import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SectionHeader from './SectionHeader'

import { FaFacebook, FaLinkedin, FaTwitter } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { Pagination } from 'swiper'
import team__data from '../assets/images/data/team'
import prevImage from '../assets/images/slider/left.png'
import nextImage from '../assets/images/slider/right.png'

const Team = ({ classes }) => {
  const [swiper, setSwiper] = React.useState(null)

  const nexto = () => {
    swiper.slideNext()
  }
  const prev = () => {
    swiper.slidePrev()
  }
  return (
    <section className={`training-program pt-100 pb-100 `}>
      <div className="container">
        <SectionHeader title="Recommended by Experienced Facilitators" />
        {/* team_card */}
        <div className=" row row-cols-4 ">
          {team__data &&
            team__data.map(({ title, designation, img }, i) => (
              <div className="team__card-img">
                <Link to="/#">
                  <img src={img} alt="" />
                </Link>
                {/* <div className="social-icons">
                  <Link to="/#" target="_blank">
                    <FaFacebook />
                  </Link>
                  <Link to="/#" target="_blank">
                    <FaLinkedin />
                  </Link>
                  <Link to="/#" target="_blank">
                    <FaTwitter />
                  </Link>
                </div> */}
                <div className="team__card-content">
                  <h6>{title}</h6>
                  <span>{designation}</span>
                </div>
              </div>
            ))}
        </div>

        {/* <ul className="nav--btns d-flex flex-wrap justify-content-end">
          <li onClick={prev}>
            <img src={prevImage} alt="" />
          </li>
          <li onClick={nexto}>
            <img src={nextImage} alt="" />
          </li>
        </ul> */}
      </div>
    </section>
  )
}

export default Team

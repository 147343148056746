import React, { useState } from 'react'
import { BiMap } from 'react-icons/bi'
import { BsTelephone } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import contact_social from '../../assets/images/data/contact_social'
import SectionHeader from '../../components/SectionHeader'
import ContactInput from './components/ContactInput'

import './ContactUs.scss'
const ContactUs = () => {
  const [remember, setRemember] = useState(true)
  return (
    <section className="contact-us py-5">
      <div className="container">
        <div className="row gy-5 justify-content-between">
          <div className="col-lg-6 pe-xxl-5 bg-white">
            <div className="contac-wrapper">
              <h3 className="title">Leave a Message</h3>
              <div className="row g-3 gy-4">
                <div className="col-sm-6">
                  <ContactInput label="Your Name" type="text" id="name" />
                </div>
                <div className="col-sm-6">
                  <ContactInput label="Your Email" type="text" id="email" />
                </div>
                <div className="col-sm-6">
                  <ContactInput label="Your Phone" type="text" id="phone" />
                </div>
                <div className="col-sm-6">
                  <ContactInput label="Subject" type="text" id="subject" />
                </div>
                <div className="col-sm-12">
                  <ContactInput
                    textarea
                    label="Your Message"
                    type="text"
                    id="message"
                  />
                </div>
                <div className="col-sm-12">
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="remember"
                      checked={remember}
                      onChange={() => setRemember(!remember)}
                    />
                    <label className="form-check-label" htmlFor="remember">
                      I agree to the{' '}
                      <Link to="/terms-and-policy">
                        terms and privacy policy
                      </Link>
                    </label>
                  </div>
                  <button className="cmn--btn w-100 form-control" type="submit">
                    Send Message
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6 ps-xxl-3">
            <SectionHeader
              leftalign
              subtitle="Get In Touch"
              title="Contact Us"
              className="contact-us"
              redBorder={false}
            />
            <p className="mb-4">
              Do you have questions or in need of further clarification? Speak
              to an Agent..
            </p>
            <div className="row g-3">
              <div className="col-sm-6">
                <div className="contact__card">
                  <div className="icon">
                    <BsTelephone />
                  </div>
                  <div className="content">
                    <h5 className="subtitle">Contact</h5>
                    <span className="d-block">
                      Mobile: <a href="tel:0800790555">0800790555</a>
                    </span>
                    <span className="d-block">
                      Mail: <a href="mailto:info@katon.app">info@katon.app</a>
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="contact__card">
                  <div className="icon">
                    <BiMap />
                  </div>
                  <div className="content">
                    <h5 className="subtitle">Address</h5>
                    <span>
                      GA-032-1298 HP Nyametei F809/11th Street Osu, Accra
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <hr className="contact-hr" />
            <h3 className="mb-3" style={{ fontWeight: `600` }}>
              FOLLOW US ON
            </h3>

            <ul className="contact-social">
              {contact_social &&
                contact_social.map(({ id, icon, link }, i) => (
                  <li>
                    <a href={link} key={i}>
                      {icon}
                    </a>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ContactUs

import React from 'react'
// import Blog from "../../components/Blog";
import Footer from '../../components/Footer'
import Header from '../../components/Header'
// import Team from "../../components/Team";
import Testimonial from '../../components/Testimonial'
import TrainingProgram from '../training-program/TrainingProgram'
import TrainingProgramHome from '../training-program/TrainingProgramHome'
import About from './components/About'
import Banner from './components/Banner'
import Feature from './components/Feature'
import Team from '../../components/Team'
import WhoJoin from './components/WhoJoin'
import ClientSlider from 'components/ClientSlider'
const Home = () => {
  return (
    <>
      <Banner />
      <About />
      <Feature />
      {/* <Blog /> */}
      {/* <Team /> */}
      <TrainingProgramHome />
      {/* <WhoJoin /> */}
      {/* <Testimonial /> */}
      <Team />
      {/* <ClientSlider /> */}
      <Footer />
    </>
  )
}

export default Home

import React, { useEffect, useState } from 'react'

import questionImage from '../../assets/images/question_bg.png'
import SubmitLoader from 'components/Common/SubmitLoader'

const FilterPastPaperQuestion = (props) => {
  const { queAns, isLoading, noRecords } = props

  // const staticData = [
  //   {
  //     question: 'Full form of who',
  //     option: ['Option1', 'Option2', 'Option3', 'Option4', 'Option5'],
  //     selectedOption: 'Option1',
  //     answer: 'Option1',
  //   },
  //   {
  //     question: 'Full form of who',
  //     option: ['Option1', 'Option2', 'Option3', 'Option4', 'Option5'],
  //     selectedOption: 'Option1',
  //     answer: 'Option2',
  //   },
  //   {
  //     question: 'Full form of who',
  //     option: ['Option1', 'Option2', 'Option3', 'Option4', 'Option5'],
  //     selectedOption: 'Option2',
  //     answer: 'Option3',
  //   },
  //   {
  //     question: 'Full form of who',
  //     option: ['Option1', 'Option2', 'Option3', 'Option4', 'Option5'],
  //     selectedOption: '',
  //     answer: 'Option3',
  //   },
  // ]
  // useEffect(() => {
  //   // getAssignmentResultById(id)
  // }, [])

  return (
    <>
      {isLoading ? (
        <div
          style={
            isLoading
              ? { position: 'relative', opacity: '0.8', minHeight: '300px' }
              : {}
          }
        >
          <SubmitLoader />
        </div>
      ) : (
        <>
          {noRecords && (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ minHeight: '300px' }}
            >
              <h1>No system activity found.</h1>
            </div>
          )}
          <section className="practice">
            <div className="pastPaperBox bg-white mb-3">
              {queAns && queAns.length > 0 ? (
                queAns.map((data, i) => {
                  return (
                    <>
                      <div className="h6 font_medium mb-2" key={i}>
                        {`${i + 1}. ${data?.question}`}
                      </div>
                      {data.option.map((que, i) => {
                        let optionIndex = ''
                        if (i == 0) {
                          optionIndex = 'A'
                        } else if (i == 1) {
                          optionIndex = 'B'
                        } else if (i == 2) {
                          optionIndex = 'C'
                        } else if (i == 3) {
                          optionIndex = 'D'
                        } else if (i == 4) {
                          optionIndex = 'E'
                        }
                        let correctAnswer = data?.answer == que
                        let IncorrectAnswer =
                          data?.selectedOption == que &&
                          data?.selectedOption != data?.answer
                        return (
                          <>
                            <div
                              className={`${que == '' ? 'd-none' : 'd-flex'}`}
                              key={i}
                            >
                              <span className="rounded-circle border">
                                {optionIndex}
                              </span>
                              <span className="ms-2 pt-2">{que}</span>
                            </div>
                          </>
                        )
                      })}
                      {i + 1 < queAns.length && <hr className="contact-hr" />}
                    </>
                  )
                })
              ) : (
                <>
                  <div className="mt-5 question__item-img text-center">
                    <img
                      src={questionImage}
                      alt="Book Library"
                      className="mb-2"
                    />
                    No system activity found.
                  </div>
                  <hr className="contact-hr" />
                </>
              )}
            </div>
          </section>
        </>
      )}
    </>
  )
}

export default FilterPastPaperQuestion

import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import questionImage from '../../assets/images/question_bg.png'

import { getQuestionsByPastPaper } from '../../helpers/backendHelpers/assignmentQSet'
import FilterPastPaperQuestion from './FilterPastPaperQuestion'

const PastPaper = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [queAns, setQueAns] = useState([])
  const [pastPaper, setPastPaper] = useState()

  const [error, setError] = useState('')

  const { id } = useParams()

  const getPastPapers = async () => {
    try {
      setIsLoading(true)
      let response = await getQuestionsByPastPaper(id)
      let { question_data, pastPaperData } = response.data
      setPastPaper(pastPaperData)
      setQueAns(question_data)
      setIsLoading(false)
    } catch (error) {
      setError(error)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getPastPapers()
  }, [])

  return (
    <>
      <section className="practice py-5">
        <div className="container">
          <div className="row books-section">
            <div className="books-sidebar col-lg-3">
              <div className="books-sidebar-inner">
                <div className="books-sidebar-top books-sidebar-widget">
                  <p className="h5">Information</p>
                </div>
                <div className="books-sidebar-widget">
                  <div className="books-wrapper-header">
                    <p className="h6 mb-3">{pastPaper?.pp_title}</p>
                    <p className="h6 mb-3">
                      {pastPaper?.pp_category}, {pastPaper?.pp_subCategory}
                    </p>
                    <p className="h6 mb-3">{pastPaper?.pp_year}, WYAK</p>
                    <p className="h6 mb-3">
                      Total Marks: {pastPaper?.pp_totalMarks}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-8 mb-3">
              <div className="pastPaperBox bg-white mb-3">
                <h5 className="text-center">Section A</h5>
                <hr className="contact-hr" />
                {queAns && queAns.length > 0 ? (
                  queAns.map((data, i) => {
                    return (
                      <>
                        <div className="h6 font_medium mb-2">
                          {`${i + 1}. ${data?.question}`}
                        </div>
                        {data.option.map((que, i) => {
                          let optionIndex = ''
                          if (i == 0) {
                            optionIndex = 'A'
                          } else if (i == 1) {
                            optionIndex = 'B'
                          } else if (i == 2) {
                            optionIndex = 'C'
                          } else if (i == 3) {
                            optionIndex = 'D'
                          } else if (i == 4) {
                            optionIndex = 'E'
                          }
                          let correctAnswer = data?.answer == que
                          let IncorrectAnswer =
                            data?.selectedOption == que &&
                            data?.selectedOption != data?.answer
                          return (
                            <>
                              <div
                                key={i}
                                className={`${que == '' ? 'd-none' : 'd-flex'}`}
                              >
                                <span className="rounded-circle border">
                                  {optionIndex}
                                </span>
                                <span className="ms-2 pt-2">{que}</span>
                              </div>
                            </>
                          )
                        })}
                      </>
                    )
                  })
                ) : (
                  <div className="mt-5 question__item-img text-center">
                    <img
                      src={questionImage}
                      alt="Book Library"
                      className="mb-2"
                    />
                    No system activity found.
                  </div>
                )}

                <hr className="contact-hr" />
              </div>
              {/* <FilterPastPaperQuestion queAns={queAns} /> */}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default PastPaper

import React, { useEffect, useState } from 'react'
import {
  getAllAssignment,
  getAssignmentResultByStudentAPI,
} from '../../helpers/backendHelpers/assignment'
import { BsCalendarWeek } from 'react-icons/bs'
import moment from 'moment'
import { IMAGE_URL } from '../../helpers/urlHelper'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { getUserInfo } from '../../helpers/authHelper'
import swal from 'sweetalert'
import { getAllAssignmentResult } from '../../helpers/backendHelpers/assignmentResult'
import { getAuthToken } from '../../helpers/authHelper'
import default_icon from '../../assets/images/default_avatar.png'
import SubmitLoader from 'components/Common/SubmitLoader'
import AnswerListModal from 'components/Common/AnswerListModal'

const Practice = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [assignment, setAssignment] = useState([])
  const [assignmentResult, setAssignmentResult] = useState([])
  const [noRecords, setNoRecords] = useState(false)
  const [error, setError] = useState('')
  const navigate = useNavigate()
  const location = useLocation()
  const pathName = location.pathname
  const userInfo = getUserInfo()
  const [answerListModal, setAnswerListModal] = useState(false)
  const [assignmentId, setAssignmentId] = useState('')

  const getAssignment = async (id) => {
    try {
      setIsLoading(true)
      let response = await getAllAssignment(id)
      let { assignment } = response.data
      setAssignment(assignment)
      setIsLoading(false)
      if (assignment.length == 0) {
        setNoRecords(true)
      } else {
        setNoRecords(false)
      }
    } catch (error) {
      setError(error)
      setIsLoading(false)
    }
  }

  const getAssignmentResultByStudent = async () => {
    try {
      setIsLoading(true)
      let response = await getAllAssignmentResult()
      let { assignmentResult } = response.data
      setAssignmentResult(assignmentResult)
      setIsLoading(false)
    } catch (error) {
      setError(error)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    let id = userInfo?.id ? userInfo?.id : ''
    getAssignment(id)
    if (userInfo) {
      getAssignmentResultByStudent()
    }
  }, [])

  const LoginAlert = async () => {
    const value = await swal('Please do login into your account', {
      buttons: {
        defeat: 'Log in',
        cancel: 'Cancel',
      },
    })
    switch (value) {
      case 'defeat':
        navigate('/auth/signin', {
          state: {
            url: pathName,
          },
        })
        break
      default:
    }
  }

  const handleStartAssignmentClick = async (asn_id) => {
    const isLoggedIn = getAuthToken() ? true : false

    if (!isLoggedIn) {
      LoginAlert()
    } else {
      window.open(
        `/mcqTest/${userInfo?.id}/${asn_id}`,
        'Popup',
        'toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, maximize=1, fullscreen=1',
      )
    }
  }

  const toggleAnswerListModal = () => {
    setAnswerListModal(!answerListModal)
  }

  return (
    <>
      <AnswerListModal
        modalTitle="Student Result"
        asn_id={assignmentId}
        isOpen={answerListModal}
        toggle={toggleAnswerListModal}
        type="pdf"
      />
      <div style={{ minHeight: '300px' }}>
        {isLoading ? (
          <div
            style={
              isLoading
                ? { position: 'relative', opacity: '0.8', minHeight: '300px' }
                : {}
            }
          >
            <SubmitLoader />
          </div>
        ) : (
          <>
            {noRecords && (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ minHeight: '300px' }}
              >
                <h1>No system activity found.</h1>
              </div>
            )}
            <section className="practice py-5">
              <div className="container">
                <div className="row">
                  {assignment &&
                    assignment.map((data, i) => {
                      return (
                        <>
                          <div className="col-md-6" key={i}>
                            <div className=" mt-3 bg-white shadow p-4 rounded-4">
                              <h5>{data.asn_title}</h5>
                              &nbsp;
                              <p>
                                Lorem ipsum dolor sit amet consectetur
                                adipisicing elit. Facilis repellendus iusto
                                alias autem praesentium quaerat nisi, aut
                                eveniet, dicta amet totam debitis. Sit, natus
                                velit numquam commodi laboriosam magnam
                                sapiente.
                              </p>
                              <div className="teacher_details">
                                <div className="d-flex mb-2">
                                  <div className="image ">
                                    <img
                                      src={
                                        data.asn_teacher.tc_profilePic
                                          ? `${IMAGE_URL}/${data.asn_teacher.tc_profilePic}`
                                          : default_icon
                                      }
                                      className="rounded-circle"
                                      style={{ width: '40px', height: '40px' }}
                                    />
                                  </div>
                                  <div className="name ms-3  d-flex align-items-center">
                                    <b className="h7">
                                      {data.asn_teacher.tc_fullName}
                                    </b>
                                  </div>
                                  <span className="ms-auto d-flex align-items-center">
                                    English Letter writting
                                  </span>
                                </div>
                              </div>
                              <ul className="m-0 p-0 mb-3">
                                <li className="d-flex">
                                  <span>Total marks: </span>
                                  <span className=" ms-3 ">
                                    {data.asn_totalMarks}
                                  </span>
                                  <div className="d-flex ms-auto">
                                    <span className="">Duration:</span>
                                    <span className="ms-3">
                                      {data.asn_duration}
                                    </span>
                                  </div>
                                </li>
                              </ul>
                              <div className=" d-block ">
                                <div></div>
                                <div className=" d-flex justify-content-end">
                                  <span className="me-auto d-flex align-items-center">
                                    <BsCalendarWeek />
                                    &nbsp;
                                    {moment(data.asn_createdAt).format(
                                      'DD MMM, YYYY',
                                    )}
                                  </span>
                                  {userInfo?.userType == 'Student' &&
                                  data.asn_isCheckResult ? (
                                    <Link
                                      to={`/practice/practiceResult/${data?.asn_id}`}
                                      className="checkResultBtn"
                                      rel="noopener noreferrer"
                                      style={{ display: 'table-cell' }}
                                    >
                                      Check Result
                                    </Link>
                                  ) : (
                                    <Link
                                      className={`${
                                        userInfo?.userType == 'Teacher' ||
                                        userInfo?.userType == 'Freelance'
                                          ? 'checkResultBtn'
                                          : 'cmn--btn'
                                      }`}
                                      rel="noopener noreferrer"
                                      onClick={() => {
                                        if (
                                          !userInfo?.userType ||
                                          userInfo?.userType == 'Student'
                                        ) {
                                          handleStartAssignmentClick(
                                            data.asn_id,
                                          )
                                        } else if (
                                          userInfo?.userType == 'Teacher'
                                        ) {
                                          toggleAnswerListModal()
                                          setAssignmentId(data.asn_id)
                                        }
                                      }}
                                      style={{ display: 'table-cell' }}
                                    >
                                      {(userInfo?.userType == 'Teacher' ||
                                        userInfo?.userType == 'Freelance') &&
                                        `${data?.asn_solveCount} Attempted`}
                                      {(userInfo?.userType == 'Student' ||
                                        userInfo?.userType == 'Premium') &&
                                        'Start Assignment'}
                                      {!userInfo?.userType &&
                                        'Start Assignment'}
                                    </Link>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )
                    })}
                </div>
              </div>
            </section>
          </>
        )}
      </div>
    </>
  )
}

export default Practice

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { IMAGE_URL } from '../../helpers/urlHelper'
import ButtonLoader from './ButtonLoader'
const PDfModal = (props) => {
  const {
    isOpen,
    toggle,
    pdf,
    type,
    modalTitle,
    showFooter = false,
    downloadAttentionForm,
    isLoading,
    setShowFooter,
  } = props
  const handleContextMenu = (e) => {
    e.preventDefault()
  }
  {
    // const handleContextMenu = (e) => {
    //   e.preventDefault()
    // }

    // useEffect(() => {
    //   const disableRightClick = (e) => {
    //     e.preventDefault()
    //   }

    //   document.addEventListener('contextmenu', disableRightClick)

    //   return () => {
    //     document.removeEventListener('contextmenu', disableRightClick)
    //   }
    // }, [])

    if (pdf !== null)
      return (
        <>
          <div>
            <Modal
              onContextMenu={handleContextMenu}
              isOpen={isOpen}
              size="xl"
              role="dialog"
              autoFocus={true}
              centered={true}
              className="tutorModal"
              tabIndex="-1"
              toggle={() => {
                toggle()
                setShowFooter(false)
              }}
            >
              <ModalHeader
                toggle={() => {
                  toggle()
                  setShowFooter(false)
                }}
              >
                {type == 'pdf'
                  ? modalTitle
                    ? modalTitle
                    : 'Read PDF'
                  : 'Read ePub'}
              </ModalHeader>
              <ModalBody>
                <div className="embed-responsive embed-responsive-16by9 ratio ratio-16x9">
                  <iframe
                    oncontextmenu="return false;"
                    id="pdfIframe"
                    // onContextMenu={handleContextMenu}
                    style={{ height: '100%', width: '100%' }}
                    title="test"
                    className="embed-responsive-item"
                    src={`${IMAGE_URL}/${pdf}#toolbar=0`}
                  />
                </div>
              </ModalBody>
              {showFooter && (
                <ModalFooter>
                  <button
                    type="button"
                    disabled={isLoading}
                    className="enroll-btn cmn--btn w-25"
                    onClick={async () => {
                      await downloadAttentionForm()
                      toggle()
                      setShowFooter(false)
                    }}
                  >
                    <div className="d-flex align-items-center justify-content-center">
                      {isLoading && <ButtonLoader></ButtonLoader>}

                      <span>{isLoading ? 'Downloading' : 'Download PDF'}</span>
                    </div>
                  </button>
                </ModalFooter>
              )}
            </Modal>
          </div>
        </>
      )
  }
}

PDfModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default PDfModal

import React from 'react'
import './Privacy.scss'
const Privacy = () => {
  return (
    <section className="privacy-section py-5">
      <div className="container">
        <h4 className="subtitle">Privacy Policy</h4>
        <p>Welcome to the KATon Privacy Policy (“Policy”).</p>
        <p>
          KATon (“We” or “Our”) operates www.katon.app (the “Website”) and KATon
          Mobile Application (the “Application”). The Website and Application
          are platforms built to host, manage, and deploy curriculum-relevant
          educational content to learners through desktop, laptop and tablet
          computers as well as smartphones, with marginal concern for internet
          limitations and costs.
        </p>
        <p>
          This page informs you of Our policies regarding the collection, use
          and disclosure of your personal data when you visit, access, browse
          and/or use Our Website or Mobile Application (“Platform”) and when you
          use any storage or transmitting device provided by us. Please read
          carefully, as it’s important for you to understand how We intend to
          retain and protect the private information you provide us. To protect
          your privacy, KATon follows different principles in accordance with
          worldwide practices for user privacy and data protection.
        </p>
        <p>
          KATon values your trust and confidence. We are committed to always
          safeguarding the privacy of your information. If you have any
          questions about this Policy or Our data protection practices, kindly
          contact us.
        </p>
        <h4 className="subtitle">Consent</h4>
        <p>
          By accessing or using Our services or Products, you agree to the
          collection and use of information in accordance with this Policy. Once
          you provide consent, you may change your mind and withdraw the consent
          at any time by contacting us at{' '}
          <a href="mailto:info@katon.app">info@katon.app.</a> Please note that,
          withdrawal of consent will not affect the lawfulness of any process
          carried out before you withdraw your consent.
        </p>
        <h4 className="subtitle">The Data We Collect About You</h4>
        <p>
          When signing up on Our Platform, We collect information about you.
          This information may include:
        </p>
        <ul>
          <li>
            Profile data, such as your name, username or alias, e-mail address,
            mailing address, location, school, class (in school), age,
            organisation of work, and phone number among others
          </li>
          <li>
            Financial data, such as your card information, billing address, and
            payment options may also be required by our payment service
            providers
          </li>
          <li>
            Technical information, such as the type of computer and or mobile
            device and internet browser you use, your computer IP address, data
            about the pages you access, mobile device ID or unique identifier,
            statistics on page views, standard Web log data, still and moving
            images, etc
          </li>
          <li>
            Marketing data, such as customer or user feedback; and • Usage data,
            such as time spent on Our Website and Application, when application
            was opened, device data and learning statistics (time spent
            learning, lessons viewed, test scores, etc.).
          </li>
        </ul>
        <h4 className="subtitle">How We Use Your Personal Data</h4>
        <p>
          We will only use your personal data for the purpose for which We
          collected it. This may include the following:
        </p>
        <ul>
          <li>to register you as a new user,</li>
          <li>to process and deliver your order(s),</li>
          <li>to manage your relationship with KATon,</li>
          <li>
            to recommend products and or services which may be of interest to
            you,
          </li>
          <li>
            to help KATon identify and provide the type of service offerings in
            which you are most interested in,
          </li>
          <li>
            to enable us to create the content most relevant to you and to
            generally improve Our services,
          </li>
          <li>
            to make the Platform easier for you to use and avoid repetitions,
          </li>
          <li>
            to manage, detect, prevent, and/or remediate the risk of fraud, or
            other potentially prohibited or illegal activities, and
          </li>
          <li>
            to send periodic electronic mails, notifications, and marketing
            materials of Our products and services.
          </li>
        </ul>
        <h4 className="subtitle">Third Party Links</h4>
        <p>
          Occasionally, We may include or offer third party products or services
          on Our Platform. As such, Our Platform may contain links to websites
          owned and operated by third parties. These third-party websites may
          have their own separate and independent privacy policies, which may
          govern the collection and processing of your personal data. We urge
          you to review these privacy policies – because this Policy will not
          apply. We therefore have no responsibility or liability for the
          content, activities, and privacy practices of such third-party
          websites.
        </p>
        <h4 className="subtitle">Cookies</h4>
        <p>
          Our Website uses cookies (text files with small pieces of data that
          are used to identify your computer as you use a computer network),
          which We place on your computer with your consent. These cookies allow
          us to uniquely identify your browser and to distinguish you from other
          users of Our Website. This enables us to track your preferences and to
          provide you with personalized and smooth experience when you browse
          Our site. The cookies We use are “analytical” cookies. They allow us
          to recognize and count the number of visitors and to see how visitors
          move around the Website when they are using it. This helps us to
          improve the way Our Website works, for example by ensuring that users
          are finding what they are looking for easily. The information
          collected by the cookies is not shared with any third parties.
        </p>
        <h4 className="subtitle">User Profiles</h4>
        <p>
          Every registered user has a unique personal profile. Each profile is
          assigned a unique personal identification number. This helps us to
          ensure that only you can access your profile.
        </p>
        <p>
          When you register, We create your profile, assign a personal
          identification number (your User ID), then send this personal
          identification number back your email address with an activation link
          for the activation of your profile. This code is unique and applies to
          only you. It is your passport to seamless travel across Our Platform,
          allowing you to use Our Platform without having to fill out
          registration forms with information you’ve already provided. Even if
          you switch computers, you won’t have to re-register – just use your
          User ID to identify yourself.
        </p>
        <h4 className="subtitle">Data Security</h4>
        <p>
          We store and process your personal information on Our servers in Ghana
          under strict security and data protection protocols. In the event that
          We need to transfer your data to another country, We ensure that such
          country has adequate data protection laws to guarantee the security of
          your data. Please be assured that We have put in place appropriate
          security measures including but not limited to access controls,
          firewalls, data encryption, and physical security to prevent your
          personal data from being lost, altered, disclosed, or otherwise used
          in an unauthorized way. In addition, We limit access to your personal
          data to those employees, agents and contractors who have a business
          need to know. These employees, agents, and contractors have a duty to
          always maintain confidentiality and will only process your personal
          data based on Our instructions.
        </p>
        <p>
          Note that We have put in place procedures to deal with any suspected
          personal data breach and will notify you and any applicable regulator
          concern of a breach where We are legally required to do so.
        </p>
        <h4 className="subtitle">Retention of Personal Information</h4>
        <p>
          We will keep your personal information for as long as it is required
          and in accordance with the purpose it is being processed for, or for
          tax, accounting, regulatory, or legal purposes. We will keep the
          personal data for a period which enables us to handle or respond to
          any complaints, queries or concerns relating to Our relationship with
          you. Related to this, We may retain your personal data for a longer
          period in the event of a complaint or if We reasonably believe there
          is a prospect of litigation in respect to Our relationship with you.
        </p>
        <p>
          You may notify us whenever you no longer wish to hear from us and We
          will keep minimum information upon receipt of such notice to ensure
          that no future contact is made by us. We will actively review the
          personal information We hold and delete it securely, or in some cases
          anonymize it, when there is no longer a legal, business or user need
          for it to be retained.
        </p>
        <h4 className="subtitle">Your Legal Rights</h4>
        <p>You have the right to:</p>
        <ul>
          <li>
            Ask for access to your personal data (commonly known as a “data
            subject access request”). This enables you to receive a copy of the
            personal information We hold about you to check if We are lawfully
            processing it. Please note that a data subject access request may
            attract an administrative fee,
          </li>
          <li>
            Ask for correction of the personal data that We hold about you. This
            enables you to have any incomplete or inaccurate data We hold about
            you corrected, though We may need to verify the accuracy of the new
            data you provide,
          </li>
          <li>
            Request removal of your personal data. This enables you to ask us to
            delete or remove personal data where there is no good reason for us
            continuing to process it. You also have the right to ask us to
            delete or remove your personal data where you have successfully
            exercised your right to object to processing (see below), where We
            may have processed your information unlawfully or where We are
            required to delete your personal data to comply with local law. Note
            however, that We may not always be able to comply with your request
            of deletion for specific legal reasons which will be notified to
            you, if applicable, at the time of your request,
          </li>
          <li>
            Object to processing of your personal data where We are relying on a
            legitimate interest (or those of a third party) and there is
            something about your situation which makes you want to object to
            processing on this ground as you feel it impacts on your fundamental
            rights and freedoms. You also have the right to object where We are
            processing your personal data for direct marketing purposes. In some
            cases, We may demonstrate that We have compelling legitimate grounds
            to process your information which override your rights and freedoms,
          </li>
          <li>
            Request transfer of your personal data to you or to a third party.
            We will provide you, or a third party you have chosen, your personal
            data in a structured, commonly used, machine-readable format. Note
            that this right only applies to automated information which you
            initially provided consent for us to use or where We used the
            information to perform a contract with you. Please note that this
            request may also attract an administrative fee,
          </li>
          <li>
            Withdraw consent at any time where We are relying on consent to
            process your personal data. However, this will not affect the
            lawfulness of any processing carried out before you withdraw your
            consent. If you withdraw your consent, We may not be able to provide
            certain products or services to you. We will advise you if this is
            the case at the time you withdraw your consent.
          </li>
        </ul>
        <h4 className="subtitle">Security</h4>
        <p>
          At KATon, We implement a variety of reasonable security measures to
          protect the security and integrity of your personal information.
        </p>
        <p>
          To prevent unauthorized access to your information, We have
          implemented strong controls and security safeguards at the technical
          and operational levels. Our Platform uses Transport Layer Security
          (TLS) to ensure secure transmission of your personal data. You should
          see the padlock symbol in your URL address bar once you are
          successfully logged into the platform. The URL address will also start
          with https:// depicting a secure Webpage.
        </p>
        <p>
          Please note that you also have a significant role in protecting your
          information. No one can see or edit your personal information without
          knowing your username and password, so do not share these with others.
          However, as the internet is not a secure medium, We cannot guarantee
          that security breaches will never occur. Accordingly, We are not
          responsible for the matters, which include actions of hackers and
          other unauthorized third parties that breach Our reasonable security
          procedure.
        </p>
        <h4 className="subtitle">Online Privacy Policy Only</h4>
        <p>
          This online Policy applies only to information collected on Our
          Platform and not to information collected offline.
        </p>
        <h4 className="subtitle">Changes to Our Privacy Policy</h4>
        <p>
          We reserve the right to amend, modify or change this Privacy Policy
          and if We do so, We will post the changes on this page. It is your
          responsibility to check the Privacy Policy every time you submit
          information to us or use Our Platform. Your use will signify that you
          agree to any such changes. In the event the purpose is for processing
          change, or if a revision is material, KATon will notify you via
          electronic mail or via a pop-up or redirection when you log in to our
          Platform.
        </p>
        <h4 className="subtitle">DISCLAIMER</h4>
        <p>
          By this Policy We do not represent or warrant the condition or
          functionality of Our platform(s), its suitability for use, nor
          guarantee that Our services and products will be free from
          interruption or any error. No liability or responsibility shall lie
          for risks associated with the use of Our Platform, including but not
          limited to any risk to your computer, software or data being damaged
          by any virus, software, or any other file that might be transmitted or
          activated via Our Platform or your access to it. Neither do We
          guarantee the reliability of information contained on Our
          Platform—particularly those shared by third party users.
        </p>
        <h4 className="subtitle">Contact Us</h4>
        <p>
          If you have any questions about this Privacy Policy, please contact
          our Data Protection Officer (DPO) at: KATon{' '}
          <a href="mailto:dpo@katon.app">dpo@katon.app</a>
        </p>
      </div>
    </section>
  )
}

export default Privacy

import React, { useEffect, useState } from 'react'
import events from '../../assets/images/data/event'
import EventCard from '../../components/EventCard'
import { getTrainingPrograms } from '../../helpers/backendHelpers/tariningProgram'
import SectionHeader from '../../components/SectionHeader'
import { getUserInfo } from 'helpers/authHelper'

const TrainingProgram = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [trainingPrograms, setTrainingPrograms] = useState([])
  const [error, setError] = useState('')
  const userInfo = getUserInfo()

  useEffect(() => {
    getAllTrainingProgram()
  }, [])

  const getAllTrainingProgram = async () => {
    try {
      setIsLoading(true)
      let response = await getTrainingPrograms(userInfo?.userType)
      let { trainingPrograms } = response.data
      setTrainingPrograms(trainingPrograms)
      setIsLoading(false)
    } catch (error) {
      setError(error)
      setIsLoading(false)
    }
  }
  console.log('trainingPrograms12', trainingPrograms)
  return (
    <>
      {trainingPrograms && trainingPrograms.length > 0 && (
        <section className="team-section pt-100 pb-100">
          <div className="container">
            <div className="row g-4">
              <SectionHeader
                className="mb-0"
                // style={{ marginBottom: '95px' }}
                title="Upskilling for Facilitators And Teams"
                subtitle=""
                // text="A learning hub for students and professionals/workers that leverages live online teachers, test books, journals, learning curriculum, solved past exam questions, media (recorded presentations), and technology to deliver superior academic and technical training. We conduct comprehensive online assessments for all levels of students and professionals through live interactive quizzes and presentations."
              />
              {trainingPrograms.map((item, i) => (
                <div className="col-lg-4 col-md-4" key={i}>
                  <EventCard classes="short-event" {...item} />
                </div>
              ))}
            </div>
          </div>
        </section>
      )}
    </>
  )
}

export default TrainingProgram

import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Col,
  Row,
} from 'reactstrap'
import { IMAGE_URL } from '../../helpers/urlHelper'
import {
  getFullUserInfo,
  getUserInfo,
  setFullUserInfo,
} from 'helpers/authHelper'
import { Field, Formik, Form, useFormik } from 'formik'
import * as Yup from 'yup'
// import { Form } from 'react-router-dom'
import ImageUploading from 'react-images-uploading'
import {
  getAllSchoolByArea,
  getProfile,
  updateProfile,
  updateProfilePicture,
} from 'helpers/backendHelpers/student'
import { toast, ToastContainer } from 'react-toastify'
import default_icon from '../../assets/images/default_avatar.png'
import {
  FaCamera,
  FaEnvelope,
  FaPhone,
  FaUser,
  FaUserPlus,
} from 'react-icons/fa'
import { AiOutlinePoweroff } from 'react-icons/ai'
import Select from 'react-select'
import { GrClose } from 'react-icons/gr'
import {
  bloodGroups,
  yearsOfExperience,
  levels,
  languages,
  classRoomType,
  divisions,
  userType,
  certificateList,
} from 'helpers/dropdownVals'
import ButtonLoader from './ButtonLoader'
import { defaultRDCSeparator } from 'helpers/common'
import RegionDistrictCircuitDropDownAllSelectable from 'helpers/RegionDistrictCircuitDropDownAllSelectable'
import {
  getTeacherProfile,
  updateProfilePictureTeacher,
  updateTeacherProfile,
} from 'helpers/backendHelpers/teacher'
import { BsFillCalendarDateFill } from 'react-icons/bs'
import { IoLocationSharp } from 'react-icons/io5'
import { getCategories } from 'helpers/backendHelpers/book'
import StudentCommonEditForm from './StudentCommonEditForm'
import { TbLogout } from 'react-icons/tb'

const FormModal = (props) => {
  const { isOpen, pdf, type, toggle, modalTitle, closeToggleFormModal } = props
  const [images, setImages] = useState([])
  const [isSubmitLoading, setIsSubmitLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [selectedClass, setSelectedClass] = useState('')
  const [selectedDivsion, setSelectedDivsion] = useState('')
  const [schoolDropdownValues, setSchoolDropdownValues] = useState([])
  const [selectedSchool, setSelectedSchool] = useState(null)
  const [selectedBloodGroup, setselectedBloodGroup] = useState('')
  const [selectedExperience, setSelectedExperience] = useState('')
  const [selectedCertificate, setSelectedCertificate] = useState('')
  const [selectedLevel, setselectedLevel] = useState(null)
  const [selectedLanguages, setSelectedLanguages] = useState(null)
  const [categoryList, setCategoryList] = useState(null)

  const childRef = useRef()

  const [studentForm, setStudentForm] = useState({
    st_fullName: '',
    st_parentName: '',
    st_phoneNumber: '',
    st_countryCode: '',
    st_email: '',
    st_altEmail: '',
    st_studentId: '',
    st_class: '',
    st_division: '',
    areaValue: '',
    st_schoolId: '',
    st_parentEmail: '',
    st_parentPhoneNumber: '',
    st_parentCountryCode: '',
    st_profilePic: { fileName: '', file: {} },
  })
  const [teacherForm, setTeacherForm] = useState({
    tc_fullName: '',
    tc_phoneNumber: '',
    tc_countryCode: '',
    tc_email: '',
    tc_altEmail: '',
    tc_profilePic: { fileName: '', file: {} },
    areaValue: '',
    tc_staffId: '',
    tc_dateOfBirth: '',
    tc_experience: '',
    tc_level: '',
    tc_bloodGroup: '',
    tc_address: '',
    tc_briefProfile: '',
    tc_schoolId: '',
    tc_alsoKnownAs: '',
    tc_languageSpoken: '',
    tc_certificate: '',
  })
  const userInfo = getUserInfo()
  const fullUserInfo = getFullUserInfo()
  const maxNumber = 1

  useEffect(() => {
    getAllCategories()
  }, [])

  const onChange = async (imageList, addUpdateIndex) => {
    try {
      setIsSubmitLoading(true)
      let studentData = studentForm.st_profilePic
      studentData.st_profilePic = imageList[0]?.file
      const response = await updateProfilePicture(studentData)
      // fetchStudentForEdit()
      localStorage.setItem(
        'profilePic',
        response.data.student[1][0].st_profilePic,
      )
      window.dispatchEvent(new Event('storage'))
      // setUpdatedProfilePic(response.data.student[1][0].st_profilePic)
      let message = 'Profile pic uploaded successfully'
      setImages(imageList)
      setIsSubmitLoading(false)
      toast.success(message, {
        autoClose: 5000,
      })
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        'There was a problem updating profile picture'
      setIsSubmitLoading(false)
      toast.error(message, {
        autoClose: 5000,
      })
    }
  }

  const updateStudentProfileDash = async (data) => {
    try {
      data.isFirstTimeLogin = 1
      setIsLoading(true)
      setIsSubmitLoading(true)
      const response = await updateProfile(data)
      let { student } = response.data
      if (response.status) {
        let message = 'Great! Your profile is completed successfully'
        toggle()
        setFullUserInfo(student[1][0])
        setIsLoading(false)
        toast.success(message, {
          autoClose: 5000,
        })
      }
      setIsSubmitLoading(false)
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        'Error while profile update'
      setIsSubmitLoading(false)
      setIsLoading(false)
      toast.error(message, {
        autoClose: 5000,
      })
    }
  }

  const fetchStudentForEdit = async () => {
    try {
      setIsLoading(true)
      let response = await getProfile()
      let { studentProfile } = response.data || {}
      let { st_region, st_district, st_circuit } = studentProfile
      let areaValue = ''
      if (st_region || st_district || st_circuit) {
        areaValue = `${st_region || ''}${defaultRDCSeparator}${
          st_district || ''
        }${defaultRDCSeparator}${st_circuit || ''}`

        studentProfile['areaValue'] = areaValue
      } else {
        studentProfile['areaValue'] = ''
      }
      studentProfile['st_profilePic_old'] = studentProfile['st_profilePic']
      studentProfile['st_profilePic'] = { fileName: '', file: {} }
      studentProfile['st_parentName'] = studentProfile['st_parentName']
        ? studentProfile['st_parentName']
        : ''
      studentProfile['st_class'] = studentProfile['st_class']
        ? studentProfile['st_class']
        : ''
      studentProfile['st_division'] = studentProfile['st_division']
      studentProfile['st_division'] = studentProfile['st_division']
        ? studentProfile['st_division']
        : ''
      studentProfile['st_phoneNumber'] = studentProfile['st_phoneNumber']
        ? studentProfile['st_phoneNumber']
        : ''
      fetchAllSchoolByArea(areaValue, studentProfile['st_schoolId'])

      if (studentProfile.st_division) {
        setSelectedDivsion({
          label: studentProfile.st_division,
          value: studentProfile.st_division,
        })
      }
      if (studentProfile.st_class) {
        setSelectedClass({
          label: studentProfile.st_class,
          value: studentProfile.st_class,
        })
      }
      if (studentProfile.st_level) {
        setselectedLevel({
          label: studentProfile.st_level,
          value: studentProfile.st_level,
        })
      }
      setSelectedSchool({
        label: '',
        value: '',
      })
      setStudentForm(studentProfile)
      setIsLoading(false)
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        'Error while fetching profile details'
      setIsLoading(false)
    }
  }

  const fetchAllSchoolByArea = async (values, oldVal) => {
    if (values === '') {
      setSchoolDropdownValues([])
      if (userInfo.userType == 'Student' || userInfo.userType == 'Premium') {
        setStudentForm({ ...studentForm, st_schoolId: '' })
      } else if (
        userInfo.userType == 'Teacher' ||
        userInfo.userType == 'Freelance'
      ) {
        setTeacherForm({ ...teacherForm, tc_schoolId: '' })
      }
    } else {
      try {
        let [region, district, circuit] =
          (values + '' || '')?.split(defaultRDCSeparator) || []
        region = region || ''
        district = district || ''
        circuit = circuit || ''

        const response = await getAllSchoolByArea(region, district, circuit)
        let { schools } = response.data || {}

        let dropdownVals = schools.map((school) => {
          return { value: school.sc_id, label: school.sc_schoolName }
        })

        dropdownVals = dropdownVals || []
        setSchoolDropdownValues(dropdownVals)
        if (oldVal) {
          const defVal = dropdownVals.find((item) => item.value === oldVal)
          defVal && setSelectedSchool(defVal)
        }
      } catch (error) {
        let message =
          error?.response?.data?.message ||
          error?.message ||
          'There Was A Problem Fetching Schools'

        setSchoolDropdownValues([])
      }
    }
  }

  const onChangeTeacher = async (imageList, addUpdateIndex) => {
    try {
      setIsSubmitLoading(true)
      let teacherData = teacherForm.tc_profilePic
      teacherData.tc_profilePic = imageList[0]?.file
      const response = await updateProfilePictureTeacher(teacherData)
      // fetchTeacherForEdit()
      localStorage.setItem('profilePic', response.data.teacher.tc_profilePic)
      window.dispatchEvent(new Event('storage'))
      let message = 'Profile pic uploaded successfully'
      setImages(imageList)
      setIsSubmitLoading(false)
      toast.success(message, {
        autoClose: 5000,
      })
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        'There was a problem updating profile picture'
      setIsSubmitLoading(false)
      toast.error(message, {
        autoClose: 5000,
      })
    }
  }

  const updateTeacherProfileDash = async (data) => {
    try {
      data.isFirstTimeLogin = 1
      setIsLoading(true)
      setIsSubmitLoading(true)
      const response = await updateTeacherProfile(data)
      let { teacher } = response.data
      if (response.status) {
        toggle()
        setFullUserInfo(teacher[1][0])
        let message = 'Great! Your profile is completed successfully'
        setIsLoading(false)
        toast.success(message, {
          autoClose: 5000,
        })
      }
      setIsSubmitLoading(false)
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        'Error while profile update'
      setIsSubmitLoading(false)
      setIsLoading(false)
      toast.error(message, {
        autoClose: 5000,
      })
    }
  }

  const fetchTeacherForEdit = async () => {
    try {
      setIsLoading(true)
      let response = await getTeacherProfile()
      let { teacherProfile } = response.data || {}

      teacherProfile['tc_profilePic_old'] = teacherProfile['tc_profilePic']
      teacherProfile['tc_profilePic'] = { fileName: '', file: {} }
      localStorage.setItem('profilePic', teacherProfile['tc_profilePic_old'])

      let { tc_region, tc_district, tc_circuit } = teacherProfile
      let areaValue = ''
      if (tc_region || tc_district || tc_circuit) {
        areaValue = `${tc_region || ''}${defaultRDCSeparator}${
          tc_district || ''
        }${defaultRDCSeparator}${tc_circuit || ''}`
        teacherProfile['areaValue'] = areaValue
      } else {
        teacherProfile['areaValue'] = ''
      }

      fetchAllSchoolByArea(areaValue, teacherProfile['tc_schoolId'])
      if (teacherProfile.tc_bloodGroup) {
        setselectedBloodGroup({
          label: teacherProfile.tc_bloodGroup,
          value: teacherProfile.tc_bloodGroup,
        })
      }
      let langFromRes = teacherProfile['tc_languageSpoken']
      let tempLang = []
      if (langFromRes && langFromRes.length > 0) {
        tempLang = languages?.filter((master) => {
          return langFromRes?.find((subRes) => {
            return subRes == master.value
          })
        })
        setSelectedLanguages(tempLang)
      }
      if (teacherProfile.tc_experience) {
        setSelectedExperience({
          label: teacherProfile.tc_experience,
          value: teacherProfile.tc_experience,
        })
      }
      if (teacherProfile.tc_level) {
        setselectedLevel({
          label: teacherProfile.tc_level,
          value: teacherProfile.tc_level,
        })
      }
      if (teacherProfile.tc_certificate) {
        setSelectedCertificate({
          label: teacherProfile.tc_certificate,
          value: teacherProfile.tc_certificate,
        })
      }
      setTeacherForm(teacherProfile)
      setIsLoading(false)
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        'Error while fetching profile details'
      setIsLoading(false)
    }
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: studentForm,
    onSubmit: (values) => {
      let studentData = values
      let [st_region, st_district, st_circuit] =
        (values?.areaValue + '' || '')?.split(defaultRDCSeparator) || []
      st_region = st_region || null
      st_district = st_district || null
      st_circuit = st_circuit || null
      studentData['st_region'] = st_region
      studentData['st_district'] = st_district
      studentData['st_circuit'] = st_circuit
      delete studentData['st_profilePic']
      updateStudentProfileDash(studentData)
    },
    validationSchema: Yup.object({
      st_fullName: Yup.string()
        .min(5, 'Mininum 5 characters')
        .test(
          'st_fullName',
          'This username has already been taken',
          function (st_fullName) {
            return 'st_fullName'
          },
        )
        .required('Please enter full name'),
      st_email: Yup.string()
        .email('Invalid email address')
        .required('Please enter your email'),
      st_altEmail: Yup.string()
        .email('Invalid email address')
        .notRequired()
        .nullable(),
      st_class: Yup.string().required('Please enter your class').nullable(),
      st_division: Yup.string()
        .required('Please enter your division')
        .nullable(),

      st_phoneNumber: Yup.string()
        .required('Please enter your phone number')
        .matches(/^\d{9}$/, 'Please enter 9 digit number,without adding 0'),
      st_profilePic: Yup.mixed().notRequired().nullable(),
      areaValue: Yup.mixed().test(
        'invalidInput',
        'Please select region-district',
        (value) => {
          return !!value
        },
      ),
      st_schoolId: Yup.string().required('Please select school').nullable(),
      st_parentEmail: Yup.string().email('Invalid email address').notRequired(),
      st_parentPhoneNumber: Yup.string()
        .required('Please enter your parent phone number')
        .matches(/^\d{9}$/, 'Please enter 9 digit number,without adding 0')
        .nullable(),
    }),
  })

  const teacherFormik = useFormik({
    enableReinitialize: true,
    initialValues: teacherForm,
    onSubmit: (values) => {
      let teacherData = values
      let [tc_region, tc_district, tc_circuit] =
        (values?.areaValue + '' || '')?.split(defaultRDCSeparator) || []
      let tc_languageSpoken = selectedLanguages.map((data) => data.value)
      let tc_subject = teacherForm?.tc_subject ? teacherForm?.tc_subject : null
      let tc_classRoomId = teacherForm?.tc_classRoomId
        ? teacherForm?.tc_classRoomId
        : null
      tc_region = tc_region || null
      tc_district = tc_district || null
      tc_circuit = tc_circuit || null
      teacherData['tc_region'] = tc_region
      teacherData['tc_district'] = tc_district
      teacherData['tc_circuit'] = tc_circuit
      teacherData['tc_languageSpoken'] = tc_languageSpoken
        ? tc_languageSpoken?.toString()
        : null
      teacherData['tc_subject'] = tc_subject ? tc_subject?.toString() : null
      teacherData['tc_classRoomId'] = tc_classRoomId
        ? tc_classRoomId?.toString()
        : null
      delete teacherData['tc_profilePic']

      updateTeacherProfileDash(teacherData)
    },
    validationSchema: Yup.object({
      tc_fullName: Yup.string()
        .min(5, 'Mininum 5 characters')
        .test(
          'tc_fullName',
          'This username has already been taken',
          function (tc_fullName) {
            return 'tc_fullName'
          },
        )
        .required('Please enter full name'),

      tc_email: Yup.string()
        .email('Invalid email address')
        .required('Please enter your email'),

      tc_altEmail: Yup.string()
        .email('Invalid email address')
        .required('Please provide your NTC email address')
        .nullable(),

      tc_phoneNumber: Yup.string()
        .required('Please enter phone number')
        .matches(/^\d{9}$/, 'Please enter 9 digit number,without adding 0'),
      tc_countryCode: Yup.string().required('Please enter country code'),
      tc_staffId: Yup.string().required('Please enter your staff id'),
      tc_certificate: Yup.string()
        .required('Please enter your certificates')
        .nullable(),
      tc_profilePic: Yup.mixed().notRequired().nullable(),
      tc_dateOfBirth: Yup.string().notRequired().nullable(),
      tc_briefProfile: Yup.string()
        .required('Please enter your brief profile')
        .nullable(),
      tc_experience: Yup.string()
        .required('Please enter your experience')
        .nullable(),
      tc_level: Yup.string().required('Please enter your level').nullable(),
      tc_bloodGroup: Yup.string()
        .nullable()
        .notRequired('Please select bloodgroups'),
      tc_languageSpoken: Yup.mixed().test(
        'invalidInput',
        'Please Select Languages',
        (value) => {
          if (value) {
            return value.length
          } else {
            return false
          }
        },
      ),
      areaValue: Yup.mixed().test(
        'invalidInput',
        'Please select region-district',
        (value) => {
          return !!value
        },
      ),
      tc_schoolId: Yup.string().required('Please select school').nullable(),
    }),
  })

  const studentHandleSubmit = () => {
    formik.handleSubmit()
  }

  const teacherHandleSubmit = () => {
    teacherFormik.handleSubmit()
  }

  const getAllCategories = async (filterCategory = '') => {
    try {
      let response = await getCategories()
      let { categories } = response.data
      let vals = []
      let mainCategoryArray = []

      if (filterCategory) {
        let filteredData = categories.filter(
          (data) => data.categoryId == filterCategory,
        )
        filteredData[0].category.map((data) => {
          vals.push({ name: data.CategoryName, value: data.CategoryName })
        })
      } else {
        categories.map((mainCategory) => {
          let val = []
          let { category } = mainCategory

          category.map((cat) => {
            val.push({ label: cat.CategoryName, value: cat.CategoryName })
          })
          mainCategoryArray.push({
            label: mainCategory.categoryName,
            options: val,
          })
        })
      }
      console.log('mainCategoryArray12', mainCategoryArray)
      setCategoryList(mainCategoryArray)
    } catch (error) {
      console.log('error', error)
    }
  }

  const childFunction = () => {
    // Function logic in the child component
    console.log('Child function called')
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        // size="lg"
        // style={{ width: '800px !important' }}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="tutorModal signUpFormModal"
        tabIndex="-1"
        toggle={toggle}
        backdrop="static"
      >
        <ModalHeader
          toggle={toggle}
          close={
            <TbLogout
              style={{ cursor: 'pointer' }}
              fontSize={24}
              onClick={closeToggleFormModal}
            />
          }
        >
          {modalTitle}
        </ModalHeader>
        <ModalBody className="scrollable-modal px-4">
          <>
            <p className="mt-3">
              Complete your profile to continue using Katon platform
            </p>

            <StudentCommonEditForm
              callFrom={1}
              ref={childRef}
              childFunction={childFunction}
              teacherFormik={teacherFormik}
              teacherForm={teacherForm}
              setTeacherForm={setTeacherForm}
              formik={formik}
              studentForm={studentForm}
              setStudentForm={setStudentForm}
              selectedLanguages={selectedLanguages}
              setSelectedLanguages={setSelectedLanguages}
              isSubmitLoading={isSubmitLoading}
              setIsSubmitLoading={setIsSubmitLoading}
            />
          </>
        </ModalBody>
        <ModalFooter>
          <>
            <button
              disabled={isSubmitLoading}
              type="button"
              className="enroll-btn cmn--btn w-25 mx-auto"
              onClick={async () => {
                if (
                  userInfo.userType == 'Student' ||
                  userInfo.userType == 'Premium'
                ) {
                  studentHandleSubmit()
                } else if (
                  userInfo.userType == 'Teacher' ||
                  userInfo.userType == 'Freelance'
                ) {
                  teacherHandleSubmit()
                }
              }}
            >
              <div className="d-flex align-items-center justify-content-center">
                {isSubmitLoading && <ButtonLoader></ButtonLoader>}

                <span>Submit</span>
              </div>
            </button>
          </>
        </ModalFooter>
      </Modal>
    </>
  )
}

FormModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default FormModal

import React from 'react'
import choose from '../../../assets/images/data/choose'
import SectionHeader from '../../../components/SectionHeader'
import './WhyChooseUs.scss'
const WhyChooseUs = () => {
  return (
    <section className="why-choose-us pt-100 pb-100 bg-section-light">
      <div className="container">
        <SectionHeader
          title="Why Choose KATON"
          subtitle=""
          text="Your KATON learning experience is grounded in cutting-edge cognitive science. With more than two dozen distinct learning features to help you achieve your academic and professional goals, our approach follows three key principles of:"
        />
        <div className="row g-4">
          {choose &&
            choose.map(({ img, title, text }, i) => (
              <div className="col-sm-4 col-lg-4" key={i}>
                <div className="choose__card">
                  <div className="choose__card-img">
                    <img src={img} alt={title} />
                  </div>
                  <div className="choose__card-cont">
                    <h5 className="title">{title}</h5>
                    <p>{text}</p>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  )
}

export default WhyChooseUs

import { get, post } from '../apiHelper'
import { getApiConfig, getUserInfo } from '../authHelper'
import * as url from '../urlHelper'

export const getAllAssignment = (id) => {
  return get(`${url.ASSIGNMENT}/getAll?st_id=${id}`, getApiConfig())
}

export const getAssignmentResultByStudentAPI = (st_id, asn_id) => {
  return get(
    `${url.ASSIGNMENTRESULT}/getAssignmentResultByStudent/get?asn_id=${asn_id}&st_id=${st_id}`,
    getApiConfig(),
  )
}

import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from 'react-icons/fa'
const contact_social = [
  {
    id: 1,
    icon: <FaFacebook />,
    link: 'https://www.facebook.com/katongh',
  },
  // {
  // 	id: 1,
  // 	icon: <FaTwitter />,
  // 	link: "https://twitter.com/",
  // },
  {
    id: 1,
    icon: <FaInstagram />,
    link: 'https://instagram.com/kat.ongh?igshid=YmMyMTA2M2Y=',
  },
  // {
  // 	id: 1,
  // 	icon: <FaLinkedin />,
  // 	link: "https://www.linkedin.com/",
  // },
]
export default contact_social

import img1 from "../books/1.png";
import img2 from "../books/2.png";
import img3 from "../books/3.png";
import img4 from "../books/4.png";
import img5 from "../books/5.png";
import img6 from "../books/6.png";
import img7 from "../books/7.png";
import img8 from "../books/8.png";
import user1 from "../order-single/review/1.png";
import user2 from "../order-single/review/2.png";
import user3 from "../order-single/review/3.png";
import user4 from "../order-single/review/4.png";
const search__category = [
	{
		img: img1,
		link: "1",
		title: "Core English - Summary 3",
		price: "20.99",
		fav: 33,
		text: "Teacher's Guide for Asante Twi textbook based on the NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Educational",
		publication: "Red Oak",
		author: "S. Banning Peprah",
		reviews: [
			{
				img: user2,
				name: "Andrian jackson",
				date: "12 / 04 / 2022",
				rating: 2,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user3,
				name: "Kevin Kay",
				date: "12 / 04 / 2022",
				rating: 4,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user4,
				name: "Lisa Lopem",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img2,
		link: "2",
		title: "Ma Yensua Asante Twi - Teacher's Guide 4",
		price: "20.99",
		fav: 0,
		text: "Teacher's Guide for Asantv NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Comedy",
		publication: "Red Oak",
		author: "S. Banning",
		reviews: [
			{
				img: user1,
				name: "Mr. Jackson Mike",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user3,
				name: "Kevin Kay",
				date: "12 / 04 / 2022",
				rating: 4,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user4,
				name: "Lisa Lopem",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img3,
		link: "3",
		title: "Career Technology CCP Curriculum",
		price: "0",
		fav: 12,
		text: "Tesante Twi textbook based on the NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Educational",
		publication: "kayd fdo",
		author: "Raing Peprah",
		reviews: [
			{
				img: user1,
				name: "Mr. Jackson Mike",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user2,
				name: "Andrian jackson",
				date: "12 / 04 / 2022",
				rating: 2,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user4,
				name: "Lisa Lopem",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img4,
		link: "4",
		title: "Arabic Teachers Resource Pack",
		price: "20.99",
		fav: 0,
		text: "Teache ES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Comedy",
		publication: "Edulab",
		author: "S.  Peprah",
		reviews: [
			{
				img: user1,
				name: "Mr. Jackson Mike",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user2,
				name: "Andrian jackson",
				date: "12 / 04 / 2022",
				rating: 2,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user3,
				name: "Kevin Kay",
				date: "12 / 04 / 2022",
				rating: 4,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img1,
		link: "5",
		title: "Core English - Summary 3",
		price: "20.99",
		fav: 3,
		text: "Guide for Asante Twi text NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Comedy",
		publication: "Edulab",
		author: "S. Rddf Peprah",
		reviews: [
			{
				img: user3,
				name: "Kevin Kay",
				date: "12 / 04 / 2022",
				rating: 4,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user4,
				name: "Lisa Lopem",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img2,
		link: "6",
		title: "Ma Yensua Asante Twi - Teacher's Guide 4",
		price: "20.99",
		fav: 0,
		text: "Based on the NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Educational",
		publication: "Red Oak",
		author: "S. Peprah",
		reviews: [
			{
				img: user1,
				name: "Mr. Jackson Mike",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user3,
				name: "Kevin Kay",
				date: "12 / 04 / 2022",
				rating: 4,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user4,
				name: "Lisa Lopem",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img3,
		link: "7",
		title: "Career Technology CCP Curriculum",
		price: "0",
		fav: 10,
		text: "Teacher's based on the NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Comedy",
		publication: "Red Oak",
		author: "S. Banning Peprah",
		reviews: [
			{
				img: user2,
				name: "Andrian jackson",
				date: "12 / 04 / 2022",
				rating: 2,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user3,
				name: "Kevin Kay",
				date: "12 / 04 / 2022",
				rating: 4,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user4,
				name: "Lisa Lopem",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img4,
		link: "8",
		title: "Arabic Teachers Resource Pack",
		price: "20.99",
		fav: 0,
		text: "Teacher's Guide for Asante Twi textbook based on the NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Comedy",
		publication: "Red Oak",
		author: "S. Banning Peprah",
		reviews: [
			{
				img: user1,
				name: "Mr. Jackson Mike",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user2,
				name: "Andrian jackson",
				date: "12 / 04 / 2022",
				rating: 2,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user3,
				name: "Kevin Kay",
				date: "12 / 04 / 2022",
				rating: 4,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img5,
		link: "1",
		title: "Core English - Summary 3",
		price: "20.99",
		fav: 13,
		text: "Teacher's Guide for Asante Twi textbook based on the NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Educational",
		publication: "Red Oak",
		author: "S. Banning Peprah",
		reviews: [
			{
				img: user2,
				name: "Andrian jackson",
				date: "12 / 04 / 2022",
				rating: 2,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user3,
				name: "Kevin Kay",
				date: "12 / 04 / 2022",
				rating: 4,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user4,
				name: "Lisa Lopem",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img6,
		link: "2",
		title: "Ma Yensua Asante Twi - Teacher's Guide 4",
		price: "20.99",
		fav: 0,
		text: "Teacher's Guide for Asante Twi textbook based on the NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Educational",
		publication: "Red Oak",
		author: "S. Banning Peprah",
		reviews: [
			{
				img: user1,
				name: "Mr. Jackson Mike",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user3,
				name: "Kevin Kay",
				date: "12 / 04 / 2022",
				rating: 4,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user4,
				name: "Lisa Lopem",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img7,
		link: "3",
		title: "Career Technology CCP Curriculum",
		price: "0",
		fav: 133,
		text: "Teacher's Guide for Asante Twi textbook based on the NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Educational",
		publication: "Red Oak",
		author: "S. Banning Peprah",
		reviews: [
			{
				img: user1,
				name: "Mr. Jackson Mike",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user2,
				name: "Andrian jackson",
				date: "12 / 04 / 2022",
				rating: 2,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user3,
				name: "Kevin Kay",
				date: "12 / 04 / 2022",
				rating: 4,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img8,
		link: "4",
		title: "Arabic Teachers Resource Pack",
		price: "20.99",
		fav: 0,
		text: "Teacher's Guide for Asante Twi textbook based on the NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Educational",
		publication: "Red Oak",
		author: "S. Banning Peprah",
		reviews: [
			{
				img: user1,
				name: "Mr. Jackson Mike",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user2,
				name: "Andrian jackson",
				date: "12 / 04 / 2022",
				rating: 2,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user4,
				name: "Lisa Lopem",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img1,
		link: "5",
		title: "Core English - Summary 3",
		price: "20.99",
		fav: 30,
		text: "Teacher's Guide for Asante Twi textbook based on the NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Educational",
		publication: "Red Oak",
		author: "S. Banning Peprah",
		reviews: [
			{
				img: user2,
				name: "Andrian jackson",
				date: "12 / 04 / 2022",
				rating: 2,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user3,
				name: "Kevin Kay",
				date: "12 / 04 / 2022",
				rating: 4,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user4,
				name: "Lisa Lopem",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img2,
		link: "6",
		title: "Ma Yensua Asante Twi - Teacher's Guide 4",
		price: "20.99",
		fav: 0,
		text: "Teacher's Guide for Asante Twi textbook based on the NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Educational",
		publication: "Red Oak",
		author: "S. Banning Peprah",
		reviews: [
			{
				img: user2,
				name: "Andrian jackson",
				date: "12 / 04 / 2022",
				rating: 2,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user3,
				name: "Kevin Kay",
				date: "12 / 04 / 2022",
				rating: 4,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user4,
				name: "Lisa Lopem",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img3,
		link: "7",
		title: "Career Technology CCP Curriculum",
		price: "0",
		fav: 303,
		text: "Teacher's Guide for Asante Twi textbook based on the NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Educational",
		publication: "Red Oak",
		author: "S. Banning Peprah",
		reviews: [
			{
				img: user2,
				name: "Andrian jackson",
				date: "12 / 04 / 2022",
				rating: 2,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user3,
				name: "Kevin Kay",
				date: "12 / 04 / 2022",
				rating: 4,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user4,
				name: "Lisa Lopem",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img4,
		link: "8",
		title: "Arabic Teachers Resource Pack",
		price: "20.99",
		fav: 0,
		text: "Teacher's Guide for Asante Twi textbook based on the NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Educational",
		publication: "Red Oak",
		author: "S. Banning Peprah",
		reviews: [
			{
				img: user1,
				name: "Mr. Jackson Mike",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user4,
				name: "Lisa Lopem",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img1,
		link: "1",
		title: "Core English - Summary 3",
		price: "20.99",
		fav: 33,
		text: "Teacher's Guide for Asante Twi textbook based on the NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Educational",
		publication: "Red Oak",
		author: "S. Banning Peprah",
		reviews: [
			{
				img: user2,
				name: "Andrian jackson",
				date: "12 / 04 / 2022",
				rating: 2,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user3,
				name: "Kevin Kay",
				date: "12 / 04 / 2022",
				rating: 4,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user4,
				name: "Lisa Lopem",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img2,
		link: "2",
		title: "Ma Yensua Asante Twi - Teacher's Guide 4",
		price: "20.99",
		fav: 0,
		text: "Teacher's Guide for Asantv NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Comedy",
		publication: "Red Oak",
		author: "S. Banning",
		reviews: [
			{
				img: user1,
				name: "Mr. Jackson Mike",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user3,
				name: "Kevin Kay",
				date: "12 / 04 / 2022",
				rating: 4,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user4,
				name: "Lisa Lopem",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img3,
		link: "3",
		title: "Career Technology CCP Curriculum",
		price: "0",
		fav: 12,
		text: "Tesante Twi textbook based on the NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Educational",
		publication: "kayd fdo",
		author: "Raing Peprah",
		reviews: [
			{
				img: user1,
				name: "Mr. Jackson Mike",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user2,
				name: "Andrian jackson",
				date: "12 / 04 / 2022",
				rating: 2,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user4,
				name: "Lisa Lopem",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img4,
		link: "4",
		title: "Arabic Teachers Resource Pack",
		price: "20.99",
		fav: 0,
		text: "Teache ES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Comedy",
		publication: "Edulab",
		author: "S.  Peprah",
		reviews: [
			{
				img: user1,
				name: "Mr. Jackson Mike",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user2,
				name: "Andrian jackson",
				date: "12 / 04 / 2022",
				rating: 2,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user3,
				name: "Kevin Kay",
				date: "12 / 04 / 2022",
				rating: 4,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img1,
		link: "5",
		title: "Core English - Summary 3",
		price: "20.99",
		fav: 3,
		text: "Guide for Asante Twi text NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Comedy",
		publication: "Edulab",
		author: "S. Rddf Peprah",
		reviews: [
			{
				img: user3,
				name: "Kevin Kay",
				date: "12 / 04 / 2022",
				rating: 4,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user4,
				name: "Lisa Lopem",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img2,
		link: "6",
		title: "Ma Yensua Asante Twi - Teacher's Guide 4",
		price: "20.99",
		fav: 0,
		text: "Based on the NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Educational",
		publication: "Red Oak",
		author: "S. Peprah",
		reviews: [
			{
				img: user1,
				name: "Mr. Jackson Mike",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user3,
				name: "Kevin Kay",
				date: "12 / 04 / 2022",
				rating: 4,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user4,
				name: "Lisa Lopem",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img3,
		link: "7",
		title: "Career Technology CCP Curriculum",
		price: "0",
		fav: 10,
		text: "Teacher's based on the NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Comedy",
		publication: "Red Oak",
		author: "S. Banning Peprah",
		reviews: [
			{
				img: user2,
				name: "Andrian jackson",
				date: "12 / 04 / 2022",
				rating: 2,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user3,
				name: "Kevin Kay",
				date: "12 / 04 / 2022",
				rating: 4,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user4,
				name: "Lisa Lopem",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img4,
		link: "8",
		title: "Arabic Teachers Resource Pack",
		price: "20.99",
		fav: 0,
		text: "Teacher's Guide for Asante Twi textbook based on the NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Comedy",
		publication: "Red Oak",
		author: "S. Banning Peprah",
		reviews: [
			{
				img: user1,
				name: "Mr. Jackson Mike",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user2,
				name: "Andrian jackson",
				date: "12 / 04 / 2022",
				rating: 2,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user3,
				name: "Kevin Kay",
				date: "12 / 04 / 2022",
				rating: 4,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img5,
		link: "1",
		title: "Core English - Summary 3",
		price: "20.99",
		fav: 13,
		text: "Teacher's Guide for Asante Twi textbook based on the NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Educational",
		publication: "Red Oak",
		author: "S. Banning Peprah",
		reviews: [
			{
				img: user2,
				name: "Andrian jackson",
				date: "12 / 04 / 2022",
				rating: 2,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user3,
				name: "Kevin Kay",
				date: "12 / 04 / 2022",
				rating: 4,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user4,
				name: "Lisa Lopem",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img6,
		link: "2",
		title: "Ma Yensua Asante Twi - Teacher's Guide 4",
		price: "20.99",
		fav: 0,
		text: "Teacher's Guide for Asante Twi textbook based on the NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Educational",
		publication: "Red Oak",
		author: "S. Banning Peprah",
		reviews: [
			{
				img: user1,
				name: "Mr. Jackson Mike",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user3,
				name: "Kevin Kay",
				date: "12 / 04 / 2022",
				rating: 4,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user4,
				name: "Lisa Lopem",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img7,
		link: "3",
		title: "Career Technology CCP Curriculum",
		price: "0",
		fav: 133,
		text: "Teacher's Guide for Asante Twi textbook based on the NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Educational",
		publication: "Red Oak",
		author: "S. Banning Peprah",
		reviews: [
			{
				img: user1,
				name: "Mr. Jackson Mike",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user2,
				name: "Andrian jackson",
				date: "12 / 04 / 2022",
				rating: 2,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user3,
				name: "Kevin Kay",
				date: "12 / 04 / 2022",
				rating: 4,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img8,
		link: "4",
		title: "Arabic Teachers Resource Pack",
		price: "20.99",
		fav: 0,
		text: "Teacher's Guide for Asante Twi textbook based on the NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Educational",
		publication: "Red Oak",
		author: "S. Banning Peprah",
		reviews: [
			{
				img: user1,
				name: "Mr. Jackson Mike",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user2,
				name: "Andrian jackson",
				date: "12 / 04 / 2022",
				rating: 2,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user4,
				name: "Lisa Lopem",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img1,
		link: "5",
		title: "Core English - Summary 3",
		price: "20.99",
		fav: 30,
		text: "Teacher's Guide for Asante Twi textbook based on the NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Educational",
		publication: "Red Oak",
		author: "S. Banning Peprah",
		reviews: [
			{
				img: user2,
				name: "Andrian jackson",
				date: "12 / 04 / 2022",
				rating: 2,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user3,
				name: "Kevin Kay",
				date: "12 / 04 / 2022",
				rating: 4,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user4,
				name: "Lisa Lopem",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img2,
		link: "6",
		title: "Ma Yensua Asante Twi - Teacher's Guide 4",
		price: "20.99",
		fav: 0,
		text: "Teacher's Guide for Asante Twi textbook based on the NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Educational",
		publication: "Red Oak",
		author: "S. Banning Peprah",
		reviews: [
			{
				img: user2,
				name: "Andrian jackson",
				date: "12 / 04 / 2022",
				rating: 2,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user3,
				name: "Kevin Kay",
				date: "12 / 04 / 2022",
				rating: 4,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user4,
				name: "Lisa Lopem",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img3,
		link: "7",
		title: "Career Technology CCP Curriculum",
		price: "0",
		fav: 303,
		text: "Teacher's Guide for Asante Twi textbook based on the NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Educational",
		publication: "Red Oak",
		author: "S. Banning Peprah",
		reviews: [
			{
				img: user2,
				name: "Andrian jackson",
				date: "12 / 04 / 2022",
				rating: 2,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user3,
				name: "Kevin Kay",
				date: "12 / 04 / 2022",
				rating: 4,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user4,
				name: "Lisa Lopem",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img4,
		link: "8",
		title: "Arabic Teachers Resource Pack",
		price: "20.99",
		fav: 0,
		text: "Teacher's Guide for Asante Twi textbook based on the NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Educational",
		publication: "Red Oak",
		author: "S. Banning Peprah",
		reviews: [
			{
				img: user1,
				name: "Mr. Jackson Mike",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user4,
				name: "Lisa Lopem",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img1,
		link: "1",
		title: "Core English - Summary 3",
		price: "20.99",
		fav: 33,
		text: "Teacher's Guide for Asante Twi textbook based on the NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Educational",
		publication: "Red Oak",
		author: "S. Banning Peprah",
		reviews: [
			{
				img: user2,
				name: "Andrian jackson",
				date: "12 / 04 / 2022",
				rating: 2,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user3,
				name: "Kevin Kay",
				date: "12 / 04 / 2022",
				rating: 4,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user4,
				name: "Lisa Lopem",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img2,
		link: "2",
		title: "Ma Yensua Asante Twi - Teacher's Guide 4",
		price: "20.99",
		fav: 0,
		text: "Teacher's Guide for Asantv NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Comedy",
		publication: "Red Oak",
		author: "S. Banning",
		reviews: [
			{
				img: user1,
				name: "Mr. Jackson Mike",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user3,
				name: "Kevin Kay",
				date: "12 / 04 / 2022",
				rating: 4,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user4,
				name: "Lisa Lopem",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img3,
		link: "3",
		title: "Career Technology CCP Curriculum",
		price: "0",
		fav: 12,
		text: "Tesante Twi textbook based on the NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Educational",
		publication: "kayd fdo",
		author: "Raing Peprah",
		reviews: [
			{
				img: user1,
				name: "Mr. Jackson Mike",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user2,
				name: "Andrian jackson",
				date: "12 / 04 / 2022",
				rating: 2,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user4,
				name: "Lisa Lopem",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img4,
		link: "4",
		title: "Arabic Teachers Resource Pack",
		price: "20.99",
		fav: 0,
		text: "Teache ES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Comedy",
		publication: "Edulab",
		author: "S.  Peprah",
		reviews: [
			{
				img: user1,
				name: "Mr. Jackson Mike",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user2,
				name: "Andrian jackson",
				date: "12 / 04 / 2022",
				rating: 2,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user3,
				name: "Kevin Kay",
				date: "12 / 04 / 2022",
				rating: 4,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img1,
		link: "5",
		title: "Core English - Summary 3",
		price: "20.99",
		fav: 3,
		text: "Guide for Asante Twi text NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Comedy",
		publication: "Edulab",
		author: "S. Rddf Peprah",
		reviews: [
			{
				img: user3,
				name: "Kevin Kay",
				date: "12 / 04 / 2022",
				rating: 4,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user4,
				name: "Lisa Lopem",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img2,
		link: "6",
		title: "Ma Yensua Asante Twi - Teacher's Guide 4",
		price: "20.99",
		fav: 0,
		text: "Based on the NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Educational",
		publication: "Red Oak",
		author: "S. Peprah",
		reviews: [
			{
				img: user1,
				name: "Mr. Jackson Mike",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user3,
				name: "Kevin Kay",
				date: "12 / 04 / 2022",
				rating: 4,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user4,
				name: "Lisa Lopem",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img3,
		link: "7",
		title: "Career Technology CCP Curriculum",
		price: "0",
		fav: 10,
		text: "Teacher's based on the NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Comedy",
		publication: "Red Oak",
		author: "S. Banning Peprah",
		reviews: [
			{
				img: user2,
				name: "Andrian jackson",
				date: "12 / 04 / 2022",
				rating: 2,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user3,
				name: "Kevin Kay",
				date: "12 / 04 / 2022",
				rating: 4,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user4,
				name: "Lisa Lopem",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img4,
		link: "8",
		title: "Arabic Teachers Resource Pack",
		price: "20.99",
		fav: 0,
		text: "Teacher's Guide for Asante Twi textbook based on the NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Comedy",
		publication: "Red Oak",
		author: "S. Banning Peprah",
		reviews: [
			{
				img: user1,
				name: "Mr. Jackson Mike",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user2,
				name: "Andrian jackson",
				date: "12 / 04 / 2022",
				rating: 2,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user3,
				name: "Kevin Kay",
				date: "12 / 04 / 2022",
				rating: 4,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img5,
		link: "1",
		title: "Core English - Summary 3",
		price: "20.99",
		fav: 13,
		text: "Teacher's Guide for Asante Twi textbook based on the NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Educational",
		publication: "Red Oak",
		author: "S. Banning Peprah",
		reviews: [
			{
				img: user2,
				name: "Andrian jackson",
				date: "12 / 04 / 2022",
				rating: 2,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user3,
				name: "Kevin Kay",
				date: "12 / 04 / 2022",
				rating: 4,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user4,
				name: "Lisa Lopem",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img6,
		link: "2",
		title: "Ma Yensua Asante Twi - Teacher's Guide 4",
		price: "20.99",
		fav: 0,
		text: "Teacher's Guide for Asante Twi textbook based on the NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Educational",
		publication: "Red Oak",
		author: "S. Banning Peprah",
		reviews: [
			{
				img: user1,
				name: "Mr. Jackson Mike",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user3,
				name: "Kevin Kay",
				date: "12 / 04 / 2022",
				rating: 4,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user4,
				name: "Lisa Lopem",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img7,
		link: "3",
		title: "Career Technology CCP Curriculum",
		price: "0",
		fav: 133,
		text: "Teacher's Guide for Asante Twi textbook based on the NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Educational",
		publication: "Red Oak",
		author: "S. Banning Peprah",
		reviews: [
			{
				img: user1,
				name: "Mr. Jackson Mike",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user2,
				name: "Andrian jackson",
				date: "12 / 04 / 2022",
				rating: 2,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user3,
				name: "Kevin Kay",
				date: "12 / 04 / 2022",
				rating: 4,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img8,
		link: "4",
		title: "Arabic Teachers Resource Pack",
		price: "20.99",
		fav: 0,
		text: "Teacher's Guide for Asante Twi textbook based on the NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Educational",
		publication: "Red Oak",
		author: "S. Banning Peprah",
		reviews: [
			{
				img: user1,
				name: "Mr. Jackson Mike",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user2,
				name: "Andrian jackson",
				date: "12 / 04 / 2022",
				rating: 2,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user4,
				name: "Lisa Lopem",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img1,
		link: "5",
		title: "Core English - Summary 3",
		price: "20.99",
		fav: 30,
		text: "Teacher's Guide for Asante Twi textbook based on the NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Educational",
		publication: "Red Oak",
		author: "S. Banning Peprah",
		reviews: [
			{
				img: user2,
				name: "Andrian jackson",
				date: "12 / 04 / 2022",
				rating: 2,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user3,
				name: "Kevin Kay",
				date: "12 / 04 / 2022",
				rating: 4,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user4,
				name: "Lisa Lopem",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img2,
		link: "6",
		title: "Ma Yensua Asante Twi - Teacher's Guide 4",
		price: "20.99",
		fav: 0,
		text: "Teacher's Guide for Asante Twi textbook based on the NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Educational",
		publication: "Red Oak",
		author: "S. Banning Peprah",
		reviews: [
			{
				img: user2,
				name: "Andrian jackson",
				date: "12 / 04 / 2022",
				rating: 2,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user3,
				name: "Kevin Kay",
				date: "12 / 04 / 2022",
				rating: 4,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user4,
				name: "Lisa Lopem",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img3,
		link: "7",
		title: "Career Technology CCP Curriculum",
		price: "0",
		fav: 303,
		text: "Teacher's Guide for Asante Twi textbook based on the NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Educational",
		publication: "Red Oak",
		author: "S. Banning Peprah",
		reviews: [
			{
				img: user2,
				name: "Andrian jackson",
				date: "12 / 04 / 2022",
				rating: 2,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user3,
				name: "Kevin Kay",
				date: "12 / 04 / 2022",
				rating: 4,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user4,
				name: "Lisa Lopem",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img4,
		link: "8",
		title: "Arabic Teachers Resource Pack",
		price: "20.99",
		fav: 0,
		text: "Teacher's Guide for Asante Twi textbook based on the NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Educational",
		publication: "Red Oak",
		author: "S. Banning Peprah",
		reviews: [
			{
				img: user1,
				name: "Mr. Jackson Mike",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user4,
				name: "Lisa Lopem",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img1,
		link: "1",
		title: "Core English - Summary 3",
		price: "20.99",
		fav: 33,
		text: "Teacher's Guide for Asante Twi textbook based on the NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Educational",
		publication: "Red Oak",
		author: "S. Banning Peprah",
		reviews: [
			{
				img: user2,
				name: "Andrian jackson",
				date: "12 / 04 / 2022",
				rating: 2,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user3,
				name: "Kevin Kay",
				date: "12 / 04 / 2022",
				rating: 4,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user4,
				name: "Lisa Lopem",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img2,
		link: "2",
		title: "Ma Yensua Asante Twi - Teacher's Guide 4",
		price: "20.99",
		fav: 0,
		text: "Teacher's Guide for Asantv NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Comedy",
		publication: "Red Oak",
		author: "S. Banning",
		reviews: [
			{
				img: user1,
				name: "Mr. Jackson Mike",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user3,
				name: "Kevin Kay",
				date: "12 / 04 / 2022",
				rating: 4,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user4,
				name: "Lisa Lopem",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img3,
		link: "3",
		title: "Career Technology CCP Curriculum",
		price: "0",
		fav: 12,
		text: "Tesante Twi textbook based on the NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Educational",
		publication: "kayd fdo",
		author: "Raing Peprah",
		reviews: [
			{
				img: user1,
				name: "Mr. Jackson Mike",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user2,
				name: "Andrian jackson",
				date: "12 / 04 / 2022",
				rating: 2,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user4,
				name: "Lisa Lopem",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img4,
		link: "4",
		title: "Arabic Teachers Resource Pack",
		price: "20.99",
		fav: 0,
		text: "Teache ES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Comedy",
		publication: "Edulab",
		author: "S.  Peprah",
		reviews: [
			{
				img: user1,
				name: "Mr. Jackson Mike",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user2,
				name: "Andrian jackson",
				date: "12 / 04 / 2022",
				rating: 2,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user3,
				name: "Kevin Kay",
				date: "12 / 04 / 2022",
				rating: 4,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img1,
		link: "5",
		title: "Core English - Summary 3",
		price: "20.99",
		fav: 3,
		text: "Guide for Asante Twi text NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Comedy",
		publication: "Edulab",
		author: "S. Rddf Peprah",
		reviews: [
			{
				img: user3,
				name: "Kevin Kay",
				date: "12 / 04 / 2022",
				rating: 4,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user4,
				name: "Lisa Lopem",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img2,
		link: "6",
		title: "Ma Yensua Asante Twi - Teacher's Guide 4",
		price: "20.99",
		fav: 0,
		text: "Based on the NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Educational",
		publication: "Red Oak",
		author: "S. Peprah",
		reviews: [
			{
				img: user1,
				name: "Mr. Jackson Mike",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user3,
				name: "Kevin Kay",
				date: "12 / 04 / 2022",
				rating: 4,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user4,
				name: "Lisa Lopem",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img3,
		link: "7",
		title: "Career Technology CCP Curriculum",
		price: "0",
		fav: 10,
		text: "Teacher's based on the NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Comedy",
		publication: "Red Oak",
		author: "S. Banning Peprah",
		reviews: [
			{
				img: user2,
				name: "Andrian jackson",
				date: "12 / 04 / 2022",
				rating: 2,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user3,
				name: "Kevin Kay",
				date: "12 / 04 / 2022",
				rating: 4,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user4,
				name: "Lisa Lopem",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img4,
		link: "8",
		title: "Arabic Teachers Resource Pack",
		price: "20.99",
		fav: 0,
		text: "Teacher's Guide for Asante Twi textbook based on the NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Comedy",
		publication: "Red Oak",
		author: "S. Banning Peprah",
		reviews: [
			{
				img: user1,
				name: "Mr. Jackson Mike",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user2,
				name: "Andrian jackson",
				date: "12 / 04 / 2022",
				rating: 2,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user3,
				name: "Kevin Kay",
				date: "12 / 04 / 2022",
				rating: 4,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img5,
		link: "1",
		title: "Core English - Summary 3",
		price: "20.99",
		fav: 13,
		text: "Teacher's Guide for Asante Twi textbook based on the NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Educational",
		publication: "Red Oak",
		author: "S. Banning Peprah",
		reviews: [
			{
				img: user2,
				name: "Andrian jackson",
				date: "12 / 04 / 2022",
				rating: 2,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user3,
				name: "Kevin Kay",
				date: "12 / 04 / 2022",
				rating: 4,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user4,
				name: "Lisa Lopem",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img6,
		link: "2",
		title: "Ma Yensua Asante Twi - Teacher's Guide 4",
		price: "20.99",
		fav: 0,
		text: "Teacher's Guide for Asante Twi textbook based on the NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Educational",
		publication: "Red Oak",
		author: "S. Banning Peprah",
		reviews: [
			{
				img: user1,
				name: "Mr. Jackson Mike",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user3,
				name: "Kevin Kay",
				date: "12 / 04 / 2022",
				rating: 4,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user4,
				name: "Lisa Lopem",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img7,
		link: "3",
		title: "Career Technology CCP Curriculum",
		price: "0",
		fav: 133,
		text: "Teacher's Guide for Asante Twi textbook based on the NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Educational",
		publication: "Red Oak",
		author: "S. Banning Peprah",
		reviews: [
			{
				img: user1,
				name: "Mr. Jackson Mike",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user2,
				name: "Andrian jackson",
				date: "12 / 04 / 2022",
				rating: 2,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user3,
				name: "Kevin Kay",
				date: "12 / 04 / 2022",
				rating: 4,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img8,
		link: "4",
		title: "Arabic Teachers Resource Pack",
		price: "20.99",
		fav: 0,
		text: "Teacher's Guide for Asante Twi textbook based on the NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Educational",
		publication: "Red Oak",
		author: "S. Banning Peprah",
		reviews: [
			{
				img: user1,
				name: "Mr. Jackson Mike",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user2,
				name: "Andrian jackson",
				date: "12 / 04 / 2022",
				rating: 2,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user4,
				name: "Lisa Lopem",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img1,
		link: "5",
		title: "Core English - Summary 3",
		price: "20.99",
		fav: 30,
		text: "Teacher's Guide for Asante Twi textbook based on the NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Educational",
		publication: "Red Oak",
		author: "S. Banning Peprah",
		reviews: [
			{
				img: user2,
				name: "Andrian jackson",
				date: "12 / 04 / 2022",
				rating: 2,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user3,
				name: "Kevin Kay",
				date: "12 / 04 / 2022",
				rating: 4,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user4,
				name: "Lisa Lopem",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img2,
		link: "6",
		title: "Ma Yensua Asante Twi - Teacher's Guide 4",
		price: "20.99",
		fav: 0,
		text: "Teacher's Guide for Asante Twi textbook based on the NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Educational",
		publication: "Red Oak",
		author: "S. Banning Peprah",
		reviews: [
			{
				img: user2,
				name: "Andrian jackson",
				date: "12 / 04 / 2022",
				rating: 2,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user3,
				name: "Kevin Kay",
				date: "12 / 04 / 2022",
				rating: 4,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user4,
				name: "Lisa Lopem",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img3,
		link: "7",
		title: "Career Technology CCP Curriculum",
		price: "0",
		fav: 303,
		text: "Teacher's Guide for Asante Twi textbook based on the NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Educational",
		publication: "Red Oak",
		author: "S. Banning Peprah",
		reviews: [
			{
				img: user2,
				name: "Andrian jackson",
				date: "12 / 04 / 2022",
				rating: 2,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user3,
				name: "Kevin Kay",
				date: "12 / 04 / 2022",
				rating: 4,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user4,
				name: "Lisa Lopem",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img4,
		link: "8",
		title: "Arabic Teachers Resource Pack",
		price: "20.99",
		fav: 0,
		text: "Teacher's Guide for Asante Twi textbook based on the NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Educational",
		publication: "Red Oak",
		author: "S. Banning Peprah",
		reviews: [
			{
				img: user1,
				name: "Mr. Jackson Mike",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user4,
				name: "Lisa Lopem",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img1,
		link: "1",
		title: "Core English - Summary 3",
		price: "20.99",
		fav: 33,
		text: "Teacher's Guide for Asante Twi textbook based on the NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Educational",
		publication: "Red Oak",
		author: "S. Banning Peprah",
		reviews: [
			{
				img: user2,
				name: "Andrian jackson",
				date: "12 / 04 / 2022",
				rating: 2,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user3,
				name: "Kevin Kay",
				date: "12 / 04 / 2022",
				rating: 4,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user4,
				name: "Lisa Lopem",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img2,
		link: "2",
		title: "Ma Yensua Asante Twi - Teacher's Guide 4",
		price: "20.99",
		fav: 0,
		text: "Teacher's Guide for Asantv NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Comedy",
		publication: "Red Oak",
		author: "S. Banning",
		reviews: [
			{
				img: user1,
				name: "Mr. Jackson Mike",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user3,
				name: "Kevin Kay",
				date: "12 / 04 / 2022",
				rating: 4,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user4,
				name: "Lisa Lopem",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img3,
		link: "3",
		title: "Career Technology CCP Curriculum",
		price: "0",
		fav: 12,
		text: "Tesante Twi textbook based on the NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Educational",
		publication: "kayd fdo",
		author: "Raing Peprah",
		reviews: [
			{
				img: user1,
				name: "Mr. Jackson Mike",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user2,
				name: "Andrian jackson",
				date: "12 / 04 / 2022",
				rating: 2,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user4,
				name: "Lisa Lopem",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img4,
		link: "4",
		title: "Arabic Teachers Resource Pack",
		price: "20.99",
		fav: 0,
		text: "Teache ES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Comedy",
		publication: "Edulab",
		author: "S.  Peprah",
		reviews: [
			{
				img: user1,
				name: "Mr. Jackson Mike",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user2,
				name: "Andrian jackson",
				date: "12 / 04 / 2022",
				rating: 2,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user3,
				name: "Kevin Kay",
				date: "12 / 04 / 2022",
				rating: 4,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img1,
		link: "5",
		title: "Core English - Summary 3",
		price: "20.99",
		fav: 3,
		text: "Guide for Asante Twi text NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Comedy",
		publication: "Edulab",
		author: "S. Rddf Peprah",
		reviews: [
			{
				img: user3,
				name: "Kevin Kay",
				date: "12 / 04 / 2022",
				rating: 4,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user4,
				name: "Lisa Lopem",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img2,
		link: "6",
		title: "Ma Yensua Asante Twi - Teacher's Guide 4",
		price: "20.99",
		fav: 0,
		text: "Based on the NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Educational",
		publication: "Red Oak",
		author: "S. Peprah",
		reviews: [
			{
				img: user1,
				name: "Mr. Jackson Mike",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user3,
				name: "Kevin Kay",
				date: "12 / 04 / 2022",
				rating: 4,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user4,
				name: "Lisa Lopem",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img3,
		link: "7",
		title: "Career Technology CCP Curriculum",
		price: "0",
		fav: 10,
		text: "Teacher's based on the NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Comedy",
		publication: "Red Oak",
		author: "S. Banning Peprah",
		reviews: [
			{
				img: user2,
				name: "Andrian jackson",
				date: "12 / 04 / 2022",
				rating: 2,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user3,
				name: "Kevin Kay",
				date: "12 / 04 / 2022",
				rating: 4,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user4,
				name: "Lisa Lopem",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img4,
		link: "8",
		title: "Arabic Teachers Resource Pack",
		price: "20.99",
		fav: 0,
		text: "Teacher's Guide for Asante Twi textbook based on the NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Comedy",
		publication: "Red Oak",
		author: "S. Banning Peprah",
		reviews: [
			{
				img: user1,
				name: "Mr. Jackson Mike",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user2,
				name: "Andrian jackson",
				date: "12 / 04 / 2022",
				rating: 2,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user3,
				name: "Kevin Kay",
				date: "12 / 04 / 2022",
				rating: 4,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img5,
		link: "1",
		title: "Core English - Summary 3",
		price: "20.99",
		fav: 13,
		text: "Teacher's Guide for Asante Twi textbook based on the NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Educational",
		publication: "Red Oak",
		author: "S. Banning Peprah",
		reviews: [
			{
				img: user2,
				name: "Andrian jackson",
				date: "12 / 04 / 2022",
				rating: 2,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user3,
				name: "Kevin Kay",
				date: "12 / 04 / 2022",
				rating: 4,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user4,
				name: "Lisa Lopem",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img6,
		link: "2",
		title: "Ma Yensua Asante Twi - Teacher's Guide 4",
		price: "20.99",
		fav: 0,
		text: "Teacher's Guide for Asante Twi textbook based on the NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Educational",
		publication: "Red Oak",
		author: "S. Banning Peprah",
		reviews: [
			{
				img: user1,
				name: "Mr. Jackson Mike",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user3,
				name: "Kevin Kay",
				date: "12 / 04 / 2022",
				rating: 4,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user4,
				name: "Lisa Lopem",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img7,
		link: "3",
		title: "Career Technology CCP Curriculum",
		price: "0",
		fav: 133,
		text: "Teacher's Guide for Asante Twi textbook based on the NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Educational",
		publication: "Red Oak",
		author: "S. Banning Peprah",
		reviews: [
			{
				img: user1,
				name: "Mr. Jackson Mike",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user2,
				name: "Andrian jackson",
				date: "12 / 04 / 2022",
				rating: 2,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user3,
				name: "Kevin Kay",
				date: "12 / 04 / 2022",
				rating: 4,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img8,
		link: "4",
		title: "Arabic Teachers Resource Pack",
		price: "20.99",
		fav: 0,
		text: "Teacher's Guide for Asante Twi textbook based on the NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Educational",
		publication: "Red Oak",
		author: "S. Banning Peprah",
		reviews: [
			{
				img: user1,
				name: "Mr. Jackson Mike",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user2,
				name: "Andrian jackson",
				date: "12 / 04 / 2022",
				rating: 2,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user4,
				name: "Lisa Lopem",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img1,
		link: "5",
		title: "Core English - Summary 3",
		price: "20.99",
		fav: 30,
		text: "Teacher's Guide for Asante Twi textbook based on the NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Educational",
		publication: "Red Oak",
		author: "S. Banning Peprah",
		reviews: [
			{
				img: user2,
				name: "Andrian jackson",
				date: "12 / 04 / 2022",
				rating: 2,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user3,
				name: "Kevin Kay",
				date: "12 / 04 / 2022",
				rating: 4,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user4,
				name: "Lisa Lopem",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img2,
		link: "6",
		title: "Ma Yensua Asante Twi - Teacher's Guide 4",
		price: "20.99",
		fav: 0,
		text: "Teacher's Guide for Asante Twi textbook based on the NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Educational",
		publication: "Red Oak",
		author: "S. Banning Peprah",
		reviews: [
			{
				img: user2,
				name: "Andrian jackson",
				date: "12 / 04 / 2022",
				rating: 2,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user3,
				name: "Kevin Kay",
				date: "12 / 04 / 2022",
				rating: 4,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user4,
				name: "Lisa Lopem",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img3,
		link: "7",
		title: "Career Technology CCP Curriculum",
		price: "0",
		fav: 303,
		text: "Teacher's Guide for Asante Twi textbook based on the NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Educational",
		publication: "Red Oak",
		author: "S. Banning Peprah",
		reviews: [
			{
				img: user2,
				name: "Andrian jackson",
				date: "12 / 04 / 2022",
				rating: 2,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user3,
				name: "Kevin Kay",
				date: "12 / 04 / 2022",
				rating: 4,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user4,
				name: "Lisa Lopem",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img4,
		link: "8",
		title: "Arabic Teachers Resource Pack",
		price: "20.99",
		fav: 0,
		text: "Teacher's Guide for Asante Twi textbook based on the NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Educational",
		publication: "Red Oak",
		author: "S. Banning Peprah",
		reviews: [
			{
				img: user1,
				name: "Mr. Jackson Mike",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user4,
				name: "Lisa Lopem",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img1,
		link: "1",
		title: "Core English - Summary 3",
		price: "20.99",
		fav: 33,
		text: "Teacher's Guide for Asante Twi textbook based on the NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Educational",
		publication: "Red Oak",
		author: "S. Banning Peprah",
		reviews: [
			{
				img: user2,
				name: "Andrian jackson",
				date: "12 / 04 / 2022",
				rating: 2,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user3,
				name: "Kevin Kay",
				date: "12 / 04 / 2022",
				rating: 4,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user4,
				name: "Lisa Lopem",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img2,
		link: "2",
		title: "Ma Yensua Asante Twi - Teacher's Guide 4",
		price: "20.99",
		fav: 0,
		text: "Teacher's Guide for Asantv NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Comedy",
		publication: "Red Oak",
		author: "S. Banning",
		reviews: [
			{
				img: user1,
				name: "Mr. Jackson Mike",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user3,
				name: "Kevin Kay",
				date: "12 / 04 / 2022",
				rating: 4,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user4,
				name: "Lisa Lopem",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img3,
		link: "3",
		title: "Career Technology CCP Curriculum",
		price: "0",
		fav: 12,
		text: "Tesante Twi textbook based on the NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Educational",
		publication: "kayd fdo",
		author: "Raing Peprah",
		reviews: [
			{
				img: user1,
				name: "Mr. Jackson Mike",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user2,
				name: "Andrian jackson",
				date: "12 / 04 / 2022",
				rating: 2,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user4,
				name: "Lisa Lopem",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img4,
		link: "4",
		title: "Arabic Teachers Resource Pack",
		price: "20.99",
		fav: 0,
		text: "Teache ES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Comedy",
		publication: "Edulab",
		author: "S.  Peprah",
		reviews: [
			{
				img: user1,
				name: "Mr. Jackson Mike",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user2,
				name: "Andrian jackson",
				date: "12 / 04 / 2022",
				rating: 2,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user3,
				name: "Kevin Kay",
				date: "12 / 04 / 2022",
				rating: 4,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img1,
		link: "5",
		title: "Core English - Summary 3",
		price: "20.99",
		fav: 3,
		text: "Guide for Asante Twi text NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Comedy",
		publication: "Edulab",
		author: "S. Rddf Peprah",
		reviews: [
			{
				img: user3,
				name: "Kevin Kay",
				date: "12 / 04 / 2022",
				rating: 4,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user4,
				name: "Lisa Lopem",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img2,
		link: "6",
		title: "Ma Yensua Asante Twi - Teacher's Guide 4",
		price: "20.99",
		fav: 0,
		text: "Based on the NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Educational",
		publication: "Red Oak",
		author: "S. Peprah",
		reviews: [
			{
				img: user1,
				name: "Mr. Jackson Mike",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user3,
				name: "Kevin Kay",
				date: "12 / 04 / 2022",
				rating: 4,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user4,
				name: "Lisa Lopem",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img3,
		link: "7",
		title: "Career Technology CCP Curriculum",
		price: "0",
		fav: 10,
		text: "Teacher's based on the NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Comedy",
		publication: "Red Oak",
		author: "S. Banning Peprah",
		reviews: [
			{
				img: user2,
				name: "Andrian jackson",
				date: "12 / 04 / 2022",
				rating: 2,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user3,
				name: "Kevin Kay",
				date: "12 / 04 / 2022",
				rating: 4,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user4,
				name: "Lisa Lopem",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img4,
		link: "8",
		title: "Arabic Teachers Resource Pack",
		price: "20.99",
		fav: 0,
		text: "Teacher's Guide for Asante Twi textbook based on the NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Comedy",
		publication: "Red Oak",
		author: "S. Banning Peprah",
		reviews: [
			{
				img: user1,
				name: "Mr. Jackson Mike",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user2,
				name: "Andrian jackson",
				date: "12 / 04 / 2022",
				rating: 2,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user3,
				name: "Kevin Kay",
				date: "12 / 04 / 2022",
				rating: 4,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img5,
		link: "1",
		title: "Core English - Summary 3",
		price: "20.99",
		fav: 13,
		text: "Teacher's Guide for Asante Twi textbook based on the NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Educational",
		publication: "Red Oak",
		author: "S. Banning Peprah",
		reviews: [
			{
				img: user2,
				name: "Andrian jackson",
				date: "12 / 04 / 2022",
				rating: 2,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user3,
				name: "Kevin Kay",
				date: "12 / 04 / 2022",
				rating: 4,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user4,
				name: "Lisa Lopem",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img6,
		link: "2",
		title: "Ma Yensua Asante Twi - Teacher's Guide 4",
		price: "20.99",
		fav: 0,
		text: "Teacher's Guide for Asante Twi textbook based on the NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Educational",
		publication: "Red Oak",
		author: "S. Banning Peprah",
		reviews: [
			{
				img: user1,
				name: "Mr. Jackson Mike",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user3,
				name: "Kevin Kay",
				date: "12 / 04 / 2022",
				rating: 4,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user4,
				name: "Lisa Lopem",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img7,
		link: "3",
		title: "Career Technology CCP Curriculum",
		price: "0",
		fav: 133,
		text: "Teacher's Guide for Asante Twi textbook based on the NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Educational",
		publication: "Red Oak",
		author: "S. Banning Peprah",
		reviews: [
			{
				img: user1,
				name: "Mr. Jackson Mike",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user2,
				name: "Andrian jackson",
				date: "12 / 04 / 2022",
				rating: 2,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user3,
				name: "Kevin Kay",
				date: "12 / 04 / 2022",
				rating: 4,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img8,
		link: "4",
		title: "Arabic Teachers Resource Pack",
		price: "20.99",
		fav: 0,
		text: "Teacher's Guide for Asante Twi textbook based on the NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Educational",
		publication: "Red Oak",
		author: "S. Banning Peprah",
		reviews: [
			{
				img: user1,
				name: "Mr. Jackson Mike",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user2,
				name: "Andrian jackson",
				date: "12 / 04 / 2022",
				rating: 2,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user4,
				name: "Lisa Lopem",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img1,
		link: "5",
		title: "Core English - Summary 3",
		price: "20.99",
		fav: 30,
		text: "Teacher's Guide for Asante Twi textbook based on the NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Educational",
		publication: "Red Oak",
		author: "S. Banning Peprah",
		reviews: [
			{
				img: user2,
				name: "Andrian jackson",
				date: "12 / 04 / 2022",
				rating: 2,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user3,
				name: "Kevin Kay",
				date: "12 / 04 / 2022",
				rating: 4,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user4,
				name: "Lisa Lopem",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img2,
		link: "6",
		title: "Ma Yensua Asante Twi - Teacher's Guide 4",
		price: "20.99",
		fav: 0,
		text: "Teacher's Guide for Asante Twi textbook based on the NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Educational",
		publication: "Red Oak",
		author: "S. Banning Peprah",
		reviews: [
			{
				img: user2,
				name: "Andrian jackson",
				date: "12 / 04 / 2022",
				rating: 2,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user3,
				name: "Kevin Kay",
				date: "12 / 04 / 2022",
				rating: 4,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user4,
				name: "Lisa Lopem",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img3,
		link: "7",
		title: "Career Technology CCP Curriculum",
		price: "0",
		fav: 303,
		text: "Teacher's Guide for Asante Twi textbook based on the NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Educational",
		publication: "Red Oak",
		author: "S. Banning Peprah",
		reviews: [
			{
				img: user2,
				name: "Andrian jackson",
				date: "12 / 04 / 2022",
				rating: 2,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user3,
				name: "Kevin Kay",
				date: "12 / 04 / 2022",
				rating: 4,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user4,
				name: "Lisa Lopem",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
	{
		img: img4,
		link: "8",
		title: "Arabic Teachers Resource Pack",
		price: "20.99",
		fav: 0,
		text: "Teacher's Guide for Asante Twi textbook based on the NaCCA/GES 2019 Standards- Based Curriculum by Red Oak Limited. ISBN 978-9988-9084-5-4 ",
		genre: "Educational",
		publication: "Red Oak",
		author: "S. Banning Peprah",
		reviews: [
			{
				img: user1,
				name: "Mr. Jackson Mike",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
			{
				img: user4,
				name: "Lisa Lopem",
				date: "12 / 04 / 2022",
				rating: 5,
				text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris`,
			},
		],
	},
];

export default search__category;

import React from 'react'
import { BsCheck2Circle } from 'react-icons/bs'
import { Link } from 'react-router-dom'

const WhoCanJoinCard = ({ index, img, title, text, list, tabName }) => {
  const check__even = index % 2 === 1

  return (
    <div
      id={`scrollSpy-${index}`}
      className={`row gy-5 align-items-center pt-80 ${
        check__even && 'flex-row-reverse'
      } ${index === 0 && 'pt-5'}`}
    >
      <div className="col-lg-6">
        <div
          className={`who-can-join-content mx-auto  ${
            check__even ? 'ps-xxl-5' : 'pe-xxl-5'
          }`}
        >
          <div>
            <h2 className="title">{title}</h2>
            {text && text.map((item, i) => <p key={i}>{item}</p>)}
          </div>
          {list && (
            <ul className="who-can-join-info">
              {list.map((item, j) => (
                <li key={j}>
                  <BsCheck2Circle />
                  <li className="who-can-join-info-text">{item}</li>
                </li>
              ))}
            </ul>
          )}
          <Link
            to="/auth/signup"
            state={{ tabName: tabName }}
            className={`cmn--btn mt-3`}
            style={{ padding: `10px 30px` }}
          >
            Join Us
          </Link>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="who-can-join-img">
          <img src={img} alt="" className="mw-100" />
        </div>
      </div>
    </div>
  )
}

export default WhoCanJoinCard

import React from 'react'
import { Link } from 'react-router-dom'
const PageHeader = ({
  title,
  img,
  parentTitle,
  parentTitleLink,
  currentPageMenu,
}) => {
  return (
    <section
      className="page-header"
      style={{ background: `url(${img}) no-repeat center center / cover` }}
    >
      <div className="container">
        <h1 className="title">{title}</h1>
        <ul className="breadcrumb">
          <li>
            <Link to="/">Home</Link>
            {parentTitle && <Link to={parentTitleLink}> / {parentTitle}</Link>}
          </li>
          <li>{currentPageMenu}</li>
        </ul>
      </div>
    </section>
  )
}

export default PageHeader

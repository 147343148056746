import { default as React } from "react";
import { BsCalendarWeek, BsPeople } from "react-icons/bs";
import { FaPaperPlane } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import comments from "../../assets/images/data/comments";
import events from "../../assets/images/data/event";
import Sidebar from "../../components/sidebar/Sidebar";
const EducationForumSingle = () => {
  const { id } = useParams();
  const { img, date, title, txt } = events[id];
  return (
    <section className="education-forum-single pt-100 pb-100">
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="training-programe-details">
              <div className="top-img">
                <img src={img} alt="" />

                <div className="event-meta d-flex align-items-center mb-4">
                  <span>
                    <BsPeople /> Admin
                  </span>
                  <span>
                    <BsCalendarWeek /> {date}
                  </span>
                </div>
              </div>
              <h3 className="title">{title}</h3>
              <p>{txt}</p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tem incid idunt ut labore et dolore magna aliqua. Ut
                enim ad minim ven iam quis nostrud xerci tation ulla mco laboris
                nisi ut Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                sed do eiusmod tem incid idunt ut labore
              </p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tem incid idunt ut labore et dolore magna aliqua. Ut
                enim ad minim ven iam quis nostrud xerci tation ulla mco laboris
                nisi ut
              </p>

              <div className="tags-area">
                <div className="tags">
                  <h6>Tags</h6>
                  <ul>
                    <li>
                      <Link to="#">Admission</Link>
                    </li>
                    <li>
                      <Link to="#">Research</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <hr className="contact-hr" />
              <div className="comments-list">
                <h3 className="comment-title">Comments</h3>
                <ul className="comments-list-items">
                  {comments &&
                    comments.map(({ user, name, date, txt }, i) => (
                      <li key={i}>
                        <img src={user} alt={name} />
                        <div className="cont">
                          <div className="top">
                            <div>
                              <h5 className="name">{name}</h5>
                              <span className="date">{date}</span>
                            </div>
                            <Link to="#" className="cmn--btn">
                              Reply
                            </Link>
                          </div>
                          <p>{txt}</p>
                        </div>
                      </li>
                    ))}
                </ul>
              </div>
              <hr className="contact-hr" />
              <div className="leave-comments">
                <div className="row g-3 mt-2">
                  <div className="col-12">
                    <textarea
                      className="form-control pt-3 ps-4"
                      placeholder="Comment"
                    ></textarea>
                  </div>

                  <div className="col-12">
                    <button type="submit" className="cmn--btn">
                      Post a Comment <FaPaperPlane />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <Sidebar />
          </div>
        </div>
      </div>
    </section>
  );
};

export default EducationForumSingle;

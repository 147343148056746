import React from 'react'
import {
  FaAngleRight,
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaPhoneAlt,
  FaTwitter,
} from 'react-icons/fa'
import { IoMdMail } from 'react-icons/io'
import { SiGooglemaps } from 'react-icons/si'
import { Link } from 'react-router-dom'
import footer__bg from '../assets/images/footer-bg.png'
import footer_logo from '../assets/images/footer-logo.png'
// import footer_logo from '../assets/images/logo/katon_footer_logo.png'
const Footer = () => {
  return (
    <>
      <footer
        className="section-overlay"
        style={{
          background: `url(${footer__bg}) no-repeat center center / cover`,
        }}
      >
        <div className="container">
          <div className="footer-wrapper">
            <div className="footer-widget widget-about">
              <Link to="/" className="footer-logo">
                <img src={footer_logo} alt="" />
              </Link>
            </div>
            <div className="footer-widget widget-links">
              <h6 className="subtitle">Join Us</h6>
              <ul className="links">
                <li>
                  <Link to="/who-can-join">
                    <FaAngleRight /> Content Expert
                  </Link>
                </li>
                <li>
                  <Link to="/who-can-join">
                    <FaAngleRight /> Freelance Teacher
                  </Link>
                </li>
              </ul>
            </div>
            <div className="footer-widget widget-links">
              <h6 className="subtitle">Quick Links</h6>
              <ul className="links">
                <li>
                  <Link to="/about">
                    <FaAngleRight /> About Us
                  </Link>
                </li>
                <li>
                  <Link to="/360-degree-learning">
                    <FaAngleRight /> What is KATON
                  </Link>
                </li>

                <li>
                  <Link to="/who-can-join">
                    <FaAngleRight /> Learner
                  </Link>
                </li>
                <li>
                  <Link to="/who-can-join">
                    <FaAngleRight /> Parent
                  </Link>
                </li>
              </ul>
            </div>
            <div className="footer-widget widget-links">
              <h6 className="subtitle">Support</h6>
              <ul className="links">
                <li>
                  <Link to="/contact-us">
                    <FaAngleRight /> FAQs
                  </Link>
                </li>
                <li>
                  <Link to="/contact-us">
                    <FaAngleRight /> Contact Us
                  </Link>
                </li>
                <li>
                  <Link to="/privacy-policy">
                    <FaAngleRight /> Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link to="/terms-of-use">
                    <FaAngleRight /> Terms of Use
                  </Link>
                </li>
              </ul>
            </div>
            <div className="footer-widget widget-address">
              <h6 className="subtitle">Address</h6>
              <ul className="adress">
                <li>
                  <span className="map-icon">
                    <FaPhoneAlt />
                  </span>
                  <a href="tel:0800790555" target="_blank">
                    Toll Free: 0800790555
                  </a>
                </li>
                <li>
                  <span className="map-icon">
                    <IoMdMail />
                  </span>
                  <a href="mailto:info@katon.app" target="_blank">
                    info@katon.app
                  </a>
                </li>
                <li>
                  <span className="map-icon">
                    <SiGooglemaps />
                  </span>
                  <span>
                    GA-032-1298 HP Nyametei F809/11th Street Osu, Accra
                  </span>
                </li>
              </ul>
              <div className="follow-us-of">
                <h6 className="f-subtitle">Follow us on</h6>
                <ul className="footer-social">
                  <li>
                    <a href="https://www.facebook.com/katongh" target="_blank">
                      <FaFacebook />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://instagram.com/kat.ongh?igshid=YmMyMTA2M2Y="
                      target="_blank"
                    >
                      <FaInstagram />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="copyright">
        &copy; KATON {new Date().getFullYear()}. All rights Reserved.
      </div>
    </>
  )
}

export default Footer

import React, { useEffect, useState } from 'react'
import { GoPrimitiveDot } from 'react-icons/go'
import { Link, useParams } from 'react-router-dom'
import order_history from '../../assets/images/data/order-history'
import DashboardSidebar from '../../components/DashboardSidebar'
import { updateLiveClassByRoomId } from 'helpers/backendHelpers/liveClass'
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap'
import logo from '../../assets/images/logo.png'
import { getUserInfo } from 'helpers/authHelper'

const LiveClassEnd = () => {
  const { id } = useParams()
  const [classEndModal, setClassEndModal] = useState(false)
  const userInfo = getUserInfo()
  useEffect(() => {
    updateLiveClass(id)
  }, [id])

  const updateLiveClass = async (id) => {
    toggleClassEndModal()
    if (userInfo && userInfo?.userType == 'Teacher') {
      let body = {
        ls_isSessionEnded: 1,
      }

      let response = await updateLiveClassByRoomId(id, body)
      if (response?.status) {
      }
    }
  }

  const toggleClassEndModal = () => {
    setClassEndModal(!classEndModal)
  }

  return (
    <Modal
      isOpen={classEndModal}
      size="lg"
      role="dialog"
      autoFocus={true}
      centered={true}
      className="tutorModal"
      tabIndex="-1"
    >
      <ModalHeader>
        <img src={logo} />
      </ModalHeader>
      <ModalBody className="text-center">
        <h3>Live class ended.</h3>
      </ModalBody>
      <ModalFooter>
        <div>
          <button
            type="button"
            className="cmn--btn w-100"
            onClick={async () => {
              window.parent.location.href = 'https://katondev.in/live-class'
            }}
          >
            <div className="d-flex align-items-center justify-content-center">
              <span>Okay</span>
            </div>
          </button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

export default LiveClassEnd

import React, { useState, useEffect, useContext } from 'react'
import {
  FaAngleDown,
  FaBars,
  FaFacebook,
  FaLinkedin,
  FaPhoneAlt,
  FaRegBell,
  FaShoppingCart,
  FaTwitter,
  FaInstagram,
} from 'react-icons/fa'
import { GrClose } from 'react-icons/gr'
import { IoMdMail } from 'react-icons/io'
import { SiGooglemaps } from 'react-icons/si'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import menu, { dashboard_header_menu } from '../assets/images/data/menu'
import logo from '../assets/images/logo.png'
import default_icon from '../assets/images/default_avatar.png'
// import katon_logo from '../assets/images/logo/katon_logo.png'
import user_img from '../assets/images/user.png'
import { getAuthToken, getUserInfo } from '../helpers/authHelper'
import { getToken } from '../hooks/useUser'
import { getProfile } from '../helpers/backendHelpers/student'
import { IMAGE_URL } from '../helpers/urlHelper'
import { AuthContext } from '../contexts/AuthContext'
import { CgArrowLongRight, CgArrowDown, CgArrowLongDown } from 'react-icons/cg'
import {
  logOutfTeacher,
  logOutParent,
  logOutPublisher,
  logOutUser,
} from '../helpers/backendHelper'
import { removeAuthInfo } from '../helpers/authHelper'
import swal from 'sweetalert'

const Header = (props) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [error, setError] = useState('')
  const [userImage, setUserImage] = useState({})
  const [temp, setTemp] = useState(true)
  const { isAuthenticated, setLogin, setLogout } = useContext(AuthContext)
  const isLoggedIn = localStorage.getItem('authToken')
  const profilePic = localStorage.getItem('profilePic')
  const navigate = useNavigate()
  const userInfo = getUserInfo()
  const location = useLocation()
  const [selectedMenu, setSelectedMenu] = useState('')
  const [selectedSubMenu, setSelectedSubMenu] = useState('')
  const [selectedprofileMenu, setSelectedProfileMenu] = useState('')
  const pathName = location.pathname
  const [newPathName, setNewPathName] = useState(pathName)

  useEffect(() => {
    setError('')
  }, [isLoggedIn])
  useEffect(() => {
    setNewPathName(pathName)
    if (pathName == '/') {
      setSelectedMenu('')
      setSelectedSubMenu('')
      setSelectedProfileMenu('')
    } else {
    }
  }, [pathName])

  useEffect(() => {
    if (newPathName == '/') {
      setSelectedMenu('')
      setSelectedSubMenu('')
      setSelectedProfileMenu('')
    } else {
      const separatedPathName = newPathName.split('/').filter(Boolean)
      setSelectedMenu(separatedPathName[0])
      if (separatedPathName) {
        setSelectedMenu(separatedPathName[0])
        if (separatedPathName[1]) {
          setSelectedSubMenu(separatedPathName[1])
        }
      }
    }
  }, [newPathName])

  useEffect(() => {
    setTemp(!temp)
  }, [profilePic])

  useEffect(() => {
    let li = document.querySelector('#social-icons')
    if (li == null) {
      return false
    } else {
      li.innerHTML = `
      <select name="cars" id="cars" onchange="changeFunc(value);">
        <option class="notranslate" value="1" Selected>
          <a
            href="#"
            title="English"
            class="nturl"
            style="background-position: -0px -0px"
            ><span class="btn notranslate h7 "
              >English</span
          ></a
        ></option>
        <option class="notranslate" value="2">
          <a
            href="#"
            onclick="doGTranslate('en|fr');return false;"
            title="French"
            class="nturl"
            style="background-position: -100px -400px"
            ><span class="btn notranslate h7 "
              >French</span
          ></a
        ></option>
        <option class="notranslate" value="3">
          <a
            href="#"
            onclick="doGTranslate('en|pt');return false;"
            title="Portuguese"
            class="nturl"
            style="background-position: -500px -200px"
            ><span class="btn notranslate h7"
              >Portuguese</span
          ></a
        ></option>
      </select>
      `
    }
    let pathUrl = window.location.href
    if (
      pathUrl.match('/live-class-room') ||
      pathUrl.match('/live-class-end') ||
      pathUrl.match('/mcqTest') ||
      pathUrl.match('/selfAssessmentTest') ||
      pathUrl.match('/trainingExam') ||
      pathUrl.match('/not-found') ||
      pathUrl.match('/epub')
    ) {
    } else {
      var Tawk_API = Tawk_API || {},
        Tawk_LoadStart = new Date()
      ;(function () {
        var s1 = document.createElement('script'),
          s0 = document.getElementsByTagName('script')[0]
        s1.async = true
        s1.src = 'https://embed.tawk.to/635118c5daff0e1306d2fe52/1gfqchheg'
        s1.charset = 'UTF-8'
        s1.setAttribute('crossorigin', '*')
        s0.parentNode.insertBefore(s1, s0)
      })()
    }
  }, [])

  const fetchStudentForEdit = async () => {
    try {
      let response = await getProfile()
      let { studentProfile } = response.data || {}
      setUserImage(studentProfile)
      setTemp(!temp)
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        'Error while fetching profile details'
      return setError(message)
    }
  }

  const handleLogout = async () => {
    console.log('pathname1', pathName)
    const value = await swal('Are you sure you want to logout?', {
      buttons: {
        defeat: 'Log out',
        cancel: 'Cancel',
      },
    })

    if (value == 'defeat') {
      if (userInfo === 'Student') {
        logOutUser()
      } else if (userInfo === 'Teacher') {
        logOutfTeacher()
      } else if (userInfo === 'Parent') {
        logOutParent()
      } else if (userInfo === 'Publisher') {
        logOutPublisher()
      }
      removeAuthInfo()
      setLogout()
      navigate('/auth/signin')
      // props.history.push('/auth/signin', { url: pathName })
      setTemp(!temp)
    }
  }

  const profilePicLocal = localStorage.getItem('profilePic')

  const changeFunc = () => {
    alert('dwad')
  }

  const setMainMenuValue = (mainMenuSlug) => {
    setSelectedMenu('')
    setSelectedSubMenu('')
    setSelectedProfileMenu('')
    setSelectedMenu(mainMenuSlug)
  }

  const setBothMenuValue = (mainMenuSlug, subMenuSlug) => {
    setSelectedMenu('')
    setSelectedSubMenu('')
    setSelectedProfileMenu('')
    setSelectedMenu(mainMenuSlug)
    setSelectedSubMenu(subMenuSlug)
  }

  const profileMenu = [
    {
      text: 'My Library',
      link: '/my-library',
      profileMenuSlug: 'my-library',
    },
    // {
    //   text: 'Order History',
    //   link: '/order-history',
    //   profileMenuSlug: 'order-history',
    // },
    {
      text: 'Messages',
      link: '/messages',
      profileMenuSlug: 'messages',
    },
    {
      text: 'Edit Profile',
      link: '/profile',
      profileMenuSlug: 'profile',
    },
    {
      text: 'Change Password',
      link: '/auth/change-pass',
      profileMenuSlug: '/auth/change-pass',
    },
  ]

  return (
    <>
      <div className="header-top">
        <div className="container">
          <div className="d-flex flex-wrap align-items-center justify-content-evenly justify-content-lg-between">
            <ul className="contact-links">
              <li>
                <Link to="/#">
                  <FaPhoneAlt />
                  <a href="tel:0800790555" target="_blank">
                    Toll Free: 0800790555
                  </a>
                </Link>
              </li>
              <li>
                <a href="mailto:info@katon.app" target="_blank">
                  <IoMdMail />
                  <span>info@katon.app</span>
                </a>
              </li>
            </ul>
            <ul className="header-social-icons" id="header-social-icons">
              <li>
                <a href="https://www.facebook.com/katongh" target="_blank">
                  <FaFacebook />
                </a>
              </li>
              <li>
                <a
                  href="https://instagram.com/kat.ongh?igshid=YmMyMTA2M2Y="
                  target="_blank"
                >
                  <FaInstagram />
                </a>
              </li>
              <li
                id="social-icons"
                className="d-flex justify-content-center align-items-center"
              ></li>
            </ul>
          </div>
        </div>
      </div>
      <div className="header-bottom bg--title">
        <div className="container">
          <div className="header-wrapper">
            <Link
              to="/"
              className="logo"
              onClick={() => {
                setSelectedMenu('')
                setSelectedSubMenu('')
                setSelectedProfileMenu('')
              }}
            >
              <img src={logo} alt="" />
            </Link>
            <div
              className="nav-toggle d-lg-none"
              onClick={() => setMenuOpen(!menuOpen)}
            >
              {!menuOpen ? <FaBars /> : <GrClose />}
            </div>
            <ul
              className={`menu ms-auto ${menuOpen ? 'show-mobile-menu' : ''}`}
            >
              {!isLoggedIn
                ? menu &&
                  menu.map(({ text, link, sub_menu, mainMenuSlug }, i) => (
                    <li key={i}>
                      <Link
                        to={link}
                        onClick={() => {
                          setMainMenuValue(mainMenuSlug)
                        }}
                      >
                        <span
                          className={
                            selectedMenu == mainMenuSlug ||
                            pathName.includes(mainMenuSlug)
                              ? 'selected-group'
                              : ''
                          }
                        >
                          {text}
                        </span>
                        {sub_menu && <FaAngleDown />}
                      </Link>
                      {sub_menu && (
                        <ul className="submenu">
                          {sub_menu
                            .filter((n) => {
                              if (
                                (!userInfo && n.text == 'Group') ||
                                (userInfo &&
                                  userInfo?.userType != 'Student' &&
                                  n.text == 'Group')
                              ) {
                                return false
                              }
                              if (
                                userInfo &&
                                userInfo?.userType == 'Teacher' &&
                                n.text == 'Self Assessment'
                              ) {
                                return false
                              }
                              return true
                            })
                            .map(({ text, link, tabName, subMenuSlug }, i) => (
                              <li key={i}>
                                <Link
                                  className={
                                    selectedSubMenu == subMenuSlug
                                      ? 'selected-group'
                                      : ''
                                  }
                                  to={link}
                                  state={{ tabName: tabName }}
                                  onClick={() => {
                                    setBothMenuValue(mainMenuSlug, subMenuSlug)
                                  }}
                                >
                                  {text}
                                </Link>
                              </li>
                            ))}
                        </ul>
                      )}
                    </li>
                  ))
                : dashboard_header_menu &&
                  dashboard_header_menu.map(
                    (
                      { text, link, sub_menu, joinUssub_menu, mainMenuSlug },
                      i,
                    ) => (
                      <>
                        <li key={i}>
                          <Link
                            to={link}
                            onClick={() => {
                              setMainMenuValue(mainMenuSlug)
                            }}
                          >
                            <span
                              className={
                                selectedMenu == mainMenuSlug
                                  ? 'selected-group'
                                  : ''
                              }
                            >
                              {text}
                            </span>
                            {sub_menu && <FaAngleDown />}
                            {!isLoggedIn
                              ? joinUssub_menu && <FaAngleDown />
                              : null}
                          </Link>
                          {sub_menu && (
                            <ul className="submenu">
                              {sub_menu
                                .filter((n) => {
                                  if (
                                    (!userInfo && n.text == 'Group') ||
                                    (userInfo &&
                                      userInfo?.userType != 'Student' &&
                                      n.text == 'Group')
                                  ) {
                                    return false
                                  }
                                  if (
                                    userInfo &&
                                    userInfo?.userType == 'Teacher' &&
                                    n.text == 'Self Assessment'
                                  ) {
                                    return false
                                  }
                                  return true
                                })
                                .map(({ text, link, subMenuSlug }, i) => (
                                  <li key={i}>
                                    <Link
                                      className={
                                        selectedSubMenu == subMenuSlug
                                          ? 'selected-group'
                                          : ''
                                      }
                                      to={link}
                                      onClick={() => {
                                        setBothMenuValue(
                                          mainMenuSlug,
                                          subMenuSlug,
                                        )
                                      }}
                                    >
                                      {text}
                                    </Link>
                                  </li>
                                ))}
                            </ul>
                          )}
                          {joinUssub_menu && (
                            <ul className="submenu">
                              {joinUssub_menu.map(({ text, link }, i) => (
                                <li key={i}>
                                  <Link to={link}>{text}</Link>
                                </li>
                              ))}
                            </ul>
                          )}
                        </li>
                      </>
                    ),
                  )}
              {userInfo && menuOpen && (
                <li>
                  <Link onClick={() => handleLogout()} to={'#'}>
                    <span>Log Out1</span>
                  </Link>
                </li>
              )}
            </ul>
            {isLoggedIn && (
              <ul className="menu ms-2 ms-xl-4">
                <li>
                  <Link to="/my-library" className="p-0">
                    <img
                      style={{
                        height: '42px',
                        width: '42px',
                        borderRadius: '50%',
                      }}
                      src={
                        profilePicLocal &&
                        profilePicLocal != null &&
                        profilePicLocal != 'null'
                          ? `${IMAGE_URL}/${profilePicLocal}`
                          : default_icon
                      }
                      alt=""
                    />
                    <FaAngleDown />
                  </Link>
                  <ul className="submenu">
                    <li
                      onClick={() => {
                        setSelectedMenu('')
                        setSelectedSubMenu('')
                      }}
                    >
                      {profileMenu.map(({ text, link, profileMenuSlug }, i) => {
                        return (
                          <Link
                            className={
                              selectedprofileMenu == profileMenuSlug ||
                              pathName.includes(profileMenuSlug)
                                ? 'selected-group'
                                : ''
                            }
                            to={link}
                            onClick={() => {
                              setSelectedMenu('')
                              setSelectedSubMenu('')
                              setSelectedProfileMenu(profileMenuSlug)
                            }}
                          >
                            {text}
                          </Link>
                        )
                      })}
                      <Link to={'#'} onClick={() => handleLogout()}>
                        Logout
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default Header

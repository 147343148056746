import React, { useEffect, useMemo, useState } from 'react'
import events from '../../assets/images/data/event'
import EventCard from '../../components/EventCard'
import SectionHeader from '../../components/SectionHeader'
import {
  getLiveClassByTeacher,
  getLiveClasses,
  getLiveClassesRecorded,
} from '../../helpers/backendHelpers/liveClass'
import LiveClassCard from './LiveClassCard'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { BsChevronDown, BsSearch } from 'react-icons/bs'
import {
  getCategories,
  getSubCategories,
  getMainCategories,
  filterBookByBookTitle,
  getTopics,
} from '../../helpers/backendHelpers/book'
import { getFullUserInfo, getUserInfo } from '../../helpers/authHelper'
import Select from 'react-select'
import SubmitLoader from 'components/Common/SubmitLoader'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'

const SignupSchema = Yup.object().shape({
  mainCategory: Yup.string().optional(),
  category: Yup.string().optional(),
  subcategory: Yup.string().optional(),
  topic: Yup.string().optional(),
})

const LiveClass = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [liveClasses, setLiveClasses] = useState([])
  const [recordedLiveClasses, setRecordedLiveClasses] = useState([])
  const [error, setError] = useState('')
  const [selectedMainCategory, setSelectedMainCategory] = useState('')
  const [selectedCategory, setSelectedCategory] = useState('')
  const [selectedSubCategory, setSelectedSubCategory] = useState('')
  const [selectedTopic, setSelectedTopic] = useState('')
  const [selectMainCategory, setSelectMainCategory] = useState([])
  const [categoryList, setCategoryList] = useState([])
  const [SubCategoryList, setSubCategoryList] = useState([])
  const [topicList, setTopicList] = useState([])
  const [selectedCategoryDropdown, setSelectedCategoryDropdown] = useState('')
  const [selectedSubCategoryDropdown, setSelectedSubCategoryDropdown] =
    useState('')
  const [selectedTopicDropdown, setSelectedTopicDropdown] = useState('')
  const [noRecords, setNoRecords] = useState(false)
  const [noRecordsForRecording, setNoRecordsForRecording] = useState(false)
  const userInfo = getUserInfo()
  const fullUserInfo = getFullUserInfo()
  // 1 = Scheduled,2 = History
  const [key, setKey] = useState('1')
  const [teacherId, setTeacherId] = useState('')

  useEffect(() => {
    getAllLiveClass('', '', '', userInfo?.id)
    getAllRecordedLiveClass('', '', '', userInfo?.id)
    getAllMainCategories()
    getAllCategories()
    getAllSubCategories()
    getAllTopics()
  }, [])

  useEffect(() => {
    if (
      userInfo &&
      (userInfo.userType == 'Teacher' || userInfo.userType == 'Freelance')
    ) {
      setTeacherId(userInfo?.id)
    } else {
      setTeacherId('')
    }
  }, [userInfo])

  const handleMainCategoryChange = (e) => {
    setSelectedMainCategory(e.target.value)
    setSelectedCategory('')
    setSelectedSubCategory('')
    setSelectedTopic('')
    getAllCategories(e.target.value)
  }

  const handleCategoryChange = (value) => {
    setSelectedCategory(value)
    setSelectedSubCategory('')
    setSelectedTopic('')
    getAllSubCategories(value)
  }

  const handleSubCategoryChange = (value) => {
    setSelectedSubCategory(value)
    getAllTopics(value)
    setSelectedTopic('')
  }

  const handleTopicChange = (value) => {
    setSelectedTopic(value)
  }

  const getAllLiveClass = async (
    selectedCategory = '',
    selectedSubCategory = '',
    selectedTopic = '',
    teacherId = '',
  ) => {
    try {
      let ls_level =
        userInfo?.userType == 'Teacher' || userInfo?.userType == 'Freelance'
          ? fullUserInfo?.tc_level
          : fullUserInfo?.st_level

      setIsLoading(true)
      let tc_id =
        userInfo?.userType == 'Teacher' || userInfo?.userType == 'Freelance'
          ? userInfo?.id
          : ''
      let response = await getLiveClasses(
        selectedCategory,
        selectedSubCategory,
        selectedTopic,
        tc_id,
        ls_level,
      )
      let { liveSession } = response.data
      setLiveClasses(liveSession)
      if (liveSession.length == 0) {
        setNoRecords(true)
      } else {
        setNoRecords(false)
      }
      setIsLoading(false)
    } catch (error) {
      setError(error)
      setIsLoading(false)
    }
  }

  const getAllRecordedLiveClass = async (
    selectedCategory = '',
    selectedSubCategory = '',
    selectedTopic = '',
    teacherId = '',
  ) => {
    try {
      let ls_level =
        userInfo?.userType == 'Teacher' || userInfo?.userType == 'Freelance'
          ? fullUserInfo?.tc_level
          : fullUserInfo?.st_level
      let tc_id =
        userInfo?.userType == 'Teacher' || userInfo?.userType == 'Freelance'
          ? userInfo?.id
          : ''
      setIsLoading(true)
      let response = await getLiveClassesRecorded(
        selectedCategory,
        selectedSubCategory,
        selectedTopic,
        tc_id,
        ls_level,
      )
      let { liveSession } = response.data
      setRecordedLiveClasses(liveSession)
      if (liveSession.length == 0) {
        setNoRecordsForRecording(true)
      } else {
        setNoRecordsForRecording(false)
      }
      setIsLoading(false)
    } catch (error) {
      setError(error)
      setIsLoading(false)
    }
  }

  const getAllLiveClassByTeacher = async (tc_id) => {
    try {
      setIsLoading(true)
      let response = await getLiveClassByTeacher(tc_id)
      let { liveSession } = response.data
      setLiveClasses(liveSession)
      if (liveSession.length == 0) {
        setNoRecords(true)
      } else {
        setNoRecords(false)
      }
      setIsLoading(false)
    } catch (error) {
      setError(error)
      setIsLoading(false)
    }
  }

  const getAllMainCategories = async () => {
    try {
      let response = await getMainCategories()
      let { mainCategories } = response.data

      let mainCategoryVals = mainCategories.map((mainCategory) => {
        return {
          id: mainCategory.cc_id,
          value: mainCategory.cc_categoryName,
        }
      })
      setSelectMainCategory(mainCategoryVals)
    } catch (error) {
      setError(error)
    }
  }

  const getAllCategories = async (filterCategory = '') => {
    try {
      let response = await getCategories()
      let { categories } = response.data
      let vals = []
      let mainCategoryArray = []

      if (filterCategory) {
        let filteredData = categories.filter(
          (data) => data.categoryId == filterCategory,
        )
        filteredData[0].category.map((data) => {
          vals.push({ name: data.CategoryName, value: data.CategoryName })
        })
      } else {
        categories.map((mainCategory) => {
          let val = []
          let { category } = mainCategory

          category.map((cat) => {
            val.push({ label: cat.CategoryName, value: cat.CategoryName })
          })
          mainCategoryArray.push({
            label: mainCategory.categoryName,
            options: val,
          })
        })
      }
      setCategoryList(mainCategoryArray)
    } catch (error) {
      setError(error)
    }
  }

  const getAllSubCategories = async (filterCategory = '') => {
    try {
      let response = await getSubCategories()
      let { subCategories } = response.data
      let vals = []
      if (filterCategory) {
        subCategories.map((mainCategory) => {
          let { category } = mainCategory
          category.map((Category) => {
            let { subCategory } = Category
            if (Category.categoryName === filterCategory) {
              subCategory.map((cat) => {
                vals.push({ label: cat.subCateName, value: cat.subCateName })
              })
            }
          })
        })
      } else {
        subCategories.map((mainCategory) => {
          let { category } = mainCategory
          category.map((Category) => {
            let { subCategory } = Category
            subCategory.map((cat) => {
              vals.push({ label: cat.subCateName, value: cat.subCateName })
            })
          })
        })
      }
      setSubCategoryList(vals)
    } catch (error) {
      setError(error)
    }
  }

  const getAllTopics = async (filterCategory = '') => {
    try {
      let response = await getTopics()
      let { topics } = response.data
      let vals = []
      if (filterCategory) {
        topics.map((mainCategory) => {
          let { category } = mainCategory
          category.map((Category) => {
            let { subCategory } = Category
            subCategory.map((subCat) => {
              let { topics } = subCat
              if (subCat.subCateName === filterCategory) {
                topics.map((topic) => {
                  vals.push({ label: topic.topicName, value: topic.topicName })
                })
              }
            })
          })
        })
      } else {
        topics.map((mainCategory) => {
          let { category } = mainCategory
          category.map((Category) => {
            let { subCategory } = Category
            subCategory.map((subCat) => {
              let { topics } = subCat
              topics.map((topic) => {
                vals.push({ label: topic.topicName, value: topic.topicName })
              })
            })
          })
        })
      }
      setTopicList(vals)
    } catch (error) {
      setError(error)
    }
  }

  const resetAllCategories = () => {
    setSelectedCategory('')
    setSelectedSubCategory('')
    setSelectedTopic('')
    getAllLiveClass()
    getAllRecordedLiveClass()
    setSelectedCategoryDropdown('')
    setSelectedSubCategoryDropdown('')
    setSelectedTopicDropdown('')
  }

  return (
    <section className="training-program py-5">
      <div className="container">
        <div className="row books-section">
          {/* <div className="books-sidebar col-lg-3">
            <div className="books-sidebar-inner">
              <div className="books-sidebar-widget text-center">
                <div className="books-sidebar-top books-sidebar-widget p-0">
                  <h4 className="m-0">Search</h4>
                  <button
                    className="cmn--btn"
                    type="button"
                    onClick={resetAllCategories}
                  >
                    Reset
                  </button>
                </div>
              </div>
              <div className="books-sidebar-widget">
                <div className="books-wrapper-header">
                  <Formik
                    initialValues={{
                      mainCategory: '',
                      category: '',
                      subcategory: '',
                      topic: '',
                    }}
                    validationSchema={SignupSchema}
                    onSubmit={(values, actions) => {
                      // getFilterQuestions()
                    }}
                  >
                    {({
                      errors,
                      touched,
                      isSubmitting,
                      handleSubmit,
                      handleChange,
                      setFieldValue,
                      values,
                      handleBlur,
                    }) => (
                      <Form autoComplete="off" onSubmit={handleSubmit}>
                        <div className="filter-form-wrapper-pastPaper">
                          <div className="select--item">
                            <div className="inner">
                              <Select
                                name="category"
                                placeholder="Class/Grade"
                                value={
                                  selectedCategoryDropdown
                                    ? selectedCategoryDropdown
                                    : ''
                                }
                                onChange={(e) => {
                                  handleCategoryChange(e.value)
                                  setSelectedCategoryDropdown(e)
                                  setFieldValue('category', e ? e.value : '')
                                }}
                                options={categoryList}
                                invalid={touched.st_class && errors.st_class}
                              />
                            </div>
                          </div>
                          <div className="select--item">
                            <div className="inner">
                              <Select
                                name="subcategory"
                                placeholder="Subject"
                                value={
                                  selectedSubCategoryDropdown
                                    ? selectedSubCategoryDropdown
                                    : ''
                                }
                                onChange={(e) => {
                                  handleSubCategoryChange(e.value)
                                  setSelectedSubCategoryDropdown(e)
                                  setFieldValue('subcategory', e ? e.value : '')
                                }}
                                options={SubCategoryList}
                                invalid={touched.st_class && errors.st_class}
                              />
                            </div>
                          </div>

                          <div className="btn-item">
                            <button
                              // disabled={isSubmitting}
                              className="cmn--btn"
                              type="submit"
                              onClick={() => {
                                if (key == '1') {
                                  getAllLiveClass(
                                    selectedCategory,
                                    selectedSubCategory,
                                    selectedTopic,
                                    userInfo?.id,
                                  )
                                } else {
                                  getAllRecordedLiveClass(
                                    selectedCategory,
                                    selectedSubCategory,
                                    selectedTopic,
                                    userInfo?.id,
                                  )
                                }
                              }}
                            >
                              Search
                            </button>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div> */}
          <div className="col-lg-12 live-class">
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className="mb-3"
            >
              <Tab eventKey="1" title="Scheduled">
                <div className="col-lg-12">
                  <div style={{ minHeight: '300px' }}>
                    {isLoading ? (
                      <div
                        style={
                          isLoading
                            ? {
                                position: 'relative',
                                opacity: '0.8',
                                minHeight: '300px',
                              }
                            : {}
                        }
                      >
                        <SubmitLoader />
                      </div>
                    ) : (
                      <>
                        <div className="row g-4">
                          {liveClasses && liveClasses.length > 0
                            ? liveClasses.map((item, i) => (
                                <div className="col-lg-3 col-md-3" key={i}>
                                  <LiveClassCard
                                    tab={key}
                                    SubCategoryList={SubCategoryList}
                                    classes="short-event"
                                    {...item}
                                  />
                                </div>
                              ))
                            : noRecords && (
                                <div
                                  className="d-flex justify-content-center align-items-center"
                                  style={{ minHeight: '300px' }}
                                >
                                  <h1>No system activity found.</h1>
                                </div>
                              )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </Tab>
              <Tab eventKey="2" title="History">
                <div className="col-lg-12">
                  <div style={{ minHeight: '300px' }}>
                    {isLoading ? (
                      <div
                        style={
                          isLoading
                            ? {
                                position: 'relative',
                                opacity: '0.8',
                                minHeight: '300px',
                              }
                            : {}
                        }
                      >
                        <SubmitLoader />
                      </div>
                    ) : (
                      <>
                        <div className="row g-4">
                          {recordedLiveClasses && recordedLiveClasses.length > 0
                            ? recordedLiveClasses.map((item, i) => (
                                <div className="col-lg-3 col-md-3" key={i}>
                                  <LiveClassCard
                                    tab={key}
                                    SubCategoryList={SubCategoryList}
                                    classes="short-event"
                                    {...item}
                                  />
                                </div>
                              ))
                            : noRecordsForRecording && (
                                <div
                                  className="d-flex justify-content-center align-items-center"
                                  style={{ minHeight: '300px' }}
                                >
                                  <h1>No system activity found.</h1>
                                </div>
                              )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </section>
  )
}

export default LiveClass

import React from 'react'
import { Spinner } from 'reactstrap'
const ButtonLoader = ({ height = '2rem', width = '2rem' }) => {
  console.log('height1', height)
  return (
    <>
      <Spinner
        className="ms-2"
        style={{
          // position: 'absolute',
          // top: '-4%',
          color: '#aeaeae',
          // left: '50%',
          zIndex: '1000',
          width: width,
          height: height,
          marginRight: '10px',
        }}
        // color="primary"
      />
    </>
  )
}

export default ButtonLoader

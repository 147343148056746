import { getAuthToken, getUserInfo } from '../../../helpers/authHelper'
const userInfo = getUserInfo()
const menu = [
  {
    text: 'Library',
    link: '/library',
    mainMenuSlug: 'library',
  },
  {
    text: 'Training',
    link: '/training-program',
    mainMenuSlug: 'training-program',
  },
  {
    text: 'Live Class',
    link: '/live-class',
    mainMenuSlug: 'live-class',
  },
  {
    text: 'Practice',
    link: '#',
    mainMenuSlug: 'practice',
    sub_menu: [
      {
        text: 'Assignment',
        link: '/practice/assignment',
        subMenuSlug: 'assignment',
      },
      {
        text: 'Past Questions',
        link: '/practice/pastQuestion',
        subMenuSlug: 'pastQuestion',
      },
      {
        text: 'Self Assessment',
        link: '/practice/selfAssessment',
        subMenuSlug: 'selfAssessment',
      },
    ],
  },
  {
    text: 'Connect',
    link: '#',
    mainMenuSlug: 'connect',
    sub_menu: [
      {
        text: 'Blog',
        link: '/connect/blog',
        subMenuSlug: 'blog',
      },
      {
        text: 'Group',
        link: '/connect/group',
        subMenuSlug: 'group',
      },
    ],
  },
  {
    text: 'Get Started',
    link: '#',
    mainMenuSlug: 'auth/signup',
    sub_menu: [
      {
        text: 'Freelance Teacher',
        link: '/auth/signup',
        tabName: 'Teacher',
        subMenuSlug: 'signUpTeacher',
      },
      {
        text: 'Premium Learner',
        link: '/auth/signup',
        tabName: 'Student',
        subMenuSlug: 'signUpStudent',
      },
      // {
      //   text: 'Parent',
      //   link: '/auth/signup',
      //   tabName: 'Parent',
      //   subMenuSlug: 'signUpParent',
      // },
      // {
      //   text: 'Publisher',
      //   link: '/auth/signup',
      //   tabName: 'Publisher',
      //   subMenuSlug: 'signUpPublisher',
      // },
    ],
  },
  {
    text: 'Sign In',
    link: '#',
    mainMenuSlug: 'auth/signin',
    sub_menu: [
      {
        text: 'Teacher',
        link: '/auth/signin',
        tabName: 'Teacher',
        subMenuSlug: 'signInTeacher',
      },
      {
        text: 'Learner',
        link: '/auth/signin',
        tabName: 'Student',
        subMenuSlug: 'signInStudent',
      },
      // {
      //   text: 'Parent',
      //   link: '/auth/signin',
      //   tabName: 'Parent',
      //   subMenuSlug: 'signInParent',
      // },
      // {
      //   text: 'Publisher',
      //   link: '/auth/signin',
      //   tabName: 'Publisher',
      //   subMenuSlug: 'signInPublisher',
      // },
    ],
  },
]
export default menu
export const dashboard_header_menu = [
  {
    text: 'Library',
    link: '/library',
    mainMenuSlug: 'library',
  },
  {
    text: 'Training',
    link: '/training-program',
    mainMenuSlug: 'training-program',
  },
  {
    text: 'Live Class',
    link: '/live-class',
    mainMenuSlug: 'live-class',
  },
  {
    text: 'Practice',
    link: '#',
    mainMenuSlug: 'practice',
    sub_menu: [
      {
        text: 'Assignment',
        link: '/practice/assignment',
        subMenuSlug: 'assignment',
      },
      {
        text: 'Past Questions',
        link: '/practice/pastQuestion',
        subMenuSlug: 'pastQuestion',
      },
      {
        text: 'Self Assessment',
        link: '/practice/selfAssessment',
        subMenuSlug: 'selfAssessment',
      },
    ],
  },
  {
    text: 'Connect',
    link: '#',
    mainMenuSlug: 'connect',
    sub_menu: [
      {
        text: 'Blog',
        link: '/connect/blog',
        subMenuSlug: 'blog',
      },

      {
        text: 'Group',
        link: '/connect/group',
        subMenuSlug: 'group',
      },
    ],
  },
  // {
  //   text: 'What is KATON',
  //   link: '#',
  //   sub_menu: [
  //     {
  //       text: 'About us',
  //       link: '/about',
  //     },
  //     {
  //       text: 'Who Can Join Us',
  //       link: '/who-can-join',
  //     },
  //     {
  //       text: '360-degree Learning',
  //       link: '/360-degree-learning',
  //     },
  //   ],
  // },
]

import { get, post, put } from '../apiHelper'
import { getApiConfig, getUserInfo } from '../authHelper'
import * as url from '../urlHelper'

export const createSelfAssessment = (data) => {
  return post(`${url.SELFASSESSMENT}/`, data, getApiConfig())
}

export const updateSelfAssessment = (id, data) => {
  return put(`${url.SELFASSESSMENT}/${id}`, data, getApiConfig())
}

export const getAllSelfAssessment = () => {
  return get(
    `${url.SELFASSESSMENT}/getAll`,
    getApiConfig(),
  )
}

export const getAllQueBySelfAssessment = (sa_id) => {
  return get(
    `${url.SELFASSESSMENT}/getAllQueBySelfAssessment?sa_id=${sa_id}`,
    getApiConfig(),
  )
}

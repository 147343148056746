import React, { useEffect, useState } from 'react'
import {
  getCategories,
  getSubCategories,
  getTopics,
} from '../../helpers/backendHelpers/book'
import { BsCalendarWeek } from 'react-icons/bs'
import { IMAGE_URL } from '../../helpers/urlHelper'
import { Link } from 'react-router-dom'
import { getAllPastPaper } from '../../helpers/backendHelpers/pastPaper'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { BsChevronDown } from 'react-icons/bs'
import PaginatedItems from '../../components/PaginatedItems'
// import FilterPastPaperQuestion from './FilterPastPaperQuestion'
import { getAllQueByFilter } from '../../helpers/backendHelpers/allQuestions'
import moment from 'moment'
import {
  createSelfAssessment,
  getAllSelfAssessment,
} from '../../helpers/backendHelpers/selfAssessment'
import '../practice/McqTest.scss'
import { getUserInfo } from '../../helpers/authHelper'
import { FaCheck } from 'react-icons/fa'
import SubmitLoader from 'components/Common/SubmitLoader'
const PastPaperFilterQuestionSchema = Yup.object().shape({
  mainCategory: Yup.string().optional(),
  category: Yup.string().optional(),
  subcategory: Yup.string().optional(),
  topic: Yup.string().optional(),
})

const SelfAssessment = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [selfAssessment, setSelfAssessment] = useState([])
  const [selectCategory, setSelectCategory] = useState([])
  const [selectSubCategory, setSelectSubCategory] = useState([])
  const [SubCategoryList, setSubCategoryList] = useState([])
  const [selectTopic, setSelectTopic] = useState([])
  const [selectYear, setSelectYear] = useState([])

  const [selectedCategory, setSelectedCategory] = useState('')
  const [selectedSubCategory, setSelectedSubCategory] = useState('')
  const [selectedTopic, setSelectedTopic] = useState('')
  const [selectedYear, setSelectedYear] = useState('')

  const [showQuestion, setShowQuestion] = useState(false)

  const [error, setError] = useState('')
  const [queAns, setQueAns] = useState([])
  const [noRecords, setNoRecords] = useState(false)
  const userInfo = getUserInfo()

  const getSelfAssessmentData = async () => {
    try {
      setIsLoading(true)
      let response = await getAllSelfAssessment()
      let { selfAssessment } = response.data
      setSelfAssessment(selfAssessment)
      setIsLoading(false)
      if (selfAssessment.length == 0) {
        setNoRecords(true)
      } else {
        setNoRecords(false)
      }
    } catch (error) {
      setError(error)
      setIsLoading(false)
    }
  }

  const getAllCategories = async (filterCategory = '') => {
    try {
      let response = await getCategories()
      let { categories } = response.data
      let vals = []
      if (filterCategory) {
        let filteredData = categories.filter(
          (data) => data.categoryId == filterCategory,
        )
        filteredData[0].category.map((data) => {
          vals.push({ name: data.CategoryName, value: data.CategoryName })
        })
      } else {
        categories.map((mainCategory) => {
          let { category } = mainCategory
          category.map((cat) => {
            if (userInfo && userInfo.userType == 'Student') {
              if (!cat.CategoryName.toLowerCase().includes('teacher')) {
                vals.push({ name: cat.CategoryName, value: cat.CategoryName })
              }
            } else {
              vals.push({ name: cat.CategoryName, value: cat.CategoryName })
            }
          })
        })
      }

      setSelectCategory(vals)
    } catch (error) {
      setError(error)
    }
  }

  const getAllSubCategories = async (filterCategory = '') => {
    try {
      let response = await getSubCategories()

      let { subCategories } = response.data
      let vals = []
      if (filterCategory) {
        subCategories.map((mainCategory) => {
          let { category } = mainCategory
          category.map((Category) => {
            let { subCategory } = Category
            if (Category.categoryName === filterCategory) {
              subCategory.map((cat) => {
                vals.push({ name: cat.subCateName, value: cat.subCateName })
              })
            }
          })
        })
      } else {
        subCategories.map((mainCategory) => {
          let { category } = mainCategory
          category.map((Category) => {
            let { subCategory } = Category
            subCategory.map((cat) => {
              vals.push({ name: cat.subCateName, value: cat.subCateName })
            })
          })
        })
        let tempVals = []
        vals.length > 0 &&
          vals.map((data, i) => {
            if (i < 4) {
              tempVals.push(data.value)
            }
          })
      }
      setSelectSubCategory(vals)
      setSubCategoryList(vals)
    } catch (error) {
      setError(error)
    }
  }

  const getAllTopics = async (filterCategory = '') => {
    try {
      let response = await getTopics()
      let { topics } = response.data
      let vals = []
      if (filterCategory) {
        topics.map((mainCategory) => {
          let { category } = mainCategory
          category.map((Category) => {
            let { subCategory } = Category
            subCategory.map((subCat) => {
              let { topics } = subCat
              if (subCat.subCateName === filterCategory) {
                topics.map((topic) => {
                  vals.push({ name: topic.topicName, value: topic.topicName })
                })
              }
            })
          })
        })
      } else {
        topics.map((mainCategory) => {
          let { category } = mainCategory
          category.map((Category) => {
            let { subCategory } = Category
            subCategory.map((subCat) => {
              let { topics } = subCat
              topics.map((topic) => {
                vals.push({ name: topic.topicName, value: topic.topicName })
              })
            })
          })
        })
      }
      setSelectTopic(vals)
    } catch (error) {
      setError(error)
    }
  }

  const handleCategoryChange = (e) => {
    const category = e.target.value
    setSelectedCategory(category)
    setSelectedSubCategory('')
    setSelectedTopic('')
    getAllSubCategories(category)
  }
  const handleSubCategoryChange = (e) => {
    const subCategory = e.target.value
    setSelectedSubCategory(subCategory)
    setSelectedTopic('')
    getAllTopics(subCategory)
  }
  const handleTopicChange = (e) => {
    const topic = e.target.value
    setSelectedTopic(topic)
  }

  useEffect(() => {
    getSelfAssessmentData()
    getAllCategories()
    getAllSubCategories()
    getAllTopics()
  }, [])

  const getFilterQuestions = async () => {
    try {
      setIsLoading(true)
      let data = {
        sa_category: selectedCategory,
        sa_subCategory: selectedSubCategory,
        sa_topic: selectedTopic,
      }
      let response = await createSelfAssessment(data)

      let { status } = response
      if (status) {
        getSelfAssessmentData()
      }

      setIsLoading(false)
    } catch (error) {
      setError(error)
      setIsLoading(false)
    }
  }

  const handleReset = () => {
    setShowQuestion(false)
    setSelectedCategory('')
    setSelectedSubCategory('')
    setSelectedTopic('')
  }

  return (
    <>
      <section className="practice py-5">
        <div className="container">
          <div className="row books-section">
            <div className="books-sidebar col-lg-3">
              <div className="books-sidebar-inner">
                <div className="books-sidebar-widget text-center">
                  <h4 className="m-0">Self Assessments</h4>
                </div>
                <div className="books-sidebar-widget">
                  <div className="books-wrapper-header">
                    <Formik
                      initialValues={{
                        category: '',
                        subcategory: '',
                        topic: '',
                      }}
                      validationSchema={PastPaperFilterQuestionSchema}
                      onSubmit={(values, actions) => {
                        getFilterQuestions()
                      }}
                    >
                      {({
                        errors,
                        touched,
                        isSubmitting,
                        handleSubmit,
                        handleChange,
                        values,
                        handleBlur,
                      }) => (
                        <Form autoComplete="off" onSubmit={handleSubmit}>
                          <div className="filter-form-wrapper-pastPaper">
                            <div className="select--item ">
                              <div className="inner">
                                <Field
                                  as="select"
                                  name="category"
                                  className={`form--control ${
                                    errors.category && touched.category
                                      ? 'form-err'
                                      : ''
                                  }`}
                                  value={
                                    selectedCategory
                                      ? selectedCategory
                                      : 'Select Category'
                                  }
                                  onChange={(e) => {
                                    handleCategoryChange(e)
                                  }}
                                >
                                  <option>
                                    {`${
                                      errors.category && touched.category
                                        ? 'Select a Category Must'
                                        : 'Class/Grade'
                                    }`}
                                  </option>
                                  {selectCategory &&
                                    selectCategory.map(({ name, val }, i) => (
                                      <option value={val} key={i}>
                                        {name}
                                      </option>
                                    ))}
                                </Field>
                                <span className="caret-icon">
                                  <BsChevronDown />
                                </span>
                              </div>
                            </div>

                            <div className="select--item ">
                              <div className="inner">
                                <Field
                                  as="select"
                                  name="subcategory"
                                  className={`form--control ${
                                    errors.subcategory && touched.subcategory
                                      ? 'form-err'
                                      : ''
                                  }`}
                                  value={
                                    selectedSubCategory
                                      ? selectedSubCategory
                                      : ''
                                  }
                                  onChange={(e) => {
                                    handleSubCategoryChange(e)
                                  }}
                                >
                                  <option>
                                    {`${
                                      errors.subcategory && touched.subcategory
                                        ? 'Select a Sub Category Must'
                                        : 'Subject'
                                    }`}
                                  </option>

                                  {SubCategoryList &&
                                    SubCategoryList.map(({ name, val }, i) => (
                                      <option value={val} key={i}>
                                        {name}
                                      </option>
                                    ))}
                                </Field>
                                <span className="caret-icon">
                                  <BsChevronDown />
                                </span>
                              </div>
                            </div>

                            <div className="select--item ">
                              <div className="inner">
                                <Field
                                  as="select"
                                  name="topic"
                                  className={`form--control ${
                                    errors.topic && touched.topic
                                      ? 'form-err'
                                      : ''
                                  }`}
                                  value={selectedTopic ? selectedTopic : ''}
                                  onChange={(e) => {
                                    handleTopicChange(e)
                                  }}
                                >
                                  <option>
                                    {`${
                                      errors.topic && touched.topic
                                        ? 'Select a Topic'
                                        : 'Topic'
                                    }`}
                                  </option>

                                  {selectTopic &&
                                    selectTopic.map(({ name, val }, i) => (
                                      <option value={val} key={i}>
                                        {name}
                                      </option>
                                    ))}
                                </Field>
                                <span className="caret-icon">
                                  <BsChevronDown />
                                </span>
                              </div>
                            </div>

                            <div className="btn-item">
                              <button
                                // disabled={isSubmitting}
                                className="cmn--btn"
                                type="submit"
                                // onClick={handleFilterBookCahange}
                              >
                                Generate Paper
                              </button>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-9">
              <div style={{ minHeight: '300px' }}>
                {isLoading ? (
                  <div
                    style={
                      isLoading
                        ? {
                            position: 'relative',
                            opacity: '0.8',
                            minHeight: '300px',
                          }
                        : {}
                    }
                  >
                    <SubmitLoader />
                  </div>
                ) : (
                  <>
                    {noRecords && (
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ minHeight: '300px' }}
                      >
                        <h1>No system activity found.</h1>
                      </div>
                    )}
                    <div className="row">
                      {!showQuestion ? (
                        <>
                          {selfAssessment &&
                            selfAssessment.map((data, i) => {
                              return (
                                <>
                                  <div className="col-md-6 mb-3 " key={i}>
                                    <div className="pastPaperBox bg-white p-4 rounded-4">
                                      <h4>{data.sa_title}</h4>
                                      &nbsp;
                                      <div className="d-flex justify-content-between">
                                        <p>{data.sa_subCategory}</p>
                                        {data.sa_score != null ? (
                                          <p className="theme-text">
                                            Score {data.sa_score}/
                                            {data.sa_totalMarks}
                                          </p>
                                        ) : (
                                          <p>Marks : {data.sa_totalMarks}</p>
                                        )}
                                      </div>
                                      <div className=" d-block ">
                                        <div className="d-flex justify-content-end">
                                          <span className="me-auto d-flex align-items-center">
                                            <BsCalendarWeek />
                                            &nbsp;{' '}
                                            {moment(data.sa_date_time).format(
                                              'DD MMM, YYYY',
                                            )}
                                          </span>
                                          {data.sa_score ? (
                                            <Link
                                              className="checkResultBtn"
                                              rel="noopener noreferrer"
                                              style={{ display: 'table-cell' }}
                                            >
                                              Done
                                            </Link>
                                          ) : (
                                            <Link
                                              // to={`/pastQuestion/${data?.sa_id}`}
                                              className="detailBtn"
                                              onClick={() => {
                                                window.open(
                                                  `/selfAssessmentTest/${data?.sa_id}/${userInfo?.id}`,
                                                  'Popup',
                                                  'toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0,maximize=1',
                                                )
                                              }}
                                              rel="noopener noreferrer"
                                              style={{ display: 'table-cell' }}
                                            >
                                              Start Exam
                                            </Link>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )
                            })}
                        </>
                      ) : (
                        {
                          /* <FilterPastPaperQuestion queAns={queAns} /> */
                        }
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default SelfAssessment

import React, { useState, useEffect } from 'react'
import queryString from 'query-string'

// import { ApiGet, ApiPost, ApiUpload, Bucket } from '../../helpers/API/ApiData'
// import * as userUtil from '../../utils/user.util'
import { Document, Page, pdfjs } from 'react-pdf'
import 'react-toastify/dist/ReactToastify.css'
import { IoArrowBack } from 'react-icons/io5'
import { MdCancel, MdPictureAsPdf } from 'react-icons/md'
import { toast, ToastContainer } from 'react-toastify'
import SVG from 'react-inlinesvg'
// import ControlPanel from '../PDF Reader/ControlPanel'
import { Modal } from 'react-bootstrap'
import moment from 'moment'
import { RiFilePdfFill } from 'react-icons/ri'
import { useDispatch, useSelector } from 'react-redux'
// import { fetchFlag, fetchVal } from 'app/store/action/action'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { IMAGE_URL } from '../../helpers/urlHelper'
import PDfModal from '../../components/Common/PdfMoal'
import { getBooksByTPAPI } from 'helpers/backendHelpers/tariningProgram'
import fileSvg from '../../assets/images/file.svg'
import {
  updateTrainingParticipants,
  uploadSignedForm,
} from 'helpers/backendHelpers/trainingParticipants'
import SubmitLoader from 'components/Common/SubmitLoader'

const TrainingResources = (props) => {
  let { id } = useParams()
  const [pdfModal, setPdfModal] = useState(false)
  // const dispatch = useDispatch()
  const [buttons, setButtons] = useState(false)
  const [pdfs, setPdf] = useState([])
  const [maincategory, setMainCategory] = useState([])
  const [state, setstate] = useState(0)
  // const [val, setVal] = useState(accountData[0]?.subject[0]?.title)
  const [userInfo, setUserInfo] = useState({})
  const [scale, setScale] = useState(1.0)
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const [modal, setModal] = useState(false)
  const [IDs, setIDs] = useState()
  const [userID, setUserID] = useState()
  const [titleName, setTitleName] = useState('')
  const [topicCover, setTopicCover] = useState()
  const [pdfFile, setPdfFile] = useState('')
  const [accountData, setAccountData] = useState([])
  const [flagManage, setFlagManage] = useState(false)
  const [noRecords, setNoRecords] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const tps_id = location?.state?.tps_id
  const trainingStatus = location?.state?.trainingStatus

  useEffect(() => {
    getBooksByTP(id)
  }, [])

  const getBooksByTP = async (id) => {
    try {
      setIsLoading(true)
      let response = await getBooksByTPAPI(id)
      let { trainingResourceData } = response.data
      setAccountData(trainingResourceData)
      if (trainingResourceData.length == 0) {
        setNoRecords(true)
      } else {
        setNoRecords(false)
      }
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  const pdfReader = (v, title) => {
    setModal(!modal)
    setIDs(v)
    setTitleName(title)

    // window.location.reload();
  }
  // let videoData = accountData[0]?.subject
  const updateVideoLog = (i) => {
    let body = {
      logUserId: userID,
      subjectId: accountData[0]._id,
      logLatestDate: moment(new Date()).format(),
      topicCovered: i + 1,
    }
  }
  const nextVideo = (i) => {
    setstate(i + 1)

    if (topicCover < i + 1 + 1) {
      updateVideoLog(i + 1)
    }
  }
  const togglePdfModal = (pdfFile) => {
    setPdfFile(pdfFile)
    setPdfModal(!pdfModal)
  }
  //
  const preVideo = (i) => {
    setstate(i - 1)
    // setFlag(false);
    // dispatch(fetchFlag(false))
    //
  }

  const uploadDoc = (i) => {
    // setstate(i + 1);
    // setFlag(true);
    // dispatch(fetchFlag(true))
  }

  const handleChangeImage2 = (e, i) => {
    //
    let { name } = e.target
    if (name == 'pdf_document') {
      let file = e.target.files[0]
      if (e.target.files.length !== 0) {
        let fileURL = window.URL.createObjectURL(file)
        file.fileURL = fileURL
        setPdf([file])
      }
    }
    setButtons(true)

    // setCount(count + 1);
  }

  const PDFupload = async () => {
    try {
      setIsLoading(true)
      const data = {
        tps_signedForm: pdfs[0],
      }
      const response = await uploadSignedForm(data, tps_id)
      let message = response?.message || 'Form Uploaded'
      if (response?.status) {
        let body = {
          // 1 = default, 2 = Image Uploaded, 3 = Admin Approved Start Exam, 4 = Exam Passed,5 = Certificate Download
          tps_trainingStatus: 2,
        }
        const updateStatusRes = await updateTrainingParticipants(body, tps_id)
        if (updateStatusRes?.status) {
          alert('Form Uploaded Successfully')
          navigate(`/training-program/${id}`)
        }
      }
      setIsLoading(false)
      // return setSuccess(message)
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        'Error while profile update'
      setIsLoading(false)
      // return setError(message)
    }
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
    setIsLoading(false)
  }

  const getVideoLog = (i, iD) => {
    // ApiGet(`/video_training_log/${i}`)
    //   .then((res) => {
    //     setTopicCover(
    //       res.data.data[0]?.topicCovered === undefined
    //         ? 1
    //         : res.data.data[0]?.topicCovered,
    //     )
    //     if (res.data.data.length === 0) {
    //       let body = {
    //         logUserId: i,
    //         subjectId: accountData[0]._id,
    //         logLatestDate: moment(new Date()).format(),
    //         topicCovered: 1,
    //         // isCompleted: true,
    //       }
    //       ApiPost(`/video_training_log/add`, body)
    //         .then((res) => {
    //           // setCategory(res.data.data);
    //         })
    //         .catch((err) => {
    //           if (err.status == 410) {
    //             // history.push("/postlist");
    //           } else {
    //             // toast.error(err.message);
    //           }
    //         })
    //     }
    //     // setCategory(res.data.data);
    //   })
    //   .catch((err) => {
    //     if (err.status == 410) {
    //       // history.push("/postlist");
    //     } else {
    //       // toast.error(err.message);
    //     }
    //   })
  }

  useEffect(() => {
    if (pdfFile && pdfFile.length !== 0) {
      setPdf([pdfFile])
    }
  }, [])

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    setUserID(userInfo && userInfo.id)
    getVideoLog(userInfo && userInfo.id)
  }, [state])

  const downloadPdf = () => {
    let v = {
      title: 'Demo Form',
      pdf: '/kt_book/bk_pdf/1663389089200.pdf',
    }
    fetch(IMAGE_URL + v.pdf, { method: 'GET' })
      .then((res) => {
        return res.blob()
      })
      .then((blob) => {
        var url = window.URL.createObjectURL(blob)
        var a = document.createElement('a')
        a.href = url
        a.download = `${v.title}.pdf`
        document.body.appendChild(a)
        a.click()
        setTimeout((_) => {
          window.URL.revokeObjectURL(url)
        }, 60000)
        a.remove()
      })
      .catch((err) => {
        console.error('err: ', err)
      })
  }

  useEffect(() => {
    async function updateParticipantDetails() {
      if (state === accountData[0]?.subject.length - 1) {
        try {
          setIsLoading(true)
          let body = {
            tps_isTrainingCompleted: 1,
          }
          const updateStatusRes = await updateTrainingParticipants(body, tps_id)
          setIsLoading(false)
        } catch (error) {
          let message =
            error?.response?.data?.message ||
            error?.message ||
            'Error while profile update'
          setIsLoading(false)
        }
      }
    }
    updateParticipantDetails()
  }, [state])

  return (
    <>
      <div style={{ minHeight: '300px' }}>
        {isLoading ? (
          <div
            style={
              isLoading
                ? { position: 'relative', opacity: '0.8', minHeight: '300px' }
                : {}
            }
          >
            <SubmitLoader />
          </div>
        ) : (
          <>
            {noRecords && (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ minHeight: '300px' }}
              >
                <h1>No system activity found.</h1>
              </div>
            )}
            <section className="practice py-5">
              <div className="container">
                <div className="row">
                  <div className="training_res_title box_shadow rounded d-flex position-relative justify-content-center py-2 mb-3 align-items-center">
                    <div className="position-absolute left_15">
                      <IoArrowBack
                        className="cursor-pointer"
                        onClick={() => navigate(`/training-program/${id}`)}
                        color="#00bde2"
                        fontSize={20}
                      />
                    </div>
                    <h4
                      className={`font_size_20 font_bold color_blue px-5 mx-5 font_capital`}
                    >
                      {accountData[0]?.title}
                    </h4>
                  </div>
                  <div className="col-md-3 pl-0">
                    <div className="box_shadow rounded">
                      <h3 className="font_size_22 font_bold color_gray px-3 py-3 border-bottom ">
                        Topic
                      </h3>
                      <div className="pb-4">
                        {accountData[0]?.subject.map((item, i) => {
                          {
                            /* id = i */
                          }

                          return (
                            <p
                              key={i}
                              className={`px-3 pt-4 font_size_16 font_capital pointer ${
                                // val === item.title
                                state === i
                                  ? 'font_bold color_blue'
                                  : 'font_regular color_light_gray'
                              }`}
                              // onClick={() => toggle(item._id, item.title, i)}
                            >
                              {item.title}
                            </p>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-9 pr-0  rounded">
                    {/* <video
          // onEnded={() => ended()}
          autoPlay
          width="100%"
          height="500px"
          // frameborder="0"
        >
          <source
            src={Bucket + accountData[0]?.subject[i].video}
            type="video/mp4"
          />
        </video> */}

                    {/* {accountData[0]?.subject.map((title, i) => {
          
          return val === title.title ? (
            <>
              <h3
                className={`font_size_32 font_bold color_gray px-3 font_capital`}
              >
                {title.title}
              </h3>
              <div className="px-3 py-3">
                {title.video ? (
                  <iframe
                    src={Bucket + title.video}
                    width="100%"
                    height="500px"
                    frameborder="0"
                  ></iframe>
                ) : (
                  <div className="font_size_32 font_bold color_gray px-3 font_capital text-center">
                    <img
                      src="assets/media/noVideo.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                )}
              </div>
            </>
          ) : (
            ""
          );
        })} */}

                    {/* <h1>{accountData[0]?.subject[state]?.title}</h1> */}
                    {flagManage === true ? (
                      <div className="box_shadow rounded d-flex px-2 py-3 align-items-center justify-content-between flex-column">
                        <div
                          className="text-end px-2 w-100 color_blue font_bold pointer"
                          onClick={() => {
                            setFlagManage(false)
                            // dispatch(fetchFlag(false))
                          }}
                        >
                          X
                        </div>
                        <h3 className="font-weight-bold font_size_32 letter_spacing font_bold color_gray text-center pt-4">
                          Congratulations !
                        </h3>
                        <div className="font_size_20 py-3 font_medium color_gray text-center">
                          You have successfully completed your coursework for{' '}
                          <span className="font_size_20 py-3 font_medium color_blue text-capitalize">
                            {accountData[0].title}
                          </span>
                        </div>
                        <div className="font_size_20 py-3  color_light_gray text-center">
                          Just a few steps to your certification. <br></br>
                          You are required to download and complete the
                          Attestation Form by clicking the link below.
                        </div>
                        <button
                          // href={Bucket + PDF}

                          className="cmn--btn py-2 text-white font_bold  rounded border_none text-decoration-none text-center linear_gradient px-4 mx-1"
                          onClick={() => downloadPdf()}
                          // onClick={() => history.push("/mcqTest")}
                        >
                          Download Attention Form
                        </button>
                        <div className="font_size_20 py-3  color_light_gray text-center">
                          Upload the form back on KATon using the upload link
                          below. <br></br>
                          You will be able to proceed with your certification
                          examination after the form is duly submitted.
                        </div>
                        <div className="d-flex justify-content-center py-3 w-60">
                          <div className="d-flex justify-content-center bgInput w-100">
                            {pdfs.length > 0 ? (
                              <>
                                <div className="py-2 UploadField color_blue d-flex flex-column align-items-center justify-content-center w-100 rounded border-none mx-1">
                                  <div className="position-relative">
                                    <a
                                      // href={
                                      //   pdf.fileURL
                                      //     ? pdf.fileURL
                                      //     : Bucket + pdf
                                      // }
                                      href="#"
                                      target="_blank"
                                    >
                                      <MdPictureAsPdf
                                        fontSize={70}
                                        color="#00bde2"
                                      />
                                    </a>
                                    <MdCancel
                                      className="pointer position-absolute right_-5 top_-5"
                                      color="red"
                                      fontSize={22}
                                      onClick={() => setPdf([])}
                                    />{' '}
                                  </div>
                                </div>
                              </>
                            ) : (
                              <label
                                for="formFileMultiple3"
                                class="py-2 UploadField color_blue d-flex flex-column align-items-center justify-content-center w-100 rounded border-none mx-1"
                              >
                                <div className="d-flex flex-column align-items-center justify-content-center">
                                  <SVG src={fileSvg} width="50px" />
                                  <div className="">Upload Signed Form</div>
                                </div>
                              </label>
                            )}
                            <input
                              // accept="image/*"
                              className="form-control"
                              type="file"
                              id="formFileMultiple3"
                              name="pdf_document"
                              hidden
                              // value={data.pdf}
                              onChange={(e) => handleChangeImage2(e)}
                            />
                          </div>
                        </div>
                        {pdfs.length !== 0 && (
                          <button
                            disabled={isLoading}
                            className="cmn--btn py-2 text-white font_bold width50 rounded border-none linear_gradient mx-1"
                            onClick={() => PDFupload()}
                          >
                            Submit
                          </button>
                        )}
                      </div>
                    ) : (
                      <>
                        <div className="training_res_title box_shadow rounded d-flex  py-2 mb-3 align-items-center justify-content-between">
                          <div className="col-md-8">
                            {state === accountData[0]?.subject.length - 1 ? (
                              <div className="d-flex align-items-center">
                                <button
                                  className="cmn--btn linear_gradient rounded border-none w-25 text-white font_bold font_capital py-1 ms-3"
                                  onClick={() => preVideo(state)}
                                >
                                  Previous
                                </button>
                              </div>
                            ) : (
                              <>
                                {state !== 0 && (
                                  <button
                                    className="cmn--btn linear_gradient rounded border-none w-25 text-white font_bold font_capital py-1 ms-3"
                                    onClick={() => preVideo(state)}
                                  >
                                    Previous
                                  </button>
                                )}
                                <button
                                  className="cmn--btn linear_gradient rounded border-none w-25 text-white font_bold font_capital py-1 ms-3"
                                  onClick={() => nextVideo(state)}
                                >
                                  Next
                                </button>
                              </>
                            )}
                          </div>
                          <div className="col-md-4 d-flex justify-content-end">
                            {accountData[0]?.subject[state]?.pdf && (
                              <button
                                className="cmn--btn rounded viewAllBtn border-none py-1 mx-3"
                                onClick={() => {
                                  togglePdfModal(
                                    accountData[0]?.subject[state]?.pdf,
                                  )
                                }}
                              >
                                Read PDF
                              </button>
                            )}
                          </div>
                        </div>
                        {accountData[0]?.subject[state]?.video ? (
                          // <iframe
                          //   src={Bucket + accountData[0]?.subject[state]?.video}
                          //   width="100%"
                          //   height="500px"
                          //   key={state}
                          //   frameborder="0"
                          // ></iframe>
                          <video
                            width="100%"
                            style={{ borderRadius: '7px' }}
                            controls
                            controlsList="nodownload"
                            key={state}
                          >
                            <source
                              // src={IMAGE_URLaccountData[0]?.subject[state]?.video}
                              src={`${IMAGE_URL}/${accountData[0]?.subject[state]?.video}`}
                              type="video/mp4"
                            />
                          </video>
                        ) : (
                          <div
                            className="box_shadow rounded d-flex align-items-center justify-content-center color_blue font_size_20"
                            style={{ height: '300px' }}
                          >
                            No Video Available for this topic. Plaease Read the
                            Resource Material in PDF
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  <PDfModal
                    isOpen={pdfModal}
                    toggle={togglePdfModal}
                    pdf={pdfFile}
                    type="pdf"
                  />
                  {/* <Modal
              show={modal}
              centered
              // onHide={() => setModal(!modal)}
              size="xl"
              // aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton onClick={() => setModal(!modal)}>
                <Modal.Title
                  id="contained-modal-title-vcenter "
                  className="color_blue font_size_20"
                >
                  {titleName}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <section
                  id="pdf-section"
                  className="d-flex flex-column align-items-center w-100 bg_gray paddingBottom45 position-relative"
                >
                  <ControlPanel
                    scale={scale}
                    setScale={setScale}
                    numPages={numPages}
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                    // file={Bucket + IDs}
                  />
                  <Document
                    // file={Bucket + IDs}
                    onLoadSuccess={onDocumentLoadSuccess}
                  >
                    <Page pageNumber={pageNumber} scale={scale} />
                  </Document>
                </section>
              </Modal.Body>
              <Modal.Footer>
                <div></div>
              </Modal.Footer>
            </Modal> */}
                </div>
              </div>
            </section>
          </>
        )}
      </div>
    </>
  )
}

export default TrainingResources

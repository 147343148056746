import React from 'react'
import { Link } from 'react-router-dom'
import android from '../../../assets/images/banner/android_icon.svg'
import android_1 from '../../../assets/images/banner/android_icon_1.svg'
import playstore from '../../../assets/images/banner/playstore.svg'
import ios_1 from '../../../assets/images/banner/ios_icon_1.svg'

const BannerContent = (props) => {
  return (
    <div className="container">
      <div className="banner-content">
        <h1 className="title">{props.title}</h1>
        <p className="txt">{props.subTitle}</p>

        <div className="d-flex align-items-center">
          <Link to="/auth/signup" className="cmn--btn">
            join
          </Link>
          {/* <Link to="/" className="banner_icon d-flex ms-4">
            <img src={android_1} alt="" className="" />
            <img src={ios_1} alt="" className="ms-4" />
          </Link> */}
          <a
            target="_blank"
            href="https://play.google.com/store/apps/details?id=com.katon.student"
            className="ms-4"
          >
            <img src={playstore} alt="" className="banner_icon" />
          </a>
          {/* <Link to="/" className="ms-4">
            <img src={ios_1} alt="" className="banner_icon" />
          </Link> */}
        </div>
      </div>
    </div>
  )
}

export default BannerContent

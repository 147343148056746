import Speech from '../../../speech'
import { GrClose } from 'react-icons/gr'
import { useNavigate } from 'react-router-dom'

const CloseIcon2 = () => {
  const navigate = useNavigate()
  
  let data = 'ooo'
  return (
    <div className="extra" onClick={() => navigate('/my-library')}>
      <Speech text={data} stop={true} />
      <GrClose className="iconc" />
    </div>
  )
}

export default CloseIcon2

import ReaderWrapper from './container/Reader.tsx'
import { useSearchParams } from 'react-router-dom'

const EPub = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  let ePubUrl = searchParams.get('ePub')
  // const EPUB_URL = `https://katonproduction.s3.eu-central-1.amazonaws.com/618e69f83314ee4398fd3bd8/pdf/1674829299725.epub`
  const EPUB_URL = `https://dashapi.katondev.in/uploads/${ePubUrl}`
  // 'https://katon.katechnologiesgh.com/epub'
  return <ReaderWrapper url={EPUB_URL} isSpeech="web" />
}

export default EPub

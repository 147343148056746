import React from 'react'
import { Link } from 'react-router-dom'
import Scrollspy from 'react-scrollspy'
import who_can_join from '../../assets/images/data/who_can_join'
import './WhoCanJoin.scss'
import WhoCanJoinCard from './WhoCanJoinCard'

const WhoCanJoin = () => {
  return (
    <section className="who-can-join-us-section py-5">
      <div className="container">
        <div className="who-can-join-nav">
          <Scrollspy
            items={['scrollSpy-0', 'scrollSpy-1', 'scrollSpy-2', 'scrollSpy-3']}
            className="nav nav-pills"
            currentClassName="active"
            offset={-130}
          >
            {who_can_join &&
              who_can_join.map(
                (item, j) => (
                  (
                    <li className="nav-item" key={j}>
                      <Link className="nav-link" to={`#scrollSpy-${j}`}>
                        <img src={item.icon} alt={item.title} />
                        {item.title}
                      </Link>
                      <a href={`#scrollSpy-${j}`} className="click-spy">
                        &nbsp;
                      </a>
                    </li>
                  )
                ),
              )}
          </Scrollspy>
        </div>
        <div>
          {who_can_join &&
            who_can_join.map((item, i) => (
              <WhoCanJoinCard index={i} {...item} key={i} />
            ))}
        </div>
      </div>
    </section>
  )
}

export default WhoCanJoin

import React, { useEffect, useState } from 'react'
import category__filter from '../../assets/images/data/filter-category'
import './Books.scss'
import bookImage from '../../assets/images/finalBook.jpg'

import { Field, Form, Formik } from 'formik'
import { useRef } from 'react'
import { BsChevronDown, BsSearch } from 'react-icons/bs'
import * as Yup from 'yup'
import PaginatedItems from '../../components/PaginatedItems'
import BookItem from './BookItem'
import {
  getBooks,
  getCategories,
  getSubCategories,
  filterBooks,
  getMainCategories,
  filterBookByBookTitle,
  getTopics,
} from '../../helpers/backendHelpers/book'
import { getBookGenre } from '../../helpers/backendHelpers/genre'
import SubmitLoader from '../../components/Common/SubmitLoader'
import { MdOutlineLocalLibrary, MdMenuBook } from 'react-icons/md'
import { TbNotebook } from 'react-icons/tb'
import { Link } from 'react-router-dom'
import { getAuthToken, getUserInfo } from '../../helpers/authHelper'
import { classRoomType } from 'helpers/dropdownVals'
import Select from 'react-select'
import BooksSingle from './BooksSingle'
import { IMAGE_URL } from '../../helpers/urlHelper'
// import Pagination from '@mui/material/Pagination'
import { styled } from '@mui/material/styles'
import PaginationItem from '@mui/material/PaginationItem'
import Pagination from 'components/pagination'

const SignupSchema = Yup.object().shape({
  mainCategory: Yup.string().optional(),
  bookName: Yup.string().optional(),
  category: Yup.string().optional(),
  subcategory: Yup.string().optional(),
  topic: Yup.string().optional(),
})
const Books = () => {
  const [isFree, setIsFree] = useState('')
  const [resource, setResource] = useState('0')
  const [isFreeBool, setIsFreeBool] = useState('')
  const [category, setCategory] = useState([])
  const [subject, setSubject] = useState([])
  const [freezedBooks, setFreezedBooks] = useState([])
  const [books, setBooks] = useState([])
  const [booksForSearch, setBooksForSearch] = useState('')
  const [booksSearchItem, setBooksSearchItem] = useState([])
  const [selectMainCategory, setSelectMainCategory] = useState([])
  const [selectedMainCategory, setSelectedMainCategory] = useState('')
  const [categoryList, setCategoryList] = useState([])
  const [selectSubCategory, setSelectSubCategory] = useState([])
  const [SubCategoryList, setSubCategoryList] = useState([])
  const [topicList, setTopicList] = useState([])
  const [subCategoryListForSidebar, setSubCategoryListForSidebar] = useState([])
  const [searchBookName, setSearchBookName] = useState('')
  const [genreData, setGenreData] = useState([])
  const [error, setError] = useState('')
  const [noBooks, setNoBooks] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isReset, setIsReset] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState('')
  const [selectedSubCategory, setSelectedSubCategory] = useState('')
  const [selectedTopic, setSelectedTopic] = useState('')
  const [selectedCategoryDropdown, setSelectedCategoryDropdown] = useState('')
  const [selectedSubCategoryDropdown, setSelectedSubCategoryDropdown] =
    useState('')
  const userInfo = getUserInfo()
  const isLoggedIn = getAuthToken() ? true : false
  const [showDetails, setShowDetails] = useState(false)
  const [bookId, setBookId] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [apiCurrentPage, setApiCurrentPage] = useState(1)

  const [bookCount, setBookCount] = useState(0)
  const [limit, setLimit] = useState(200)
  const [temp, setTemp] = useState(1)
  const [flag, setFlag] = useState(true)
  const [pageNumbers, setPageNumbers] = useState([])
  const [perPageLimit, setPerPageLimit] = useState(20)
  const [isResourcesSearched, setIsResourcesSearched] = useState(false)
  const [currentBooks, setCurrentBooks] = useState([])

  const ref = useRef()
  const handleSubjectChange = (e) => {
    var updatedList = [...subject]
    if (e.target.checked) {
      updatedList = [...subject, e.target.value]
    } else {
      updatedList.splice(subject.indexOf(e.target.value), 1)
    }
    setSubject(updatedList)
    handleFilterChange(
      selectedCategory,
      selectedSubCategory,
      updatedList,
      isFreeBool,
      resource,
    )
  }

  const handleReset = () => {
    setCurrentPage(1)
    setApiCurrentPage(1)
    setBooks([])
    setFreezedBooks([])
    setGenreData([])
    setIsFree('')
    setSelectedCategory('')
    setSelectedSubCategory('')
    setSelectedCategoryDropdown('')
    setSelectedSubCategoryDropdown('')
    setSelectedTopic('')
    setSearchBookName('')
    setNoBooks(false)
    setSubject([])
    setIsReset(!isReset)
    setResource('0')
    unCheck()
  }
  const unCheck = () => {
    var x = document.getElementsByClassName('subjects')
    let i = 0
    for (i = 0; i <= x.length; i++) {
      x[i].checked = false
    }
  }
  const setInitialState = () => {
    setSelectedCategory('')
    setSelectSubCategory('')
    setSubCategoryList('')
    setSearchBookName('')
    setSubject([])
    setGenreData([])
    setIsFree('')
    setIsFreeBool('')
  }

  useEffect(() => {
    setInitialState()
    getAllMainCategories()
    getAllBooks()
    getAllCategories()
    getAllSubCategories()
    getAllTopics()
    getAllGenre()
    // setResource('0')
  }, [isReset])

  useEffect(() => {
    console.log('bookcheck')
    // 0 = All, 1 = pdf, 2 = video, 3 = epub
    let filterdBooks
    setCurrentPage(1)
    setApiCurrentPage(1)
    if (!resource && subject.length == 0 && !isFree) {
    } else {
      setBooks([])
    }
    if (booksForSearch.length > 0) {
      if (subject.length > 0) {
        if (isFree) {
          if (resource && resource > 0) {
            if (isFree == 'free') {
              if (resource == 1) {
                filterdBooks = booksForSearch.filter(
                  (data) =>
                    data.bk_pdf != null &&
                    data.bk_isFree &&
                    subject?.includes(data.bk_subCategory),
                )
              } else if (resource == 2) {
                filterdBooks = booksForSearch.filter(
                  (data) =>
                    data.bk_video != null &&
                    data.bk_isFree &&
                    subject?.includes(data.bk_subCategory),
                )
              } else if (resource == 3) {
                filterdBooks = booksForSearch.filter(
                  (data) =>
                    data.bk_epub != null &&
                    data.bk_isFree &&
                    subject?.includes(data.bk_subCategory),
                )
              }
            } else if (isFree == 'paid') {
              if (resource == 1) {
                filterdBooks = booksForSearch.filter(
                  (data) =>
                    data.bk_pdf != null &&
                    !data.bk_isFree &&
                    subject?.includes(data.bk_subCategory),
                )
              } else if (resource == 2) {
                filterdBooks = booksForSearch.filter(
                  (data) =>
                    data.bk_video != null &&
                    !data.bk_isFree &&
                    subject?.includes(data.bk_subCategory),
                )
              } else if (resource == 3) {
                filterdBooks = booksForSearch.filter(
                  (data) =>
                    data.bk_epub != null &&
                    !data.bk_isFree &&
                    subject?.includes(data.bk_subCategory),
                )
              }
            } else {
              if (resource == 1) {
                filterdBooks = booksForSearch.filter(
                  (data) =>
                    data.bk_pdf != null &&
                    subject?.includes(data.bk_subCategory),
                )
              } else if (resource == 2) {
                filterdBooks = booksForSearch.filter(
                  (data) =>
                    data.bk_video != null &&
                    subject?.includes(data.bk_subCategory),
                )
              } else if (resource == 3) {
                filterdBooks = booksForSearch.filter(
                  (data) =>
                    data.bk_epub != null &&
                    subject?.includes(data.bk_subCategory),
                )
              }
            }
          } else {
            if (isFree == 'free') {
              filterdBooks = booksForSearch.filter(
                (data) =>
                  data.bk_isFree && subject?.includes(data.bk_subCategory),
              )
            } else if (isFree == 'paid') {
              filterdBooks = booksForSearch.filter(
                (data) =>
                  !data.bk_isFree && subject?.includes(data.bk_subCategory),
              )
            }
          }
        } else {
          if (resource == 0) {
            // getAllBooks()
            filterdBooks = booksForSearch.filter((data) =>
              subject?.includes(data.bk_subCategory),
            )
          } else if (resource == 1) {
            filterdBooks = booksForSearch.filter(
              (data) =>
                data.bk_pdf != null && subject?.includes(data.bk_subCategory),
            )
          } else if (resource == 2) {
            filterdBooks = booksForSearch.filter(
              (data) =>
                data.bk_video != null && subject?.includes(data.bk_subCategory),
            )
          } else if (resource == 3) {
            filterdBooks = booksForSearch.filter(
              (data) =>
                data.bk_epub != null && subject?.includes(data.bk_subCategory),
            )
          }
        }
      } else {
        if (isFree) {
          if (resource && resource > 0) {
            if (isFree == 'free') {
              if (resource == 1) {
                filterdBooks = booksForSearch.filter(
                  (data) => data.bk_pdf != null && data.bk_isFree,
                )
              } else if (resource == 2) {
                filterdBooks = booksForSearch.filter(
                  (data) => data.bk_video != null && data.bk_isFree,
                )
              } else if (resource == 3) {
                filterdBooks = booksForSearch.filter(
                  (data) => data.bk_epub != null && data.bk_isFree,
                )
              }
            } else if (isFree == 'paid') {
              if (resource == 1) {
                filterdBooks = booksForSearch.filter(
                  (data) => data.bk_pdf != null && !data.bk_isFree,
                )
              } else if (resource == 2) {
                filterdBooks = booksForSearch.filter(
                  (data) => data.bk_video != null && !data.bk_isFree,
                )
              } else if (resource == 3) {
                filterdBooks = booksForSearch.filter(
                  (data) => data.bk_epub != null && !data.bk_isFree,
                )
              }
            }
          } else {
            if (isFree == 'free') {
              filterdBooks = booksForSearch.filter((data) => data.bk_isFree)
            } else if (isFree == 'paid') {
              filterdBooks = booksForSearch.filter((data) => !data.bk_isFree)
            }
          }
        } else {
          if (resource == 0) {
            getAllBooks()
          } else if (resource == 1) {
            filterdBooks = booksForSearch.filter((data) => data.bk_pdf != null)
          } else if (resource == 2) {
            filterdBooks = booksForSearch.filter(
              (data) => data.bk_video != null,
            )
          } else if (resource == 3) {
            filterdBooks = booksForSearch.filter((data) => data.bk_epub != null)
          } else {
          }
        }
      }
    }

    if (!resource && subject.length == 0 && !isFree) {
    } else {
      setBooks(filterdBooks)
    }
    setBooksSearchItem(filterdBooks)
    setCurrentBooks(filterdBooks)

    if (filterdBooks?.length == 0) {
      setNoBooks(true)
    } else {
      setBookCount(filterdBooks?.length)
      setNoBooks(false)
    }
  }, [resource, isFree, subject])

  const getAllBooks = async () => {
    try {
      let response = {}
      setIsLoading(true)
      if (userInfo) {
        if (
          userInfo?.userType == 'Student' ||
          userInfo?.userType == 'Premium'
        ) {
          response = await getBooks('Student', currentPage, limit)
        } else if (
          userInfo?.userType == 'Teacher' ||
          userInfo?.userType == 'Freelance'
        ) {
          response = await getBooks('Teacher', currentPage, limit)
        }
      } else {
        response = await getBooks('Student', currentPage, limit)
      }
      let { books } = response.data
      let { rows, count } = books
      const totalPages = Math.ceil(count / limit)
      const hasNextPage = apiCurrentPage < totalPages
      setFreezedBooks(rows)
      setBookCount(count)
      setBooks(rows)
      // setCurrentPage(1)
      setBooksForSearch(rows)
      setCurrentBooks(rows)
      setIsLoading(false)
    } catch (error) {
      setError(error)
      setIsLoading(false)
    }
  }
  useEffect(() => {
    if (books?.length > 0) {
      setPageNumbers([])
      let pageNumbers = []
      for (let i = 1; i <= Math.ceil(books?.length / perPageLimit); i++) {
        pageNumbers.push(i)
      }
      setPageNumbers(pageNumbers)
    }
  }, [books])
  const getAllMainCategories = async () => {
    try {
      let response = await getMainCategories()
      let { mainCategories } = response.data
      let mainCategoryVals = mainCategories.map((mainCategory) => {
        return {
          id: mainCategory.cc_id,
          value: mainCategory.cc_categoryName,
        }
      })
      setSelectMainCategory(mainCategoryVals)
    } catch (error) {
      setError(error)
    }
  }

  const getAllCategories = async (filterCategory = '') => {
    try {
      let response = await getCategories()
      let { categories } = response.data
      let vals = []
      let mainCategoryArray = []

      if (filterCategory) {
        let filteredData = categories.filter(
          (data) => data.categoryId == filterCategory,
        )
        filteredData[0].category.map((data) => {
          vals.push({ name: data.CategoryName, value: data.CategoryName })
        })
      } else {
        categories.map((mainCategory) => {
          let val = []
          let { category } = mainCategory

          category.map((cat) => {
            if (userInfo && userInfo.userType == 'Student') {
              if (!cat.CategoryName.toLowerCase().includes('teacher')) {
                val.push({ label: cat.CategoryName, value: cat.CategoryName })
              }
            } else {
              val.push({ label: cat.CategoryName, value: cat.CategoryName })
            }
          })
          mainCategoryArray.push({
            label: mainCategory.categoryName,
            options: val,
          })
        })
      }
      setCategoryList(mainCategoryArray)
    } catch (error) {
      setError(error)
    }
  }

  const getAllSubCategories = async (filterCategory = '') => {
    try {
      let response = await getSubCategories()

      let { subCategories } = response.data
      let vals = []
      if (filterCategory) {
        subCategories.map((mainCategory) => {
          let { category } = mainCategory
          category.map((Category) => {
            let { subCategory } = Category
            if (Category.categoryName === filterCategory) {
              subCategory.map((cat) => {
                vals.push({ label: cat.subCateName, value: cat.subCateName })
              })
            }
          })
        })
      } else {
        subCategories.map((mainCategory) => {
          let { category } = mainCategory
          category.map((Category) => {
            let { subCategory } = Category
            subCategory.map((cat) => {
              vals.push({ label: cat.subCateName, value: cat.subCateName })
            })
          })
        })
        let tempVals = []
        vals.length > 0 &&
          vals.map((data, i) => {
            if (i < 4) {
              tempVals.push(data.value)
            }
          })
        setSubCategoryListForSidebar(tempVals)
      }
      setSelectSubCategory(vals)
      setSubCategoryList(vals)
    } catch (error) {
      setError(error)
    }
  }

  const getAllTopics = async (filterCategory = '') => {
    try {
      let response = await getTopics()
      let { topics } = response.data
      let vals = []
      if (filterCategory) {
        topics.map((mainCategory) => {
          let { category } = mainCategory
          category.map((Category) => {
            let { subCategory } = Category
            subCategory.map((subCat) => {
              let { topics } = subCat
              if (subCat.subCateName === filterCategory) {
                topics.map((topic) => {
                  vals.push({ label: topic.topicName, value: topic.topicName })
                })
              }
            })
          })
        })
      } else {
        topics.map((mainCategory) => {
          let { category } = mainCategory
          category.map((Category) => {
            let { subCategory } = Category
            subCategory.map((subCat) => {
              let { topics } = subCat
              topics.map((topic) => {
                vals.push({ label: topic.topicName, value: topic.topicName })
              })
            })
          })
        })
      }
      setTopicList(vals)
    } catch (error) {
      setError(error)
    }
  }

  const getAllGenre = async () => {
    try {
      let response = await getBookGenre()
      let { genres } = response.data
      setGenreData(genres)
    } catch (error) {
      setError(error)
    }
  }

  const handleCategoryChange = (value) => {
    const category = value
    setSelectedCategory(category)
    setSelectedSubCategory('')
    setSelectedTopic('')
    getAllSubCategories(category)
    handleFilterBookCahange(
      category,
      '',
      '',
      subject,
      searchBookName,
      apiCurrentPage,
      limit,
    )
  }

  const handleSubCategoryChange = (value) => {
    const subCategory = value
    setSelectedSubCategory(subCategory)
    setSelectedTopic('')
    getAllTopics(subCategory)
    handleFilterBookCahange(
      selectedCategory,
      subCategory,
      '',
      subject,
      searchBookName,
      apiCurrentPage,
      limit,
    )
  }

  const handleTopicChange = (value) => {
    const topic = value
    setSelectedTopic(topic)
    handleFilterBookCahange(
      selectedCategory,
      selectedSubCategory,
      topic,
      subject,
      searchBookName,
      apiCurrentPage,
      limit,
    )
  }

  const handleBookSearch = (e) => {
    const bookName = e.target.value
    setSearchBookName(bookName)
    handleFilterBookCahange(
      selectedCategory,
      selectedSubCategory,
      selectedTopic,
      subject,
      bookName,
      apiCurrentPage,
      limit,
    )
  }

  const handlePriceChange = (e) => {
    setIsFree(e.target.value)
    const isFreeBooks = e.target.value.toLowerCase() === 'free' ? true : false
    setIsFreeBool(isFreeBooks)
    // handleFilterChange(
    //   selectedCategory,
    //   selectedSubCategory,
    //   subject,
    //   isFreeBooks,
    //   resource,
    // )
  }

  // Here PDF = 1 , Video = 2, ePUB = 3
  const handleResorceTypeChange = (e) => {
    // setIsFree(e.target.value)
    // const isResorceType =
    //   e.target.value.toLowerCase() === '1'
    //     ? true
    //     : e.target.value.toLowerCase() === '2'
    //     ? true
    //     : e.target.value.toLowerCase() === '3'
    //     ? true
    //     : false
    const isResorceType = e.target.value
    setResource(e.target.value)
    // handleFilterChange(
    //   selectedCategory,
    //   selectedSubCategory,
    //   subject,
    //   isFreeBool,
    //   isResorceType,
    // )
  }

  const handleFilterChange = async (
    category = '',
    subCategory = '',
    subject = [],
    isFree = '',
    resource = '',
  ) => {
    try {
      setIsLoading(true)
      setSearchBookName('')
      setSelectedCategory('')
      setSelectedSubCategory('')
      setSelectedTopic('')
      let response = await filterBooks(
        category,
        subCategory,
        subject,
        isFree,
        resource,
        userInfo?.userType,
        isLoggedIn,
        currentPage,
        limit,
      )
      let { books } = response.data || {}
      let { rows, count } = books
      let pageCount = Math.ceil(count / limit)
      setBooks(rows)
      setBookCount(pageCount)
      setFreezedBooks(rows)
      if (rows.length === 0) {
        setNoBooks(true)
      } else {
        setNoBooks(false)
      }
      setIsLoading(false)
    } catch (error) {
      setError(error)
      setIsLoading(false)
    }
  }

  const handleFilterBookCahange = async (
    category,
    subCategory,
    topic,
    subject,
    searchBookName,
    page,
    limit,
  ) => {
    try {
      setIsLoading(true)
      let response = await filterBookByBookTitle(
        category,
        subCategory,
        topic,
        subject,
        searchBookName,
        page,
        limit,
        userInfo?.userType,
      )

      let { books } = response.data || {}
      let { rows, count } = books
      let pageCount = Math.ceil(count / limit)
      setBooks(rows)
      setBookCount(pageCount)
      setFreezedBooks(rows)

      if (rows.length === 0) {
        setNoBooks(true)
        setCurrentBooks([])
      } else {
        setNoBooks(false)
      }
      setIsLoading(false)
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        'There was a problem creating freelance teacher'
      console.log(message)
      setBooks([])
      setError(message)
      setIsLoading(false)
    }
  }

  const handleNextBookChange = async (
    category,
    subCategory,
    topic,
    subject,
    searchBookName,
    page,
    limit,
  ) => {
    try {
      setIsLoading(true)
      let response = await filterBookByBookTitle(
        category,
        subCategory,
        topic,
        subject,
        searchBookName,
        page,
        limit,
      )
      let { books } = response.data || {}
      let { rows, count } = books
      const totalPages = Math.ceil(count / limit)
      const hasNextPage = apiCurrentPage < totalPages
      setBookCount(count)
      setBooks((prevList) => [...prevList, ...rows])
      setBooksForSearch((prevList) => [...prevList, ...rows])
      setFreezedBooks(rows)
      if (rows.length === 0) {
        setNoBooks(true)
      } else {
        setNoBooks(false)
      }
      setIsLoading(false)
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        'There was a problem creating freelance teacher'
      console.log(message)
      setBooks([])
      setError(message)
      setIsLoading(false)
    }
  }

  const handlePrevBookChange = () => {
    const indexOfLastPost = apiCurrentPage * limit
    const indexOfFirstPost = indexOfLastPost - limit
    let oldBooks = books
    oldBooks = books.splice(indexOfFirstPost, limit)
    setBooks(books)
    setBooksForSearch((prevList) => [...prevList, ...books])
    paginate(1)
  }

  const toggleDetails = (id = '') => {
    setBookId(id)
    setShowDetails(!showDetails)
  }

  useEffect(() => {
    setTemp(temp + 1)
  }, [resource])

  const paginate = (pageNumber) => setCurrentPage(pageNumber)

  useEffect(() => {
    if (books && books.length > 0) {
      const indexOfLastPost = currentPage * perPageLimit
      const indexOfFirstPost = indexOfLastPost - perPageLimit
      const currentBooks = books?.slice(indexOfFirstPost, indexOfLastPost)
      setCurrentBooks(currentBooks)
    }
  }, [currentPage, books])

  return (
    <>
      {showDetails && <BooksSingle id={bookId} toggleDetails={toggleDetails} />}
      <section className={`py-5 ${showDetails ? 'd-none' : 'd-block'}`}>
        <div className="container">
          <div className="books-section row g-3">
            <div className="books-sidebar col-lg-3">
              <div className="books-sidebar-inner">
                <div className="books-sidebar-top books-sidebar-widget">
                  {/* <h5 className="m-0">{books.length} Books</h5> */}
                  <button
                    className="cmn--btn"
                    type="button"
                    onClick={handleReset}
                  >
                    Reset
                  </button>
                </div>

                <div className="books-sidebar-widget">
                  <h5 className="subtitle">Resource Type</h5>
                  <label className="form-check form--check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="pdf"
                      checked={resource == '0' ? true : false}
                      value="0"
                      onChange={(e) => handleResorceTypeChange(e)}
                    />
                    <div className="form-check-label">
                      <h5 className="title">All</h5>
                    </div>
                  </label>
                  <label className="form-check form--check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="pdf"
                      checked={resource === '1' ? true : false}
                      value="1"
                      onChange={(e) => handleResorceTypeChange(e)}
                    />
                    <div className="form-check-label">
                      <h5 className="title">PDF</h5>
                    </div>
                  </label>
                  <label className="form-check form--check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="video"
                      checked={resource === '2' ? true : false}
                      value="2"
                      onChange={(e) => handleResorceTypeChange(e)}
                    />
                    <div className="form-check-label">
                      <h5 className="title">Video</h5>
                    </div>
                  </label>
                  <label className="form-check form--check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="epub"
                      checked={resource === '3' ? true : false}
                      value="3"
                      onChange={(e) => handleResorceTypeChange(e)}
                    />
                    <div className="form-check-label">
                      <h5 className="title">ePUB</h5>
                    </div>
                  </label>
                </div>

                <div className="books-sidebar-widget">
                  <h5 className="subtitle">Price</h5>
                  <label className="form-check form--check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="price"
                      checked={isFree === 'free' ? true : false}
                      value="free"
                      onChange={(e) => handlePriceChange(e)}
                    />
                    <div className="form-check-label">
                      <h5 className="title">Free</h5>
                    </div>
                  </label>
                  <label className="form-check form--check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="price"
                      checked={isFree === 'paid' ? true : false}
                      value="paid"
                      onChange={(e) => handlePriceChange(e)}
                    />
                    <div className="form-check-label">
                      <h5 className="title">Premium</h5>
                    </div>
                  </label>
                </div>

                <div className="books-sidebar-widget">
                  <h5 className="subtitle">Subjects</h5>
                  <div className="books-sidebar-widget-tags" ref={ref}>
                    {subCategoryListForSidebar &&
                      subCategoryListForSidebar.map((item, i) => (
                        <label key={i}>
                          <input
                            class="subjects"
                            type="checkbox"
                            name="subject"
                            value={item}
                            onChange={(e) => handleSubjectChange(e)}
                          />
                          <span className="name">{item}</span>
                        </label>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`books-wrapper col-lg-9 ${
                showDetails ? 'd-none' : 'd-block'
              }`}
            >
              <div className="books-wrapper-header sticky-pagination pb-3">
                <Formik
                  initialValues={{
                    bookName: '',
                    category: '',
                    subcategory: '',
                    topic: '',
                  }}
                  validationSchema={SignupSchema}
                  onSubmit={(values, actions) => {
                    // handleSearchSubmit(values)
                    // actions.setSubmitting(false);
                  }}
                >
                  {({
                    errors,
                    touched,
                    isSubmitting,
                    handleSubmit,
                    handleChange,
                    values,
                    setFieldValue,
                    handleBlur,
                  }) => (
                    <Form autoComplete="off" onSubmit={handleSubmit}>
                      <div className="filter-form-wrapper-book">
                        <div className="select--item col-md-3">
                          <div className="inner">
                            <Select
                              name="category"
                              placeholder="Class/Grade"
                              value={
                                selectedCategoryDropdown
                                  ? selectedCategoryDropdown
                                  : ''
                              }
                              onChange={(e) => {
                                handleCategoryChange(e.value)
                                setSelectedCategoryDropdown(e)
                                setFieldValue('category', e ? e.value : '')
                              }}
                              options={categoryList}
                              invalid={touched.st_class && errors.st_class}
                            />
                          </div>
                        </div>

                        <div className="select--item col-md-3">
                          <div className="inner">
                            <Select
                              name="subcategory"
                              placeholder="Subject"
                              value={
                                selectedSubCategoryDropdown
                                  ? selectedSubCategoryDropdown
                                  : ''
                              }
                              onChange={(e) => {
                                handleSubCategoryChange(e.value)
                                setSelectedSubCategoryDropdown(e)
                                setFieldValue('subcategory', e ? e.value : '')
                              }}
                              options={SubCategoryList}
                              invalid={touched.st_class && errors.st_class}
                            />
                          </div>
                        </div>

                        <div className="select--item col-md-3">
                          <div className="inner">
                            <Select
                              name="topic"
                              placeholder="Topic"
                              value={selectedTopic ? selectedTopic : ''}
                              onChange={(e) => {
                                handleTopicChange(e.value)
                                setSelectedTopic(e)
                                setFieldValue('topic', e ? e.value : '')
                              }}
                              onBlur={(evt) => {
                                // setFieldTouched('st_class', true, true)
                              }}
                              options={topicList}
                              invalid={touched.st_class && errors.st_class}
                            />
                          </div>
                        </div>

                        <div className="select--item col-md-3">
                          <div className="inner">
                            <Field
                              className={`form--control ${
                                errors.name && touched.name ? 'form-err' : ''
                              }`}
                              name="bookName"
                              placeholder={`${
                                errors.bookName && touched.bookName
                                  ? 'Search Name Must'
                                  : 'Search here'
                              }`}
                              value={searchBookName ? searchBookName : ''}
                              onChange={(e) => {
                                handleBookSearch(e)
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
                {currentBooks && currentBooks.length > 0 && (
                  <div className="mt-4">
                    <Pagination
                      apiCurrentPage={apiCurrentPage}
                      currentPage={currentPage}
                      totalLimit={limit}
                      totalPosts={books?.length}
                      paginate={paginate}
                      setApiCurrentPage={setApiCurrentPage}
                      handleNextBookChange={handleNextBookChange}
                      pageNumbers={pageNumbers}
                      handlePrevBookChange={handlePrevBookChange}
                      bookCount={bookCount}
                    />
                  </div>
                )}
              </div>
              <div
                className="books-wrapper-cont"
                style={
                  isLoading
                    ? {
                        position: 'relative',
                        opacity: '0.8',
                        minHeight: '600px',
                      }
                    : {}
                }
              >
                {isLoading ? <SubmitLoader /> : <></>}

                <div className="py-2 px-2">
                  {noBooks ? (
                    <div
                      className="mt-2 library__item-img"
                      style={{ textAlign: 'center', backgroundColor: 'white' }}
                    >
                      <img src={bookImage} alt="Book Library" />
                      No system activity found.
                    </div>
                  ) : null}
                  <div className="row g-3 g-md-4 g-lg-3 g-xl-4 mt-1">
                    {currentBooks &&
                      currentBooks.length > 0 &&
                      currentBooks.map((data) => {
                        return (
                          <div className="col-sm-6 col-md-4 col-lg-6 col-xl-3">
                            <div className="bg-white library__item">
                              <div className="library__item-img">
                                <img
                                  className="cursor-pointer"
                                  src={`${IMAGE_URL}/${data?.bk_preview}`}
                                  alt=""
                                  onClick={() => toggleDetails(data?.bk_id)}
                                />
                              </div>
                              <div
                                className="library__item-cont cursor-pointer"
                                onClick={() => toggleDetails(data?.bk_id)}
                              >
                                {data?.bk_title}
                              </div>
                              <div className="d-flex align-items-center justify-content-between mt-2">
                                <div className="text-base">
                                  {data?.bk_isFree ? (
                                    <span className="text-green">Free</span>
                                  ) : (
                                    `$${data?.bk_price}`
                                  )}
                                </div>
                                <Link
                                  to={`#`}
                                  className="details-btn"
                                  onClick={() => toggleDetails(data?.bk_id)}
                                >
                                  Details
                                </Link>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Books

import img1 from "../../images/comments/1.png";
import img2 from "../../images/comments/2.png";

const comments = [
	
	{
		user: img2,
		name: "Elizabeth Sofia",
		date: "jan 17, 2022 at 10:43 am",
		txt: "Ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tem incid idunt ut dolore magna aliqua. Ut enim ad minim ven iam quis nostrud Lorem",
	},
];
export default comments;

import React from 'react'

const LearningCard = ({ index, img, id, title, text }) => {
  const bg_section = index % 2 === 1
  return (
    <div className={`pt-100 pb-100 ${!bg_section && 'bg-section-light'}`}>
      <div className="container">
        <div
          className={`row align-items-center gy-5 ${
            bg_section && 'flex-row-reverse'
          }`}
        >
          <div className="col-lg-6">
            <div className={`learning-content mx-auto`}>
              <h2 className="title">{title}</h2>
              {text && text.map((item, i) => <p key={i}>{item}</p>)}
            </div>
          </div>
          <div className="col-lg-6">
            <img className="mw-100" src={img} alt={title} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default LearningCard

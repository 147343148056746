export const classRoomType = [
  {
    label: 'Kindergarten',
    options: [
      { label: 'KG 1', value: 'KG 1' },
      { label: 'KG 2', value: 'KG 2' },
    ],
  },
  {
    label: 'Primary School',
    options: [
      { label: 'Primary 1', value: 'Primary 1' },
      { label: 'Primary 2', value: 'Primary 2' },
      { label: 'Primary 3', value: 'Primary 3' },
      { label: 'Primary 4', value: 'Primary 4' },
      { label: 'Primary 5', value: 'Primary 5' },
      { label: 'Primary 6', value: 'Primary 6' },
    ],
  },
  {
    label: 'Junior High School',
    options: [
      { label: 'JHS 1', value: 'JHS 1' },
      { label: 'JHS 2', value: 'JHS 2' },
    ],
  },
  {
    label: 'Senior High School ',
    options: [
      { label: 'SHS 1', value: 'SHS 1' },
      { label: 'SHS 2', value: 'SHS 2' },
      { label: 'SHS 3', value: 'SHS 3' },
    ],
  },
  {
    label: 'Vocational and Technical Education',
    options: [
      { label: 'Level 1', value: 'Level 1' },
      { label: 'Level 2', value: 'Level 2' },
      { label: 'Level 3', value: 'Level 3' },
      { label: 'Level 4', value: 'Level 4' },
    ],
  },
]

export const divisions = [
  { label: 'A', value: 'A' },
  { label: 'B', value: 'B' },
  { label: 'C', value: 'C' },
  { label: 'D', value: 'D' },
  { label: 'E', value: 'E' },
]

export const userType = [
  { label: 'Student', value: 'Student' },
  { label: 'fTeacher', value: 'fTeacher' },
  { label: 'parent', value: 'parent' },
  { label: 'publisher', value: 'publisher' },
]

export const bloodGroups = [
  { label: 'A+VE', value: 'A+VE' },
  { label: 'A-VE', value: 'A-VE' },
  { label: 'B+VE', value: 'B+VE' },
  { label: 'B-VE', value: 'B-VE' },
  { label: 'O+VE', value: 'O+VE' },
  { label: 'O-VE', value: 'O-VE' },
  { label: 'AB+VE', value: 'AB+VE' },
  { label: 'AB-VE', value: 'AB-VE' },
]

export const yearsOfExperience = [
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4', value: '4' },
  { label: '5', value: '5' },
  { label: '6', value: '6' },
  { label: '7', value: '7' },
  { label: '8', value: '8' },
  { label: '9', value: '9' },
  { label: '10', value: '10' },
  { label: '11', value: '11' },
  { label: '12', value: '12' },
  { label: '13', value: '13' },
  { label: '14', value: '14' },
  { label: '15', value: '15' },
]

export const certificateList = [
  { label: 'BA', value: 'BA' },
  { label: 'BEd', value: 'BEd' },
  { label: 'BSc', value: 'BSc' },
  { label: 'HND', value: 'HND' },
  { label: 'MA', value: 'MA' },
  { label: 'MEd', value: 'MEd' },
  { label: 'MPhil', value: 'MPhil' },
  { label: 'MSc', value: 'MSc' },
  { label: 'PGDE', value: 'PGDE' },
]

export const levels = [
  {
    label: 'Primary Upper',
    value: 'Primary Upper',
  },
  {
    label: 'Primary Lower',
    value: 'Primary Lower',
  },
  {
    label: 'JHS',
    value: 'JHS',
  },
  {
    label: 'SHS',
    value: 'SHS',
  },
]

export const languages = [
  { label: 'Dagbani', value: 'Dagbani' },
  { label: 'Dangme', value: 'Dangme' },
  { label: 'English', value: 'English' },
  { label: 'Ewe', value: 'Ewe' },
  { label: 'French', value: 'French' },
  { label: 'Ga', value: 'Ga' },
  { label: 'Hausa', value: 'Hausa' },
  { label: 'Twi', value: 'Twi' },
]

export const signatureStyleNames = [
  'Autography',
  'Betterlett',
  'Darlington',
  'Creattion',
  'HealingFairy',
  'KimberlySignature',
  'Pontgraph',
  'Reinatha',
]

export const subjectsMaster = [
  { value: 'English', label: 'English' },
  { value: 'Science', label: 'Science' },
  { value: 'History', label: 'History' },
  { value: 'Drawing', label: 'Drawing' },
  { value: 'Mathematics', label: 'Mathematics' },
  { value: 'Agriculture', label: 'Agriculture' },
  { value: 'Asante Twi', label: 'Asante Twi' },
  { value: 'Creative Arts', label: 'Creative Arts' },
  { value: 'Social Science', label: 'Social Science' },
  { value: 'Robotics Science', label: 'Robotics Science' },
  { value: 'Kids Subject 1', label: 'Kids Subject 1' },
  { value: 'Social Studies', label: 'Social Studies' },
  { value: 'Robotics Science', label: 'Robotics Science' },
  { value: 'History Of Ghana', label: 'History Of Ghana' },
  { value: 'Ghanaian Language', label: 'Ghanaian Language' },
  { value: 'Integrated Science', label: 'Integrated Science' },
  { value: 'Physical Education', label: 'Physical Education' },
  { value: 'Our World Our People', label: 'Our World Our People' },
  { value: 'Computer Programming', label: 'Computer Programming' },
  { value: 'Science And Technology', label: 'Science And Technology' },
  { value: "Basic 1. Teacher's Guide", label: "Basic 1. Teacher's Guide" },
  { value: "Basic 2. Teacher's Guide", label: "Basic 2. Teacher's Guide" },
  { value: "Basic 3. Teacher's Guide", label: "Basic 3. Teacher's Guide" },
  { value: "Basic 4. Teacher's Guide", label: "Basic 4. Teacher's Guide" },
  { value: "Basic 5. Teacher's Guide", label: "Basic 5. Teacher's Guide" },
  { value: "Basic 6. Teacher's Guide", label: "Basic 6. Teacher's Guide" },
  { value: 'Practical English Language', label: 'Practical English Language' },
  {
    value: 'Religious And Moral Education',
    label: 'Religious And Moral Education',
  },
  {
    value: "Asante Twi - Teacher's Guide 1",
    label: "Asante Twi - Teacher's Guide 1",
  },
  {
    value: "Asante Twi - Teacher's Guide 2",
    label: "Asante Twi - Teacher's Guide 2",
  },
  {
    value: "Asante Twi - Teacher's Guide 3",
    label: "Asante Twi - Teacher's Guide 3",
  },
  {
    value: "Asante Twi - Teacher's Guide 4",
    label: "Asante Twi - Teacher's Guide 4",
  },
  {
    value: "Asante Twi - Teacher's Guide 5",
    label: "Asante Twi - Teacher's Guide 5",
  },
  {
    value: "Asante Twi - Teacher's Guide 6",
    label: "Asante Twi - Teacher's Guide 6",
  },
]

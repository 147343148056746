import React, { useContext } from 'react'
import { useState, useEffect } from 'react'
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi'
import { BsStarFill } from 'react-icons/bs'
import { GoPrimitiveDot, GoStar } from 'react-icons/go'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import search__category from '../../assets/images/data/search-category'
import similar_books from '../../assets/images/data/similar_books'
import './Books.scss'
import {
  getBook,
  addToLibrary,
  getBooks,
  getSimilarBooks,
  addToLibraryTeacher,
} from '../../helpers/backendHelpers/book'
import {
  encryptId,
  getAuthToken,
  getUserInfo,
  LoginAlert,
} from '../../helpers/authHelper'
import VideoModal from '../../components/Common/WatchModal'
import SubmitLoader from '../../components/Common/SubmitLoader'
import { IMAGE_URL } from '../../helpers/urlHelper'
import swal from 'sweetalert'
import sampleBookImage from '../../assets/images/book.png'
import { removeObjFromArr } from '../../helpers/common'
import { getBookReviews } from '../../helpers/backendHelpers/bookReview'
import { AuthContext } from '../../contexts/AuthContext'
import default_icon from '../../assets/images/default_avatar.png'
import { Rating } from '@material-ui/lab'
import moment from 'moment'

const BooksSingle = ({ id, toggleDetails }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const pathName = location.pathname
  // let { id } = useParams()
  const { reviews } = search__category[id] ? search__category[id] : ''

  const [book, setBook] = useState({})
  const [books, setBooks] = useState([])
  const [bookId, setBookId] = useState(id)
  const [error, setError] = useState('')
  const [isFavourite, setIsFavourite] = useState(false)
  const [isFavouriteTeacher, setIsFavouriteTeacher] = useState(false)

  //video modal states
  const [videoFile, setVideoFile] = useState('')
  const [videoModal, setVideoModal] = useState(false)
  //for loader
  const [isLoading, setIsLoading] = useState(false)
  const [saved, setSaved] = useState(false)

  // list Book Reviews
  const [bookReviews, setBookReviews] = useState([])
  // const { userInfo } = useContext(AuthContext)
  // const [userInfoJson, setUserInfoJson] = useState(JSON.parse(userInfo))
  const userInfo = getUserInfo()

  useEffect(() => {
    fetchBook(bookId)
  }, [bookId])

  useEffect(() => {
    fetchAllBook()
    // fetchBookReviews()
  }, [saved])

  const fetchBook = async (id) => {
    try {
      let userId = userInfo?.id
      setIsLoading(true)
      const response = await getBook(id, userId)
      const { book } = response.data
      setBookReviews(book?.bk_reviews)
      book.bk_previewVideo
        ? setVideoFile(book.bk_previewVideo)
        : setVideoFile('')
      setBook(book)
      if (userInfo?.userType == 'Student' || userInfo?.userType == 'Premium') {
        setIsFavourite(book.isInStudentLibrary)
      } else if (
        userInfo?.userType == 'Teacher' ||
        userInfo?.userType == 'Freelance'
      ) {
        setIsFavourite(book.isInTeacherLibrary)
      }
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      setError(error)
    }
  }
  const fetchAllBook = async () => {
    try {
      setIsLoading(true)
      const response = await getSimilarBooks(id)
      const { books } = response.data
      removeObjFromArr(books, 'bk_id', parseInt(id))
      setBooks(books)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      setError(error)
    }
  }

  // const fetchBookReviews = async () => {
  //   try {
  //     setIsLoading(true)
  //     const response = await getBookReviews(id)
  //     const { bookReviews } = response.data
  //     setBookReviews(bookReviews)
  //     setIsLoading(false)
  //   } catch (error) {
  //     setIsLoading(false)
  //     let message =
  //       error?.response?.data?.message ||
  //       error?.message ||
  //       'There Was A Problem fetch BookReview'
  //     setError(message)
  //   }
  // }

  // const LoginAlert = async () => {
  //   const value = await swal('Please do login into your account', {
  //     buttons: {
  //       defeat: 'Log in',
  //       cancel: 'Cancel',
  //     },
  //   })
  //   switch (value) {
  //     case 'defeat':
  //       navigate('/auth/signin')
  //       break
  //     default:
  //   }
  // }

  const AlertWithMessage = async (message, redirect = false) => {
    await swal(message)
    if (redirect) {
      return navigate('/my-library')
    }
  }
  const handleAddToLibraryClick = async (id) => {
    const isLoggedIn = getAuthToken() ? true : false
    const encryptedId = await encryptId(id)
    if (!isLoggedIn) {
      LoginAlert(navigate)
    } else {
      if (isFavourite) {
        return navigate(`/my-library/${encryptedId}`)
      } else {
        let body = {
          bookId: bookId,
        }
        try {
          if (
            userInfo.userType === 'Student' ||
            userInfo.userType === 'Premium'
          ) {
            await addToLibrary(bookId)
            setIsFavourite(true)
            navigate('/my-library')
          } else if (
            userInfo.userType === 'Teacher' ||
            userInfo.userType === 'Freelance'
          ) {
            await addToLibraryTeacher(body)
            setIsFavourite(true)
            navigate('/my-library')
          }
        } catch (error) {
          AlertWithMessage(error)
        }
      }
    }
  }

  const handleBuyBook = () => {
    //Buy book logic here
  }
  const toggleWatchModal = () => {
    setVideoModal(!videoModal)
  }

  return (
    // <h1>Hello</h1>
    <section className="books-single py-5">
      <VideoModal
        isOpen={videoModal}
        toggle={toggleWatchModal}
        video={videoFile}
      />
      <div className="container">
        <div className="page-header-2">
          <ul className="breadcrumb-2">
            <li>
              <Link to="/library">
                Library
                <BiChevronRight />
              </Link>
            </li>
            <li>{book.bk_title}</li>
          </ul>
          <div
            onClick={() =>
              // navigate('/library')
              toggleDetails()
            }
            className="back-btn text-base"
          >
            <BiChevronLeft /> Back to Listing
          </div>
        </div>
        <div className="books-single row">
          <div className="books-single-sidebar col-lg-4">
            <div className="card cmn--card">
              <div className="card-header py-3">
                <h5 className="card-title px-2">Similar Books</h5>
              </div>
              <div className="card-body">
                <ul className="similar-products p-sm-2">
                  {books &&
                    books.map((book, i) => (
                      <li key={i}>
                        <Link
                          to={`#`}
                          onClick={() => {
                            setBookId(book.bk_id)
                          }}
                          // onClick={() => {
                          //   book.bk_previewVideo
                          //     ? setVideoFile(book.bk_previewVideo)
                          //     : setVideoFile('')
                          //   if (userInfoJson.userType == 'Student') {
                          //     setIsFavourite(book.isInStudentLibrary)
                          //   } else if (userInfoJson.userType == 'Teacher') {
                          //     setIsFavourite(book.isInTeacherLibrary)
                          //   }
                          //   setSaved((preSaved) => !preSaved)
                          //   setBookId(book.bk_id)
                          //   setBook(book)
                          // }}
                        >
                          <div className="img">
                            <img
                              src={`${IMAGE_URL}/${book.bk_preview}`}
                              alt=""
                            />
                          </div>
                          <div className="cont">
                            <h6 className="title">{book.bk_title}</h6>
                            {book.bk_isFree ? (
                              <span className="text-green">Free</span>
                            ) : (
                              <strong>${book.bk_price} GHS</strong>
                            )}
                          </div>
                        </Link>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
          <div
            className="books-single-wrapper col-lg-8"
            style={isLoading ? { position: 'relative', opacity: '0.9' } : {}}
          >
            {isLoading ? <SubmitLoader /> : ''}
            <div
              className="books-single-inner"
              style={isLoading ? { display: 'none' } : {}}
            >
              <div className="books-single-top">
                <div className="books-single-top-img">
                  <img
                    src={
                      book.bk_preview
                        ? `${IMAGE_URL}/${book.bk_preview}`
                        : sampleBookImage
                    }
                    alt={book.bk_name}
                  />
                </div>
                <div className="books-single-top-cont">
                  <h4 className="title">{book.bk_title}</h4>
                  <div className="rating-area">
                    <div className="rating">
                      <span className="text-rating">
                        <GoStar />
                      </span>
                      <span className="text-rating">
                        <GoStar />
                      </span>
                      <span className="text-rating">
                        <GoStar />
                      </span>
                      <span className="text-clr">
                        <GoStar />
                      </span>
                      <span className="text-clr">
                        <GoStar />
                      </span>
                      <small className="ms-2 text-rating">9.3</small>
                    </div>
                    <div className="text-green">
                      <GoPrimitiveDot /> In stock
                    </div>
                  </div>
                  {/* {book.bk_isFree ? (
                    <h4 className="mt-2 mb-2 text-green">Free</h4>
                  ) : (
                    <h4 className="price">
                      ${book.bk_price} GHS <sub>/ per book</sub>
                    </h4>
                  )} */}

                  <h4 className="mt-2 mb-2 text-green">
                    {book.bk_isFree ? 'Free' : `${book.bk_isFree} GHS`}
                  </h4>

                  <ul className="genre-info">
                    <li>
                      <span>Genre</span>
                      <strong>{book.bk_genre}</strong>
                    </li>
                    <li>
                      <span>Publication</span>
                      <strong>{book.bk_publisher}</strong>
                    </li>
                    <li>
                      <span>Author</span>
                      <strong>{book.bk_author}</strong>
                    </li>
                  </ul>
                  <div className="details__btn-grp">
                    {book.bk_previewVideo ? (
                      <Link
                        to="#"
                        className="cmn--btn"
                        onClick={toggleWatchModal}
                      >
                        Watch Preview
                      </Link>
                    ) : (
                      ''
                    )}
                    {book.bk_isFree ? (
                      isFavourite ? (
                        <button
                          className="cmn--btn add-btn-col"
                          onClick={() => handleAddToLibraryClick(book?.bk_id)}
                        >
                          {book?.bk_video
                            ? 'Watch From Library'
                            : 'Read From Library'}
                        </button>
                      ) : (
                        <button
                          className="cmn--btn add-btn-col"
                          onClick={() => handleAddToLibraryClick(book?.bk_id)}
                        >
                          Add To Library
                        </button>
                      )
                    ) : (
                      <Link
                        to="#"
                        className="cmn--btn add-btn-col"
                        onClick={handleBuyBook}
                      >
                        Buy Book
                      </Link>
                    )}
                  </div>
                </div>
                <p className="txt mt-3">{book.bk_description}</p>
              </div>
              <div className="books-single-bottom">
                <h4 className="title border-bottom">Reviews</h4>
                <ul className="all-reviews px-0">
                  <div className="review">
                    {bookReviews &&
                      bookReviews.map((bookReview, i) => (
                        <li key={i}>
                          <div className="img">
                            <img
                              src={`${IMAGE_URL}/${bookReview?.profilePic}`}
                              alt={bookReview?.br_student?.st_fullName}
                            />
                          </div>
                          <div className="cont">
                            <div className="top row">
                              <h5 className="name col mb-0">
                                <p className="fullName">
                                  <b>{bookReview?.fullName}</b>
                                </p>
                              </h5>

                              <div className="rating col-lg-2 mb-0 px-0 justify-content-end pe-3">
                                <Rating
                                  max={bookReview?.br_rate}
                                  value={bookReview?.br_rate}
                                  readOnly
                                />
                              </div>
                              <div className="row">
                                <p className="col comment">
                                  {moment(bookReview?.br_createdAt).format(
                                    'DD MMM YYYY',
                                  )}
                                </p>
                              </div>
                              <div className="row mt-4">
                                <p className="col comment">
                                  {bookReview.br_comment}
                                </p>
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BooksSingle

import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import DeleteModal from '../../components/Common/DeleteModal'
import { removeFromLibrary } from '../../helpers/backendHelpers/book'
import { IMAGE_URL } from '../../helpers/urlHelper'
import { AuthContext } from '../../contexts/AuthContext'
import { useNavigate } from 'react-router-dom'
import {
  getLibraryBooksStudent,
  getLibraryBooksTeacher,
} from '../../helpers/backendHelpers/book'
import { toast, ToastContainer } from 'react-toastify'

function LibraryItems({
  bk_preview,
  bk_title,
  bk_id,
  getMyLibraryBooks,
  toggleDetails,
}) {
  const [bookId, setBookId] = useState()
  const [deleteModal, setDeleteModal] = useState(false)
  const [books, setBooks] = useState([])
  const [error, setError] = useState('')
  const [userInfoJson, setUserInfoJson] = useState(
    JSON.parse(localStorage.getItem('userInfo')),
  )

  const handleDeleteBook = async () => {
    setDeleteModal(false)
    const userType = userInfoJson.userType

    try {
      const response = await removeFromLibrary(bookId, userType)
      if (response.status) {
        toast.success('Book removed from library', {
          autoClose: 5000,
        })
        getMyLibraryBooks()
      } else {
        toast.error('There was problem while removing book from library', {
          autoClose: 5000,
        })
      }
    } catch (error) {
      alert(error)
    }
  }

  const onClickDelete = (bk_id) => {
    setDeleteModal(true)
    setBookId(bk_id)
  }
  return (
    <>
      <div className="col-sm-6 col-md-4 col-lg-6 col-xl-3">
        <ToastContainer position="top-right" />
        <div className="text-end">
          <span
            onClick={() => {
              onClickDelete(bk_id)
            }}
            className="cancel_icon"
          >
            X
          </span>
        </div>

        <Link
          to={`#`}
          className="library__item"
          onClick={() => toggleDetails(bk_id)}
        >
          <div className="library__item-img">
            <img src={`${IMAGE_URL}/${bk_preview}`} alt="" />
          </div>
          <div className="library__item-cont">{bk_title}</div>
        </Link>
      </div>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteBook}
        onCloseClick={() => setDeleteModal(false)}
      />
    </>
  )
}

export default LibraryItems

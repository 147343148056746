import React, { useState } from 'react'
import Slider from 'react-slick'
import banner_bg from '../../../assets/images/banner-bg.jpg'
import banner_bg_thumb from '../../../assets/images/banner-bg-thumb.jpg'
import banner_bg1 from '../../../assets/images/banner-bg1.jpg'
import banner_bg1_thumb from '../../../assets/images/banner-bg1-thumb.jpg'
import banner_bg2 from '../../../assets/images/banner-bg2.jpg'
import banner_bg2_thumb from '../../../assets/images/banner-bg2-thumb.jpg'
import banner_bg3 from '../../../assets/images/banner-bg3.jpg'
import banner_bg3_thumb from '../../../assets/images/banner-bg3-thumb.png'
import img1 from '../../../assets/images/banner1.png'
import img2 from '../../../assets/images/banner2.png'
import img3 from '../../../assets/images/banner3.png'
import BannerContent from './BannerContent'
const Banner = () => {
  const [nav1, setNav1] = useState()
  const [nav2, setNav2] = useState()
  const gallerySlid = {
    responsive: [
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  }
  return (
    <>
      <section className="banner-section overflow-hidden">
        <Slider
          asNavFor={nav2}
          ref={(slider1) => setNav1(slider1)}
          // autoplay={true}
          fade={true}
          className="gallery-content"
        >
          <div>
            <div
              className="banner-item section-overlay"
              style={{
                background: `url(${banner_bg}) no-repeat center center / cover`,
              }}
            >
              <BannerContent
                title="Reach Out To Your Learners Faster, Easier And simpler"
                subTitle="Use Katon To Provide Your Learners With A One-on-One Knowledge Experience. Extra Classes Made Easier And Virtual."
              />
            </div>
          </div>
          <div>
            <div
              className="banner-item section-overlay"
              style={{
                background: `url(${banner_bg1}) no-repeat center center / cover`,
              }}
            >
              <BannerContent
                title="Reach Out To Your Learners Faster, Easier And simpler"
                subTitle="Use Katon To Provide Your Learners With A One-on-One Learning Experience. Extra Classes Made Easier And Virtual."
              />
            </div>
          </div>
          <div>
            <div
              className="banner-item section-overlay"
              style={{
                background: `url(${banner_bg2}) no-repeat center center / cover`,
              }}
            >
              <BannerContent
                title="Access Your Child’s Progress At A Click Of A Button."
                subTitle="Keep Updated With Your Child’s Performance. Have An Eagle Eye On Their Progress."
              />
            </div>
          </div>
        </Slider>
      </section>
      <div className="right--image">
        <div className="container">
          <Slider
            {...gallerySlid}
            asNavFor={nav1}
            ref={(slider2) => setNav2(slider2)}
            slidesToShow={3}
            fade={false}
            swipeToSlide={true}
            focusOnSelect={true}
            className="gallery-nav d-flex flex-wrap justify-content-center justify-content-md-end"
          >
            <div className="bottom-img">
              <img src={banner_bg_thumb} alt="" />
            </div>
            <div className="bottom-img">
              <img src={banner_bg1_thumb} alt="" />
            </div>
            <div className="bottom-img">
              <img src={banner_bg2_thumb} alt="" />
            </div>
          </Slider>
        </div>
      </div>
    </>
  )
}

export default Banner

import React from "react";

const ContactInput = ({ id, label, textarea, ...rest }) => {
	return (
		<>
			{!textarea ? (
				<>
					<label className="form-label" htmlFor={id}>
						{label}
					</label>
					<input {...rest} className="form-control" id={id} />
				</>
			) : (
				<>
					<label className="form-label" htmlFor={id}>
						{label}
					</label>
					<textarea {...rest} className="form-control" id={id}></textarea>
				</>
			)}
		</>
	);
};

export default ContactInput;

import React from 'react'
import { IoMdCheckmarkCircleOutline } from 'react-icons/io'
import about__image from '../../../assets/images/about/about.png'
import image__2 from '../../../assets/images/about/border-img.png'
import type__text from '../../../assets/images/about/type-txt.png'
import { aboutFeature } from '../../../assets/images/data/about'
import SectionHeader from '../../../components/SectionHeader'
const AboutSection = () => {
  return (
    <section className={`about-section py-5`}>
      <div className="container">
        <div className="row gy-5 align-items-center">
          <div className="col-lg-6 col-xxl-7">
            <div className="about-image">
              <div className="about-img">
                <img src={about__image} alt="about" />
              </div>
              {/* <div className="type-txt">
                <img src={type__text} alt="" className="type-txt-img" />
                <h4>Type Some Text</h4>
              </div> */}
              <img src={image__2} className="border-img" alt="" />
            </div>
          </div>
          <div className="col-lg-6 col-xxl-5">
            <SectionHeader
              leftalign
              subtitle=""
              title="KATON is the go-to spot"
            />
            <p className="mb-4">
              KATON is a mobile and web-based educational tool. The best
              solutions for student preparation for WASSCE, JAMB, NECO, and GCE
              exams are accessible here, with over 15,000 interactive quizzes
              and thorough solutions. Through a combination of the greatest
              online instructors, books, journals, media, and technology, we
              provide students with a high-quality education that is both
              accessible and inexpensive, allowing them to achieve their
              academic goals and succeed on tests. Through our efforts, we hope
              to create a society where no child is held back from realizing his
              or her full potential because of financial or geographical
              constraints.
            </p>
            <ul className="checked--list">
              {aboutFeature &&
                aboutFeature.map(({ title, id }, i) => (
                  <li key={i}>
                    <IoMdCheckmarkCircleOutline />
                    <span>{title}</span>
                  </li>
                ))}
            </ul>
            <h5 className="mt-4">Trusted By 10,000 + Organization</h5>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AboutSection

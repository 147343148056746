import React, { useEffect, useRef, useState } from 'react'
import bookImage from '../../assets/images/team/teacher.png'
import blogImg from '../../assets/images/event/event.png'
import { BsCalendarWeek, BsPeople } from 'react-icons/bs'
import { BiCommentDetail } from 'react-icons/bi'
import { SlLike } from 'react-icons/sl'
import { getSubCategories } from 'helpers/backendHelpers/book'
import {
  createBlogAPI,
  createComment,
  getAllBlog,
  getAllBlogWithPagination,
  getCommentsByBlogId,
  likeBlogAPI,
} from 'helpers/backendHelpers/blog'
import default_card_img from '../../assets/images/default_card_img.png'
import { Input, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import moment from 'moment'
import { getAuthToken, getFullUserInfo, getUserInfo } from 'helpers/authHelper'
import swal from 'sweetalert'
import { useLocation, useNavigate } from 'react-router-dom'
import { IMAGE_URL } from 'helpers/urlHelper'
import default_icon from '../../assets/images/default_avatar.png'
import { toast, ToastContainer } from 'react-toastify'
import SubmitLoader from 'components/Common/SubmitLoader'
import ButtonLoader from 'components/Common/ButtonLoader'

const Blog = () => {
  const [creatorType, setCreatorType] = useState('')
  const [subCategoryListForSidebar, setSubCategoryListForSidebar] = useState([])
  const ref = useRef()
  const [error, setError] = useState('')
  const [subject, setSubject] = useState([])
  const [blogList, setBlogList] = useState([])
  const [blogModal, setBlogModal] = useState(false)
  const [showComments, setShowComments] = useState(false)
  const [pageNumber, setPageNumber] = useState(1)
  const [blogForm, setBlogForm] = useState({
    bl_title: '',
    bl_desc: '',
    bl_image: { fileName: '', file: {} },
  })
  const [isLoading, setIsLoading] = useState(false)
  const [isCommentLoading, setIsCommentLoading] = useState(false)
  const [temp, setTemp] = useState(false)
  const [noRecords, setNoRecords] = useState(false)
  const [noRecordsForComment, setNoRecordsForComment] = useState(false)
  const [isSubmitLoading, setIsSubmitLoading] = useState(false)
  const [comment, setComment] = useState('')
  const [currentBlogIndex, setCurrentBlogIndex] = useState('')

  const [showError, setShowError] = useState(false)

  const userInfo = getUserInfo()
  const fullUserInfo = getFullUserInfo()
  const navigate = useNavigate()
  const location = useLocation()
  const pathName = location.pathname
  const lastItemRef = useRef()

  useEffect(() => {
    getAllSubCategories()
    getAllBlogs(creatorType, 1)
  }, [])

  const getAllSubCategories = async (filterCategory = '') => {
    try {
      let response = await getSubCategories()

      let { subCategories } = response.data
      let vals = []
      if (filterCategory) {
        subCategories.map((mainCategory) => {
          let { category } = mainCategory
          category.map((Category) => {
            let { subCategory } = Category
            if (Category.categoryName === filterCategory) {
              subCategory.map((cat) => {
                vals.push({ label: cat.subCateName, value: cat.subCateName })
              })
            }
          })
        })
      } else {
        subCategories.map((mainCategory) => {
          let { category } = mainCategory
          category.map((Category) => {
            let { subCategory } = Category
            subCategory.map((cat) => {
              vals.push({ label: cat.subCateName, value: cat.subCateName })
            })
          })
        })
        let tempVals = []
        vals.length > 0 &&
          vals.map((data, i) => {
            if (i < 4) {
              tempVals.push(data.value)
            }
          })
        setSubCategoryListForSidebar(tempVals)
      }
    } catch (error) {
      setError(error)
    }
  }

  const getAllBlogs = async (creatorType, pageNumber) => {
    try {
      setIsLoading(true)
      let response = await getAllBlogWithPagination(creatorType, pageNumber)
      let { blog } = response.data
      setBlogList(blog)
      setIsLoading(false)
      if (blog.length == 0) {
        setNoRecords(true)
      } else {
        setNoRecords(false)
      }
    } catch (error) {
      setIsLoading(false)
      setError(error)
    }
  }

  const getAllBlogsWithPagination = async (creatorType) => {
    try {
      let response = await getAllBlogWithPagination(creatorType, pageNumber + 1)
      let { blog } = response.data
      setPageNumber(pageNumber + 1)
      setBlogList((prevList) => [...prevList, ...blog])
    } catch (error) {
      setError(error)
    }
  }

  const handleCreatorTypeChange = (e) => {
    setCreatorType(e.target.value)
    setPageNumber(1)
    getAllBlogs(e.target.value, 1)
  }

  const loginAlert = async () => {
    const value = await swal('Please do login into your account', {
      buttons: {
        defeat: 'Log in',
        cancel: 'Cancel',
      },
    })
    switch (value) {
      case 'defeat':
        navigate('/auth/signin', {
          state: {
            url: pathName,
          },
        })
        break
      default:
    }
  }

  const toggleBlogModal = () => {
    const isLoggedIn = getAuthToken() ? true : false
    if (!isLoggedIn) {
      loginAlert()
    } else {
      setBlogModal(!blogModal)
    }
  }

  const handleReset = () => {
    setCreatorType('')
    setPageNumber(1)
    getAllBlogs('', 1)
  }

  const createBlog = async (values) => {
    try {
      // setIsLoading(true)
      let userType = ''
      if (userInfo?.userType == 'Student' || userInfo?.userType == 'Premium') {
        userType = 'Student'
      } else if (
        userInfo?.userType == 'Teacher' ||
        userInfo?.userType == 'Freelance'
      ) {
        userType = 'Teacher'
      }
      setIsSubmitLoading(true)
      let body = {
        bl_title: values?.bl_title,
        bl_desc: values?.bl_desc,
        bl_image: blogForm.bl_image.file,
        bl_creation_type: userType,
        bl_creatorId: userInfo?.id,
      }
      let response = await createBlogAPI(body)
      if (response?.status) {
        toggleBlogModal()
        toast.success('Blog added sucessfully.', { autoClose: 5000 })
        getAllBlogs(creatorType)
        setBlogForm({
          bl_title: '',
          bl_desc: '',
          bl_image: { fileName: '', file: {} },
        })
      } else {
        alert('Something Went Wrong.')
      }
      setIsSubmitLoading(false)
    } catch (error) {
      setError(error)
      alert(error)
      setIsLoading(false)
      setIsSubmitLoading(false)
    }
  }

  const likeBlog = async (id, index, likeCount) => {
    setTemp(!temp)
    const isLoggedIn = getAuthToken() ? true : false
    if (!isLoggedIn) {
      loginAlert()
    } else {
      try {
        blogList[index].isLiked = true
        blogList[index].bl_likeCount = likeCount + 1
        let response = await likeBlogAPI(id)
        let { blog } = response.data
        if (response?.status) {
          // getAllBlogs(creatorType)
        }
      } catch (error) {
        setError(error)
      }
    }
  }
  const hideShowComments = async (index, bl_id) => {
    const isLoggedIn = getAuthToken() ? true : false
    if (!isLoggedIn) {
      loginAlert()
    } else {
      let tempBlog = blogList
      tempBlog = tempBlog.map((data, i) => {
        data.showComments = false
        return data
      })
      tempBlog[index].showComments = !tempBlog[index].showComments
      if (tempBlog[index].isFetchComments) {
      } else {
        setIsCommentLoading(true)
        let response = await getCommentsByBlogId(bl_id)
        if (response?.status) {
          let { comment } = response.data
          tempBlog[index].bl_comment = comment
          tempBlog[index].isFetchComments = true

          if (comment.length == 0) {
            tempBlog[index].showNoRecordsMsg = true
            setNoRecordsForComment(true)
          } else {
            tempBlog[index].showNoRecordsMsg = false
            setNoRecordsForComment(false)
          }
        } else {
          console.log('Error while fetching comments')
        }
        setIsCommentLoading(false)
      }
      setBlogList(tempBlog)
      setTemp(!temp)
    }
  }

  const addComment = async (bl_id, index) => {
    try {
      let userType = ''
      if (userInfo?.userType == 'Student' || userInfo?.userType == 'Premium') {
        userType = 'Student'
      } else if (
        userInfo?.userType == 'Teacher' ||
        userInfo?.userType == 'Freelance'
      ) {
        userType = 'Teacher'
      }
      if (comment.length == 0) {
        return setShowError(true)
      } else {
        setShowError(false)
        setIsSubmitLoading(true)
        let body = {
          cmn_userId: userInfo?.id,
          cmn_userType: userType,
          bl_id,
          cmn_comment: comment,
          cmn_date: new Date(),
        }
        let response = await createComment(body)
        if (response.status) {
          toast.success('Thank you for your comment', { autoClose: 5000 })
          let tempBlog = blogList
          tempBlog[index].bl_comment = [
            ...tempBlog[index].bl_comment,
            {
              cmn_comment: comment,
              cmn_userId: userInfo?.id,
              cmn_userType: userType,
              cmn_fullName: userInfo?.fullName,
              cmn_profilePic: userInfo?.profilePic,
              bl_id: bl_id,
              cmn_date: new Date(),
            },
          ]
          setBlogList(tempBlog)
          setComment('')
        } else {
          console.log('Something went wrong')
        }

        setIsSubmitLoading(false)
      }
    } catch (error) {
      console.log('error', error)
      setIsSubmitLoading(false)
      setError(error)
    }
  }

  return (
    <>
      <ToastContainer position="top-right" />
      <section className="py-5">
        <Modal
          isOpen={blogModal}
          size="md"
          role="dialog"
          autoFocus={true}
          centered={true}
          className="tutorModal"
          tabIndex="-1"
          toggle={toggleBlogModal}
        >
          <ModalHeader toggle={toggleBlogModal}>Create Blog</ModalHeader>
          <ModalBody>
            <Formik
              initialValues={{
                bl_title: '',
                bl_desc: '',
                bl_image: '',
              }}
              validationSchema={Yup.object({
                bl_title: Yup.string()
                  .required('Please Enter Blog Title')
                  .nullable(),
                bl_desc: Yup.string()
                  .required('Please Enter Description')
                  .nullable(),
                bl_image: Yup.mixed()
                  .optional()
                  .test('fileFormat', 'Unsupported Format', (value) => {
                    if (!blogForm?.['bl_image']?.file?.type)
                      return blogForm['bl_image'].fileName === '' || false
                    return ['image/jpg', 'image/png', 'image/jpeg'].includes(
                      blogForm?.['bl_image'].file.type,
                    )
                  })
                  .nullable(),
              })}
              onSubmit={async (values) => {
                createBlog(values)
              }}
            >
              {({
                errors,
                touched,
                isSubmitting,
                handleSubmit,
                handleChange,
                setFieldValue,
                setFieldTouched,
                values,
              }) => (
                <Form
                  className="mt-4 pt-2"
                  autoComplete="off"
                  onSubmit={(e) => {
                    e.preventDefault()
                    handleSubmit(e)
                    return false
                  }}
                >
                  <div className="input--group">
                    <Field
                      className={`form-control ${
                        errors.bl_title && touched.bl_title ? 'form-err' : ''
                      }`}
                      name="bl_title"
                      placeholder="Enter Blog Title"
                    />
                    {errors.bl_title && touched.bl_title ? (
                      <div className="form-err-msg">{errors.bl_title}</div>
                    ) : null}
                  </div>
                  <div className="input--group">
                    <Field
                      className={`form-control ${
                        errors.bl_desc && touched.bl_desc ? 'form-err' : ''
                      }`}
                      component="textarea"
                      rows="4"
                      name="bl_desc"
                      type="text"
                      placeholder="Enter Description"
                    />
                    {errors.bl_desc && touched.bl_desc ? (
                      <div className="form-err-msg">{errors.bl_desc}</div>
                    ) : null}
                  </div>
                  <div className="input--group">
                    <Input
                      name="bl_image"
                      type="file"
                      accept=".png, .jpg"
                      placeholder="Select Book Pdf"
                      onChange={(e) => {
                        let tempForm = blogForm
                        tempForm['bl_image']['fileName'] = e.target.value
                        tempForm['bl_image']['file'] = e.target.files[0]
                        setBlogForm(tempForm)
                      }}
                      invalid={!!touched.bl_image && !!errors.bl_image}
                      defaultValue={blogForm.bl_image?.fileName}
                    />
                    {errors.bl_image && touched.bl_image ? (
                      <div className="form-err-msg">{errors.bl_image}</div>
                    ) : null}
                  </div>
                  <button
                    disabled={isSubmitLoading}
                    className="cmn--btn  w-100 form-control"
                    type="submit"
                  >
                    <div className="d-flex align-items-center justify-content-center">
                      {isSubmitLoading && <ButtonLoader></ButtonLoader>}

                      <span>Submit</span>
                    </div>
                  </button>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </Modal>
        <div className="container">
          <div className="books-section row g-3">
            <div className="books-sidebar col-lg-3">
              <div className="books-sidebar-inner">
                <div className="books-sidebar-top books-sidebar-widget">
                  <h5 className="m-0 fw-bold">Sort by</h5>
                  <button
                    className="cmn--btn"
                    type="button"
                    onClick={() => handleReset()}
                  >
                    Reset
                  </button>
                </div>
                <div className="books-sidebar-widget">
                  <h5 className="subtitle">Creator</h5>
                  <label className="form-check form--check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="all"
                      checked={creatorType == '' ? true : false}
                      value=""
                      onChange={(e) => handleCreatorTypeChange(e)}
                    />
                    <div className="form-check-label">
                      <h5 className="title">All</h5>
                    </div>
                  </label>
                  <label className="form-check form--check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="teacher"
                      checked={creatorType === 'Teacher' ? true : false}
                      value="Teacher"
                      onChange={(e) => handleCreatorTypeChange(e)}
                    />
                    <div className="form-check-label">
                      <h5 className="title">Teacher</h5>
                    </div>
                  </label>
                  <label className="form-check form--check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="student"
                      checked={creatorType === 'Student' ? true : false}
                      value="Student"
                      onChange={(e) => handleCreatorTypeChange(e)}
                    />
                    <div className="form-check-label">
                      <h5 className="title">Student</h5>
                    </div>
                  </label>
                  <label className="form-check form--check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="experts"
                      checked={creatorType === 'Experts' ? true : false}
                      value="Experts"
                      onChange={(e) => handleCreatorTypeChange(e)}
                    />
                    <div className="form-check-label">
                      <h5 className="title">Experts</h5>
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <div className="col-lg-9 blog-main-section">
              <div className="blog-box d-flex justify-content-between mb-4">
                <div className="d-flex flex-column">
                  <h4 className="title mb-3">Blogs</h4>
                  <p>Created by teacher & content expert</p>
                </div>
                <button
                  className="checkResultBtn"
                  onClick={() => {
                    toggleBlogModal()
                  }}
                >
                  Create Blog
                </button>
              </div>
              <div style={{ minHeight: '300px' }}>
                {isLoading ? (
                  <div
                    style={
                      isLoading
                        ? {
                            position: 'relative',
                            opacity: '0.8',
                            minHeight: '300px',
                          }
                        : {}
                    }
                  >
                    <SubmitLoader />
                  </div>
                ) : (
                  <>
                    {noRecords && (
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ minHeight: '300px' }}
                      >
                        <h1>No system activity found.</h1>
                      </div>
                    )}
                    {blogList &&
                      blogList.length > 0 &&
                      blogList.map((data, i) => {
                        return (
                          <>
                            <div
                              ref={
                                i == blogList.length - 1 ? lastItemRef : null
                              }
                              className="blog-box single-blog mt-3"
                            >
                              <div className="row g-2 g-sm-4 mb-4">
                                <div className="col-12">
                                  <div className="img-container">
                                    {data?.bl_image &&
                                      data?.bl_image != null && (
                                        <img
                                          className="w-100 blog-img"
                                          src={`${IMAGE_URL}/${data?.bl_image}`}
                                          alt=""
                                        />
                                      )}
                                  </div>
                                </div>
                              </div>
                              <h6 className="mb-3 blog-title">
                                {data?.bl_title}
                              </h6>
                              <p className="mt-0 blog-desc">{data?.bl_desc}</p>
                              <div className="row g-2 g-sm-4 mb-4">
                                <div className="col">
                                  <div className="d-flex teacher-list align-items-center mb-3 justify-content-center">
                                    <img
                                      className="me-3"
                                      src={
                                        data?.bl_creatorPic
                                          ? `${IMAGE_URL}/${data?.bl_creatorPic}`
                                          : default_icon
                                      }
                                      alt=""
                                      srcset=""
                                    />
                                    <p className="subtitle mb-3">
                                      {data?.bl_creatorName}
                                    </p>
                                  </div>
                                </div>
                                <div className="col">
                                  <span className="d-flex align-items-center icon icon-color justify-content-center">
                                    <BsCalendarWeek />{' '}
                                    <p className="m-0 ms-2">
                                      {moment(data?.bl_createdAt).format(
                                        'DD MMM YYYY',
                                      )}
                                    </p>
                                  </span>
                                </div>
                                <div className="col">
                                  <span className="d-flex align-items-center icon justify-content-center">
                                    <SlLike
                                      // fill="red"
                                      style={{
                                        color: data?.isLiked ? '#00bee3' : '',
                                      }}
                                      className="cursor-pointer"
                                      onClick={() => {
                                        if (!data?.isLiked) {
                                          likeBlog(
                                            data?.bl_id,
                                            i,
                                            data?.bl_likeCount,
                                          )
                                        }
                                      }}
                                    />{' '}
                                    <p className="m-0 ms-2">
                                      {data?.bl_likeCount > 0
                                        ? data?.bl_likeCount + ' '
                                        : ' '}
                                      Like
                                    </p>
                                  </span>
                                </div>
                                <div className="col">
                                  <span
                                    className="d-flex align-items-center icon cursor-pointer"
                                    onClick={() =>
                                      hideShowComments(i, data?.bl_id)
                                    }
                                  >
                                    <BiCommentDetail />{' '}
                                    <p className="m-0 ms-2">Comment</p>
                                  </span>
                                </div>
                              </div>
                              {data?.showComments && (
                                <div className="comment-section">
                                  <div style={{ minHeight: '100px' }}>
                                    {isCommentLoading ? (
                                      <div
                                        style={
                                          isCommentLoading
                                            ? {
                                                position: 'relative',
                                                opacity: '0.8',
                                                minHeight: '100px',
                                              }
                                            : {}
                                        }
                                      >
                                        <SubmitLoader />
                                      </div>
                                    ) : (
                                      <>
                                        <div className="row comment-section">
                                          <div className="col-12">
                                            <div className="comment-input position-relative">
                                              <input
                                                onChange={(e) => {
                                                  setComment(e.target.value)
                                                  setCurrentBlogIndex(i)
                                                  if (
                                                    e.target.value.length > 0
                                                  ) {
                                                    setShowError(false)
                                                  } else {
                                                    setShowError(true)
                                                  }
                                                }}
                                                value={comment ? comment : ''}
                                                className={`form-control`}
                                                name="bookName"
                                                placeholder={`Add Comment`}
                                              />
                                              <button
                                                disabled={isSubmitLoading}
                                                className="position-absolute comment-btn"
                                                onClick={() =>
                                                  addComment(data.bl_id, i)
                                                }
                                              >
                                                <div className="d-flex align-items-center justify-content-center">
                                                  {isSubmitLoading && (
                                                    <ButtonLoader
                                                      height="1.2rem"
                                                      width="1.2rem"
                                                    ></ButtonLoader>
                                                  )}

                                                  <span>Post</span>
                                                </div>
                                              </button>
                                            </div>
                                          </div>
                                          {showError && (
                                            <p className="text-danger mt-2">
                                              Please add comment
                                            </p>
                                          )}
                                        </div>
                                        <div className="comments-list">
                                          <ul className="comments-list-items">
                                            {data?.bl_comment &&
                                            data?.bl_comment.length > 0 ? (
                                              data?.bl_comment.map((data) => {
                                                return (
                                                  <li>
                                                    <img
                                                      src={`${IMAGE_URL}/${data?.cmn_profilePic}`}
                                                    />
                                                    <div className="cont">
                                                      <div className="top">
                                                        <div>
                                                          <h5 className="name">
                                                            {data?.cmn_fullName}
                                                          </h5>
                                                          <span className="date">
                                                            {moment(
                                                              data?.cmn_date,
                                                            ).format(
                                                              'DD MMM YYYY',
                                                            )}
                                                          </span>
                                                        </div>
                                                      </div>
                                                      <p>{data?.cmn_comment}</p>
                                                    </div>
                                                  </li>
                                                )
                                              })
                                            ) : (
                                              <>
                                                {data?.showNoRecordsMsg && (
                                                  <div
                                                    className="d-flex justify-content-center align-items-center"
                                                    style={{
                                                      minHeight: '100px',
                                                    }}
                                                  >
                                                    <h6>No comments yet</h6>
                                                  </div>
                                                )}
                                              </>
                                            )}
                                          </ul>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                          </>
                        )
                      })}
                  </>
                )}
              </div>

              {blogList.length >= 10 && !isLoading && (
                <button
                  className="cmn--btn text-center d-flex m-auto mt-3"
                  type="button"
                  onClick={() => getAllBlogsWithPagination(creatorType)}
                >
                  Load More
                </button>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
export default Blog

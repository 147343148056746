import React, { useState, useEffect } from 'react'
import DashboardSidebar from '../../components/DashboardSidebar'

import { useNavigate } from 'react-router-dom'

import { Field, Form, Formik, useFormik } from 'formik'
import {
  FaCamera,
  FaEnvelope,
  FaPhone,
  FaUser,
  FaUserPlus,
} from 'react-icons/fa'
import ImageUploading from 'react-images-uploading'
import * as Yup from 'yup'
import Select from 'react-select'

import { GrClose } from 'react-icons/gr'

import {
  updateProfile,
  updateProfilePicture,
  getProfile,
  getAllSchoolByArea,
} from '../../helpers/backendHelpers/student'
import { IMAGE_URL } from '../../helpers/urlHelper'
import SubmitLoader from '../../components/Common/SubmitLoader'
import {
  classRoomType,
  divisions,
  bloodGroups,
} from '../../helpers/dropdownVals'
import { AuthContext } from '../../contexts/AuthContext'
import { useContext } from 'react'
import {
  getfTeacherProfile,
  updateFteacherProfile,
} from '../../helpers/backendHelpers/fTeacher'
import {
  getParentProfile,
  updateParentProfile,
  updateProfilePictureParent,
} from '../../helpers/backendHelpers/parent'
import {
  getPublisherProfile,
  updateProfilePicturePublisher,
  updatePublisherProfile,
} from '../../helpers/backendHelpers/publisher'
import {
  getTeacherProfile,
  updateProfilePictureTeacher,
  updateTeacherProfile,
} from '../../helpers/backendHelpers/teacher'
import default_icon from '../../assets/images/default_avatar.png'
import RegionDistrictCircuitDropDownAllSelectable from 'helpers/RegionDistrictCircuitDropDownAllSelectable'
import { defaultRDCSeparator } from 'helpers/common'
import { BsFillCalendarDateFill } from 'react-icons/bs'
import { IoLocationSharp } from 'react-icons/io5'
import ButtonLoader from 'components/Common/ButtonLoader'
import { toast, ToastContainer } from 'react-toastify'
import { getCategories } from 'helpers/backendHelpers/book'
import StudentCommonEditForm from 'components/Common/StudentCommonEditForm'
import { getUserInfo, setFullUserInfo } from 'helpers/authHelper'

const Profile = () => {
  const [images, setImages] = React.useState([])
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const [isSubmitLoading, setIsSubmitLoading] = useState(true)
  const [isSubmitButtonLoading, setIsSubmitButtonLoading] = useState(false)
  const [selectedClass, setSelectedClass] = useState({})
  const [selectedDivsion, setSelectedDivsion] = useState({})
  const [selectedBloodGroup, setselectedBloodGroup] = useState('')
  const [updatedProfilePic, setUpdatedProfilePic] = useState('')
  // const { userInfo } = useContext(AuthContext)
  // const [userInfoJson, setUserInfoJson] = useState(JSON.parse(userInfo))
  const userInfo = getUserInfo()
  const [temp, setTemp] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [noRecords, setNoRecords] = useState(false)
  const [selectedSchool, setSelectedSchool] = useState('')
  const [schoolDropdownValues, setSchoolDropdownValues] = useState([])
  const [categoryList, setCategoryList] = useState(null)
  const [selectedLanguages, setSelectedLanguages] = useState(null)

  const [studentForm, setStudentForm] = useState({
    st_fullName: '',
    st_parentName: '',
    st_phoneNumber: '',
    st_countryCode: '',
    st_email: '',
    st_altEmail: '',
    st_studentId: '',
    st_class: '',
    st_division: '',
    areaValue: '',
    st_schoolId: '',
    st_parentEmail: '',
    st_parentPhoneNumber: '',
    st_profilePic: { fileName: '', file: {} },
  })
  const [teacherForm, setTeacherForm] = useState({
    tc_fullName: '',
    tc_phoneNumber: '',
    tc_countryCode: '',
    tc_email: '',
    tc_altEmail: '',
    tc_profilePic: { fileName: '', file: {} },
    areaValue: '',
    tc_staffId: '',
    tc_dateOfBirth: '',
    tc_experience: '',
    tc_level: '',
    tc_bloodGroup: '',
    tc_address: '',
    tc_briefProfile: '',
    tc_schoolId: '',
    tc_alsoKnownAs: '',
    tc_languageSpoken: '',
    tc_certificate: '',
  })
  const [parentForm, setparentForm] = useState({
    pt_fullName: '',
    pt_phoneNumber: '',
    pt_email: '',
    pt_profilePic: { fileName: '', file: {} },
  })
  const [publisherForm, setPublisherForm] = useState({
    pb_fullName: '',
    pb_phoneNumber: '',
    pb_email: '',
    pb_profilePic: { fileName: '', file: {} },
  })
  const maxNumber = 1

  useEffect(() => {
    setSuccess('')
    setError('')
    getAllCategories()
    if (userInfo.userType === 'Student' || userInfo.userType === 'Premium') {
      fetchStudentForEdit()
    } else if (
      userInfo.userType === 'Teacher' ||
      userInfo.userType === 'Freelance'
    ) {
      // fetchTeacherForEdit()
    }
    // else if (userInfoJson.userType === 'Parent') {
    //   fetchParentForEdit()
    // } else if (userInfoJson.userType === 'Publisher') {
    //   fetchPublisherForEdit()
    // }
  }, [])

  // useEffect(() => {
  //   setUserInfoJson(JSON.parse(userInfo))
  // }, [userInfo])

  useEffect(() => {
    console.log('studentForm12', studentForm)
  }, [studentForm])

  const onChange = async (imageList, addUpdateIndex) => {
    try {
      setIsSubmitLoading(true)
      let studentData = studentForm.st_profilePic
      studentData.st_profilePic = imageList[0]?.file
      const response = await updateProfilePicture(studentData)
      fetchStudentForEdit()
      localStorage.setItem(
        'profilePic',
        response.data.student[1][0].st_profilePic,
      )
      window.dispatchEvent(new Event('storage'))
      setUpdatedProfilePic(response.data.student[1][0].st_profilePic)
      let message = 'Profile pic uploaded successfully'
      setImages(imageList)
      setIsSubmitLoading(false)
      toast.success(message, {
        autoClose: 5000,
      })
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        'There was a problem updating profile picture'
      setIsSubmitLoading(false)
      toast.error(message, {
        autoClose: 5000,
      })
    }
  }
  const onChangeTeacher = async (imageList, addUpdateIndex) => {
    try {
      setIsSubmitLoading(true)
      let teacherData = teacherForm.tc_profilePic
      teacherData.tc_profilePic = imageList[0]?.file
      const response = await updateProfilePictureTeacher(teacherData)
      // fetchTeacherForEdit()
      localStorage.setItem('profilePic', response.data.teacher.tc_profilePic)
      window.dispatchEvent(new Event('storage'))
      let message = 'Profile pic uploaded successfully'
      setImages(imageList)
      setIsSubmitLoading(false)
      toast.success(message, {
        autoClose: 5000,
      })
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        'There was a problem updating profile picture'
      setIsSubmitLoading(false)
      toast.error(message, {
        autoClose: 5000,
      })
    }
  }

  const onChangeParent = async (imageList, addUpdateIndex) => {
    try {
      setIsSubmitLoading(true)
      let parentData = parentForm.pt_profilePic
      parentData.pt_profilePic = imageList[0]?.file
      const response = await updateProfilePictureParent(parentData)
      fetchParentForEdit()
      localStorage.setItem('profilePic', response.data.parent[0].pt_profilePic)
      window.dispatchEvent(new Event('storage'))
      let message = 'Profile pic uploaded successfully'
      setImages(imageList)
      setIsSubmitLoading(false)
      toast.success(message, {
        autoClose: 5000,
      })
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        'There was a problem updating profile picture'
      setIsSubmitLoading(false)
      toast.error(message, {
        autoClose: 5000,
      })
    }
  }

  const onChangePublisher = async (imageList, addUpdateIndex) => {
    try {
      setIsSubmitLoading(true)
      let publisherData = publisherForm.pb_profilePic
      publisherData.pb_profilePic = imageList[0]?.file
      const response = await updateProfilePicturePublisher(publisherData)
      fetchPublisherForEdit()
      localStorage.setItem(
        'profilePic',
        response.data.publisher[0].pb_profilePic,
      )
      window.dispatchEvent(new Event('storage'))

      let message = 'Profile pic uploaded successfully'
      setImages(imageList)
      setIsSubmitLoading(false)
      toast.success(message, {
        autoClose: 5000,
      })
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        'There was a problem updating profile picture'
      setIsSubmitLoading(false)
      toast.error(message, {
        autoClose: 5000,
      })
    }
  }

  const fetchStudentForEdit = async () => {
    try {
      setIsLoading(true)
      let response = await getProfile()
      let { studentProfile } = response.data || {}
      let { st_region, st_district, st_circuit } = studentProfile
      let areaValue = ''
      if (st_region || st_district || st_circuit) {
        areaValue = `${st_region || ''}${defaultRDCSeparator}${
          st_district || ''
        }${defaultRDCSeparator}${st_circuit || ''}`

        studentProfile['areaValue'] = areaValue
      } else {
        studentProfile['areaValue'] = ''
      }
      setSelectedClass({
        label: studentProfile.st_class,
        value: studentProfile.st_class,
      })
      setSelectedDivsion({
        label: studentProfile.st_division,
        value: studentProfile.st_division,
      })
      studentProfile['st_profilePic_old'] = studentProfile['st_profilePic']
      studentProfile['st_profilePic'] = { fileName: '', file: {} }
      studentProfile['st_parentName'] = studentProfile['st_parentName']
        ? studentProfile['st_parentName']
        : ''
      studentProfile['st_class'] = studentProfile['st_class']
        ? studentProfile['st_class']
        : ''
      studentProfile['st_division'] = studentProfile['st_division']
        ? studentProfile['st_division']
        : ''
      studentProfile['st_phoneNumber'] = studentProfile['st_phoneNumber']
        ? studentProfile['st_phoneNumber']
        : ''

      fetchAllSchoolByArea(areaValue, studentProfile['st_schoolId'])
      setStudentForm(studentProfile)
      setIsLoading(false)
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        'Error while fetching profile details'
      setIsLoading(false)
      return setError(message)
    }
  }

  // const fetchTeacherForEdit = async () => {
  //   try {
  //     setIsLoading(true)
  //     let response = await getTeacherProfile()
  //     let { teacherProfile } = response.data || {}

  //     teacherProfile['tc_profilePic_old'] = teacherProfile['tc_profilePic']
  //     teacherProfile['tc_profilePic'] = { fileName: '', file: {} }
  //     localStorage.setItem('profilePic', teacherProfile['tc_profilePic_old'])
  //     let { tc_region, tc_district, tc_circuit } = teacherProfile
  //     let areaValue = ''
  //     if (tc_region || tc_district || tc_circuit) {
  //       areaValue = `${tc_region || ''}${defaultRDCSeparator}${
  //         tc_district || ''
  //       }${defaultRDCSeparator}${tc_circuit || ''}`
  //       teacherProfile['areaValue'] = areaValue
  //     } else {
  //       teacherProfile['areaValue'] = ''
  //     }
  //     if (teacherProfile.tc_bloodGroup) {
  //       setselectedBloodGroup({
  //         label: teacherProfile.tc_bloodGroup,
  //         value: teacherProfile.tc_bloodGroup,
  //       })
  //     }
  //     fetchAllSchoolByArea(areaValue, teacherProfile['tc_schoolId'])
  //     setTeacherForm(teacherProfile)
  //     setIsLoading(false)
  //   } catch (error) {
  //     let message =
  //       error?.response?.data?.message ||
  //       error?.message ||
  //       'Error while fetching profile details'
  //     setIsLoading(false)
  //     return setError(message)
  //   }
  // }

  const fetchParentForEdit = async () => {
    try {
      setIsLoading(true)
      let response = await getParentProfile()
      let { parentProfile } = response.data || {}

      parentProfile['pt_profilePic_old'] = parentProfile['pt_profilePic']
      parentProfile['pt_profilePic'] = { fileName: '', file: {} }
      localStorage.setItem('profilePic', parentProfile['pt_profilePic_old'])
      setparentForm(parentProfile)
      setIsLoading(false)
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        'Error while fetching profile details'
      setIsLoading(false)
      return setError(message)
    }
  }

  const fetchPublisherForEdit = async () => {
    try {
      setIsLoading(true)
      let response = await getPublisherProfile()
      let { publisherProfile } = response.data || {}

      publisherProfile['pb_profilePic_old'] = publisherProfile['pb_profilePic']
      publisherProfile['pb_profilePic'] = { fileName: '', file: {} }
      setPublisherForm(publisherProfile)
      setIsLoading(false)
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        'Error while fetching profile details'
      setIsLoading(false)
      return setError(message)
    }
  }

  const updateStudentProfileDash = async (data) => {
    try {
      setIsLoading(true)
      setIsSubmitLoading(true)
      setIsSubmitButtonLoading(true)
      const response = await updateProfile(data)
      let { student } = response.data || {}
      if (response.status) {
        let message = 'Great! Your profile is completed successfully'
        setIsLoading(false)
        toast.success(message, {
          autoClose: 5000,
        })
      }
      setFullUserInfo(student[1][0])
      setIsSubmitLoading(true)
      setIsSubmitButtonLoading(false)
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        'Error while profile update'
      setIsSubmitLoading(false)
      setIsSubmitButtonLoading(false)
      setIsLoading(false)
      toast.error(message, {
        autoClose: 5000,
      })
    }
  }

  const updateTeacherProfileDash = async (data) => {
    try {
      setIsLoading(true)
      setIsSubmitLoading(true)
      setIsSubmitButtonLoading(true)
      const response = await updateTeacherProfile(data)
      if (response.status) {
        let message = 'Great! Your profile is completed successfully'
        setIsLoading(false)
        toast.success(message, {
          autoClose: 5000,
        })
      }
      setIsSubmitLoading(true)
      setIsSubmitButtonLoading(false)
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        'Error while profile update'
      setIsSubmitLoading(false)
      setIsSubmitButtonLoading(false)
      setIsLoading(false)
      toast.error(message, {
        autoClose: 5000,
      })
    }
  }

  const updateParentProfileDash = async (data) => {
    try {
      setIsLoading(true)
      setIsSubmitLoading(true)
      const response = await updateParentProfile(data)
      if (response.status) {
        let message = 'Great! Your profile is completed successfully'
        setIsLoading(false)
        toast.success(message, {
          autoClose: 5000,
        })
      }
      setIsSubmitLoading(false)
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        'Error while profile update'
      setIsSubmitLoading(false)
      toast.error(message, {
        autoClose: 5000,
      })
    }
  }

  const updatePublisherProfileDash = async (data) => {
    try {
      setIsLoading(true)
      setIsSubmitLoading(true)
      const response = await updatePublisherProfile(data)
      if (response.status) {
        let message = 'Great! Your profile is completed successfully'
        setIsLoading(false)
        toast.success(message, {
          autoClose: 5000,
        })
      }
      setIsSubmitLoading(false)
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        'Error while profile update'
      setIsSubmitLoading(false)
      setIsLoading(false)
      toast.error(message, {
        autoClose: 5000,
      })
    }
  }

  const fetchAllSchoolByArea = async (values, oldVal) => {
    if (values === '') {
      if (userInfo.userType == 'Student' || userInfo.userType == 'Premium') {
        setStudentForm({ ...studentForm, st_schoolId: '' })
      } else if (
        userInfo.userType == 'Teacher' ||
        userInfo.userType == 'Freelance'
      ) {
        setTeacherForm({ ...teacherForm, tc_schoolId: '' })
      }
    } else {
      try {
        let [region, district, circuit] =
          (values + '' || '')?.split(defaultRDCSeparator) || []
        region = region || ''
        district = district || ''
        circuit = circuit || ''

        const response = await getAllSchoolByArea(region, district, circuit)
        let { schools } = response.data || {}

        let dropdownVals = schools.map((school) => {
          return { value: school.sc_id, label: school.sc_schoolName }
        })

        dropdownVals = dropdownVals || []
        setSchoolDropdownValues(dropdownVals)
        if (oldVal) {
          const defVal = dropdownVals.find((item) => item.value === oldVal)
          defVal
            ? setSelectedSchool(defVal)
            : setSelectedSchool({ label: '', value: '' })
        } else {
          setSelectedSchool({ label: '', value: '' })
        }
      } catch (error) {
        let message =
          error?.response?.data?.message ||
          error?.message ||
          'There Was A Problem Fetching Schools'

        setSchoolDropdownValues([])
      }
    }
  }

  const getAllCategories = async (filterCategory = '') => {
    try {
      let response = await getCategories()
      let { categories } = response.data
      let vals = []
      let mainCategoryArray = []

      if (filterCategory) {
        let filteredData = categories.filter(
          (data) => data.categoryId == filterCategory,
        )
        filteredData[0].category.map((data) => {
          vals.push({ name: data.CategoryName, value: data.CategoryName })
        })
      } else {
        categories.map((mainCategory) => {
          let val = []
          let { category } = mainCategory

          category.map((cat) => {
            val.push({ label: cat.CategoryName, value: cat.CategoryName })
          })
          mainCategoryArray.push({
            label: mainCategory.categoryName,
            options: val,
          })
        })
      }
      setCategoryList(mainCategoryArray)
    } catch (error) {
      console.log('error', error)
    }
  }

  const teacherFormik = useFormik({
    enableReinitialize: true,
    initialValues: teacherForm,
    onSubmit: (values) => {
      let teacherData = values
      let [tc_region, tc_district, tc_circuit] =
        (values?.areaValue + '' || '')?.split(defaultRDCSeparator) || []
      let tc_languageSpoken = selectedLanguages.map((data) => data.value)
      let tc_subject = teacherForm?.tc_subject ? teacherForm?.tc_subject : null
      let tc_classRoomId = teacherForm?.tc_classRoomId
        ? teacherForm?.tc_classRoomId
        : null
      tc_region = tc_region || null
      tc_district = tc_district || null
      tc_circuit = tc_circuit || null
      teacherData['tc_region'] = tc_region
      teacherData['tc_district'] = tc_district
      teacherData['tc_circuit'] = tc_circuit
      teacherData['tc_languageSpoken'] = tc_languageSpoken
        ? tc_languageSpoken?.toString()
        : null
      teacherData['tc_subject'] = tc_subject ? tc_subject?.toString() : null
      teacherData['tc_classRoomId'] = tc_classRoomId
        ? tc_classRoomId?.toString()
        : null
      delete teacherData['tc_profilePic']
      console.log('teacherdatasubmit', teacherData)
      updateTeacherProfileDash(teacherData)
    },
    validationSchema: Yup.object({
      tc_fullName: Yup.string()
        .min(5, 'Mininum 5 characters')
        .test(
          'tc_fullName',
          'This username has already been taken',
          function (tc_fullName) {
            return 'tc_fullName'
          },
        )
        .required('Please enter full name'),

      tc_email: Yup.string()
        .email('Invalid email address')
        .required('Please enter your email'),

      tc_altEmail:
        userInfo?.userType == 'Teacher'
          ? Yup.string()
              .email('Invalid email address')
              .required('Please provide your NTC email address')
              .nullable()
          : Yup.string().email('Invalid email address').nullable(),

      tc_phoneNumber: Yup.string()
        .required('Please enter phone number')
        .matches(/^\d{9}$/, 'Please enter 9 digit number,without adding 0'),
      tc_countryCode: Yup.string().required('Please enter country code'),
      tc_staffId:
        userInfo?.userType == 'Teacher' &&
        Yup.string().required('Please enter your staff id'),
      tc_certificate: Yup.string()
        .required('Please enter your certificates')
        .nullable(),
      tc_profilePic: Yup.mixed().notRequired().nullable(),
      tc_dateOfBirth: Yup.string().notRequired().nullable(),
      tc_briefProfile: Yup.string()
        .required('Please enter your brief profile')
        .nullable(),
      tc_experience: Yup.string()
        .required('Please enter your experience')
        .nullable(),
      tc_level: Yup.string().required('Please enter your level').nullable(),
      tc_bloodGroup: Yup.string()
        .nullable()
        .notRequired('Please select bloodgroups'),
      tc_languageSpoken: Yup.mixed().test(
        'invalidInput',
        'Please Select Languages',
        (value) => {
          if (value) {
            return value.length
          } else {
            return false
          }
        },
      ),
      areaValue: Yup.mixed().test(
        'invalidInput',
        'Please select region-district',
        (value) => {
          return !!value
        },
      ),
      tc_schoolId: Yup.string().required('Please select school').nullable(),
    }),
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: studentForm,
    onSubmit: (values) => {
      let studentData = values
      let [st_region, st_district, st_circuit] =
        (values?.areaValue + '' || '')?.split(defaultRDCSeparator) || []
      st_region = st_region || null
      st_district = st_district || null
      st_circuit = st_circuit || null
      studentData['st_region'] = st_region
      studentData['st_district'] = st_district
      studentData['st_circuit'] = st_circuit
      delete studentData['st_profilePic']
      updateStudentProfileDash(studentData)
    },
    validationSchema: Yup.object({
      st_fullName: Yup.string()
        .min(5, 'Mininum 5 characters')
        .test(
          'st_fullName',
          'This username has already been taken',
          function (st_fullName) {
            return 'st_fullName'
          },
        )
        .required('Please enter full name'),
      st_email: Yup.string()
        .email('Invalid email address')
        .required('Please enter your email'),
      st_altEmail: Yup.string()
        .email('Invalid email address')
        .notRequired()
        .nullable(),
      st_class: Yup.string().required('Please enter your class').nullable(),
      st_division: Yup.string()
        .required('Please enter your division')
        .nullable(),

      st_phoneNumber: Yup.string()
        .required('Please enter your phone number')
        .matches(/^\d{9}$/, 'Please enter 9 digit number,without adding 0'),
      st_profilePic: Yup.mixed().notRequired().nullable(),
      areaValue: Yup.mixed().test(
        'invalidInput',
        'Please select region-district',
        (value) => {
          return !!value
        },
      ),
      st_schoolId: Yup.string().required('Please select school').nullable(),
      st_parentEmail: Yup.string().email('Invalid email address').notRequired(),
      st_parentPhoneNumber: Yup.string()
        .required('Please enter your parent phone number')
        .matches(/^\d{9}$/, 'Please enter 9 digit number,without adding 0')
        .nullable(),
    }),
  })

  const handleFormValueChange = (e) => {
    setIsSubmitLoading(false)
  }

  return (
    <div className="py-5">
      <div className="container">
        {/* <ToastContainer position="top-right" /> */}
        <div className="d-flex flex-wrap">
          <DashboardSidebar />
          <div className="dashboard-article">
            <div className="card cmn--card">
              <div className="card-header py-3">
                <h5 className="card-title">Edit Profile</h5>
              </div>
              <div className="card-body">
                {/* Parent Edit Profile */}
                {/* {userInfoJson?.userType == 'Parent' && (
                  <Formik
                    enableReinitialize={true}
                    initialValues={parentForm}
                    validationSchema={Yup.object({
                      pt_fullName: Yup.string()
                        .min(5, 'Mininum 5 characters')
                        .test(
                          'pt_fullName',
                          'This username has already been taken',
                          function (pt_fullName) {
                            return 'pt_fullName'
                          },
                        )
                        .required('Please enter full name'),

                      pt_email: Yup.string()
                        .email('Invalid email address')
                        .required('Please enter your email'),

                      pt_phoneNumber: Yup.string()
                        .required('Please enter phone number')
                        .matches(
                          /^[0-9]{10}$/,
                          'Please enter valid phone number',
                        ),
                      pt_profilePic: Yup.mixed().notRequired().nullable(),
                    })}
                    onSubmit={async (values, actions) => {
                      let parentData = values
                      delete parentData['pt_profilePic']
                      updateParentProfileDash(parentData)
                    }}
                  >
                    {({
                      touched,
                      errors,
                      values,
                      handleSubmit,
                      handleBlur,
                      handleChange,
                      setFieldValue,
                      setFieldTouched,
                      isSubmitting,
                    }) => (
                      <Form
                        className="profile-form my-4 py-2"
                        autoComplete="off"
                        onSubmit={(e) => {
                          e.preventDefault()
                          handleSubmit(e)
                          return false
                        }}
                      >
                        <ImageUploading
                          multiple
                          value={images}
                          onChange={onChangeParent}
                          maxNumber={maxNumber}
                          dataURLKey="data_url"
                        >
                          {({
                            imageList,
                            onImageUpload,
                            onImageRemoveAll,
                            onImageUpdate,
                            onImageRemove,
                            isDragging,
                            dragProps,
                          }) => (
                            <>
                              {imageList.length === 0 && (
                                <>
                                  <div className="update-image-container">
                                    <img
                                      src={
                                        parentForm?.pt_profilePic_old
                                          ? `${IMAGE_URL}/${parentForm?.pt_profilePic_old}`
                                          : default_icon
                                      }
                                      alt={parentForm?.pt_fullName}
                                    />
                                    <label
                                      className="upload-icon"
                                      onClick={onImageUpload}
                                    >
                                      <FaCamera />
                                    </label>
                                  </div>
                                </>
                              )}
                              <div className="upload__image-wrapper">
                                {imageList.map((image, index) => (
                                  <div key={index} className="image-item">
                                    <img src={image['data_url']} alt="" />
                                    <button
                                      onClick={() => onImageRemove(index)}
                                      className="remove-btn"
                                    >
                                      <GrClose />
                                    </button>
                                  </div>
                                ))}
                              </div>
                            </>
                          )}
                        </ImageUploading>

                        <label htmlFor="" className="form-label">
                          Full name
                        </label>
                        <div className="input--group">
                          <Field
                            className={`form-control ${
                              errors.pt_fullName && touched.pt_fullName
                                ? 'form-err'
                                : ''
                            }`}
                            name="pt_fullName"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Enter full name"
                            invalid={touched.pt_fullName && errors.pt_fullName}
                            defaultValue={parentForm?.pt_fullName}
                          />
                          <span className="icon">
                            <FaUser />
                          </span>
                          {errors.pt_fullName && touched.pt_fullName ? (
                            <div className="form-err-msg">
                              {errors.pt_fullName}
                            </div>
                          ) : null}
                        </div>

                        <label htmlFor="" className="form-label">
                          Email
                        </label>
                        <div className="input--group">
                          <Field
                            className={`form-control ${
                              errors.pt_email && touched.pt_email
                                ? 'form-err'
                                : ''
                            }`}
                            disabled={true}
                            name="pt_email"
                            defaultValue={parentForm?.pt_email}
                            onChange={handleChange}
                            placeholder="Enter Email"
                          />
                          <span className="icon">
                            <FaEnvelope />
                          </span>
                          {errors.pt_email && touched.pt_email ? (
                            <div className="form-err-msg">
                              {errors.pt_email}
                            </div>
                          ) : null}
                        </div>
                        <label htmlFor="" className="form-label">
                          Phone
                        </label>
                        <div className="input--group">
                          <Field
                            // type="select"
                            className={`form-control ${
                              errors.pt_phoneNumber && touched.pt_phoneNumber
                                ? 'form-err'
                                : ''
                            }`}
                            name="pt_phoneNumber"
                            defaultValue={parentForm?.pt_phoneNumber}
                            type="number"
                            onChange={handleChange}
                            placeholder="Enter Phone"
                          />
                          <span className="icon">
                            <FaPhone />
                          </span>
                          {errors.pt_phoneNumber && touched.pt_phoneNumber ? (
                            <div className="form-err-msg">
                              {errors.pt_phoneNumber}
                            </div>
                          ) : null}
                        </div>
                        <button
                          disabled={isSubmitLoading}
                          className="cmn--btn  w-100 form-control"
                          type="submit"
                        >
                          <div className="d-flex align-items-center justify-content-center">
                            {isSubmitLoading && <ButtonLoader></ButtonLoader>}

                            <span>Submit</span>
                          </div>
                        </button>
                      </Form>
                    )}
                  </Formik>
                )} */}
                {/* Publisher Profile from */}
                {/* {userInfoJson?.userType == 'Publisher' && (
                  <Formik
                    enableReinitialize={true}
                    initialValues={publisherForm}
                    validationSchema={Yup.object({
                      pb_fullName: Yup.string()
                        .min(5, 'Mininum 5 characters')
                        .test(
                          'pb_fullName',
                          'This username has already been taken',
                          function (pb_fullName) {
                            return 'pb_fullName'
                          },
                        )
                        .required('Please enter full name'),

                      pb_email: Yup.string()
                        .email('Invalid email address')
                        .required('Please enter your email'),

                      pb_phoneNumber: Yup.string()
                        .required('Please enter phone number')
                        .matches(
                          /^[0-9]{10}$/,
                          'Please enter valid phone number',
                        ),
                      pb_profilePic: Yup.mixed().notRequired().nullable(),
                    })}
                    onSubmit={async (values, actions) => {
                      let publisherData = values
                      delete publisherData['pb_profilePic']
                      updatePublisherProfileDash(publisherData)
                    }}
                  >
                    {({
                      touched,
                      errors,
                      values,
                      handleSubmit,
                      handleBlur,
                      handleChange,
                      setFieldValue,
                      setFieldTouched,
                      isSubmitting,
                    }) => (
                      <Form
                        className="profile-form my-4 py-2"
                        autoComplete="off"
                        onSubmit={(e) => {
                          e.preventDefault()
                          handleSubmit(e)
                          return false
                        }}
                      >
                        <ImageUploading
                          multiple
                          value={images}
                          onChange={onChangePublisher}
                          maxNumber={maxNumber}
                          dataURLKey="data_url"
                        >
                          {({
                            imageList,
                            onImageUpload,
                            onImageRemoveAll,
                            onImageUpdate,
                            onImageRemove,
                            isDragging,
                            dragProps,
                          }) => (
                            <>
                              {imageList.length === 0 && (
                                <>
                                  <div className="update-image-container">
                                    <img
                                      src={
                                        publisherForm?.pb_profilePic_old
                                          ? `${IMAGE_URL}/${publisherForm?.pb_profilePic_old}`
                                          : default_icon
                                      }
                                      alt={publisherForm?.pb_fullName}
                                    />
                                    <label
                                      className="upload-icon"
                                      onClick={onImageUpload}
                                    >
                                      <FaCamera />
                                    </label>
                                  </div>
                                </>
                              )}
                              <div className="upload__image-wrapper">
                                {imageList.map((image, index) => (
                                  <div key={index} className="image-item">
                                    <img src={image['data_url']} alt="" />
                                    <button
                                      onClick={() => onImageRemove(index)}
                                      className="remove-btn"
                                    >
                                      <GrClose />
                                    </button>
                                  </div>
                                ))}
                              </div>
                            </>
                          )}
                        </ImageUploading>

                        <label htmlFor="" className="form-label">
                          Full name
                        </label>
                        <div className="input--group">
                          <Field
                            className={`form-control ${
                              errors.pb_fullName && touched.pb_fullName
                                ? 'form-err'
                                : ''
                            }`}
                            name="pb_fullName"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Enter full name"
                            invalid={touched.pb_fullName && errors.pb_fullName}
                            defaultValue={publisherForm?.pb_fullName}
                          />
                          <span className="icon">
                            <FaUser />
                          </span>
                          {errors.pb_fullName && touched.pb_fullName ? (
                            <div className="form-err-msg">
                              {errors.pb_fullName}
                            </div>
                          ) : null}
                        </div>

                        <label htmlFor="" className="form-label">
                          Email
                        </label>
                        <div className="input--group">
                          <Field
                            className={`form-control ${
                              errors.pb_email && touched.pb_email
                                ? 'form-err'
                                : ''
                            }`}
                            disabled={true}
                            name="pb_email"
                            defaultValue={publisherForm?.pb_email}
                            onChange={handleChange}
                            placeholder="Enter email"
                          />
                          <span className="icon">
                            <FaEnvelope />
                          </span>
                          {errors.pb_email && touched.pb_email ? (
                            <div className="form-err-msg">
                              {errors.pb_email}
                            </div>
                          ) : null}
                        </div>
                        <label htmlFor="" className="form-label">
                          Phone
                        </label>
                        <div className="input--group">
                          <Field
                            // type="select"
                            className={`form-control ${
                              errors.pb_phoneNumber && touched.pb_phoneNumber
                                ? 'form-err'
                                : ''
                            }`}
                            name="pb_phoneNumber"
                            defaultValue={publisherForm?.pb_phoneNumber}
                            type="number"
                            onChange={handleChange}
                            placeholder="Enter Phone"
                          />
                          <span className="icon">
                            <FaPhone />
                          </span>
                          {errors.pb_phoneNumber && touched.pb_phoneNumber ? (
                            <div className="form-err-msg">
                              {errors.pb_phoneNumber}
                            </div>
                          ) : null}
                        </div>
                        <button
                          disabled={isSubmitLoading}
                          className="cmn--btn  w-100 form-control"
                          type="submit"
                        >
                          <div className="d-flex align-items-center justify-content-center">
                            {isSubmitLoading && <ButtonLoader></ButtonLoader>}

                            <span>Submit</span>
                          </div>
                        </button>
                      </Form>
                    )}
                  </Formik>
                )} */}
                <StudentCommonEditForm
                  callFrom={2}
                  teacherFormik={teacherFormik}
                  teacherForm={teacherForm}
                  setTeacherForm={setTeacherForm}
                  formik={formik}
                  studentForm={studentForm}
                  setStudentForm={setStudentForm}
                  selectedLanguages={selectedLanguages}
                  setSelectedLanguages={setSelectedLanguages}
                  isSubmitLoading={isSubmitLoading}
                  setIsSubmitLoading={setIsSubmitLoading}
                  setIsSubmitButtonLoading={setIsSubmitButtonLoading}
                  isSubmitButtonLoading={isSubmitButtonLoading}
                  handleFormValueChange={handleFormValueChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Profile

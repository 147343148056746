import React from 'react'
import { Link } from 'react-router-dom'
import feature_data from '../../../assets/images/data/feature'
import feature_bg from '../../../assets/images/feature-bg.png'
import feature from '../../../assets/images/feature.png'
const Feature = () => {
  return (
    <>
      <div className="feature-section">
        <div
          className="feature-bg section-overlay"
          style={{
            background: `url(${feature_bg}) no-repeat center center / cover`,
          }}
        ></div>
        <div className="container position-relative">
          <div className="row align-items-center">
            <div className="col-lg-8">
              <div className="feature-content pt-100 pb-100">
                {/* <h6 className="subtitle">Type Text Here</h6> */}
                <h2 className="title">A Multi-Layered Knowledge Enhancement Platform</h2>
                <ul className="feature-area">
                  {feature_data &&
                    feature_data.map(({ icon, text }, i) => (
                      <li key={i}>
                        <div className="icon">
                          <Link to="/auth/signup">
                            <img src={icon} alt="" />
                          </Link>
                        </div>

                        <div className="text">
                          <Link to="/auth/signup">{text}</Link>
                        </div>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="feature-img ps-xl-4">
                <img src={feature} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Feature

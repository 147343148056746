import img1 from "../../images/team/1.png";
import img2 from "../../images/team/2.png";
import img3 from "../../images/team/3.png";
const team__data = [
	{
		img: img1,
		title: "Robot Smith",
		designation: "Founder / Teacher",
	},
	{
		img: img2,
		title: "Jonathan Roberts",
		designation: "Founder / Teacher",
	},
	{
		img: img3,
		title: "Adam Green",
		designation: "Founder / Teacher",
	},
	{
		img: img1,
		title: "Robot Smith",
		designation: "Founder / Teacher",
	},
	// {
	// 	img: img2,
	// 	title: "Jonathan Roberts",
	// 	designation: "Founder / Teacher",
	// },
	// {
	// 	img: img3,
	// 	title: "Adam Green",
	// 	designation: "Founder / Teacher",
	// },
];
export default team__data;

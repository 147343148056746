import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { IMAGE_URL } from '../../helpers/urlHelper'
const VideoModal = (props) => {
  const { isOpen, toggle, video } = props
  const handleContextMenu = (e) => {
    e.preventDefault()
  }
  {
    if (video !== null)
      return (
        <>
          <Modal
            onContextMenu={handleContextMenu}
            isOpen={isOpen}
            size="lg"
            role="dialog"
            autoFocus={true}
            centered={true}
            className="tutorModal"
            tabIndex="-1"
            toggle={toggle}
          >
            <ModalHeader toggle={toggle}>Watch Video</ModalHeader>
            <ModalBody>
              <div className="embed-responsive embed-responsive-16by9 ratio ratio-16x9">
                <video
                  allow="fullscreen"
                  frameBorder="0"
                  width="100%"
                  height="700"
                  controls
                  controlsList="nodownload"
                >
                  <source src={`${IMAGE_URL}/${video}#toolbar=0`} />
                </video>
              </div>
            </ModalBody>
          </Modal>
        </>
      )
  }
}

VideoModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default VideoModal

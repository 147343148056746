import { Field, Form, Formik } from 'formik'
import { useState, useEffect } from 'react'
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi'
import { BsStarFill } from 'react-icons/bs'
import { GoPrimitiveDot, GoStar } from 'react-icons/go'
import { Link, useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import './LibraryBooks.scss'
import { getBook } from '../../helpers/backendHelpers/book'
import SubmitLoader from '../../components/Common/SubmitLoader'
import { IMAGE_URL } from '../../helpers/urlHelper'
import VideoModal from '../../components/Common/WatchModal'
import ListenModal from '../../components/Common/ListenModal'
import DashboardSidebar from '../../components/DashboardSidebar'
import PDfModal from '../../components/Common/PdfMoal'
import {
  createBookReview,
  getBookReviews,
} from '../../helpers/backendHelpers/bookReview'

// Rating
import Rating from '@material-ui/lab/Rating'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { checkIdWithEncryption, getUserInfo } from 'helpers/authHelper'
import ButtonLoader from 'components/Common/ButtonLoader'
import default_icon from '../../assets/images/default_avatar.png'
import { toast, ToastContainer } from 'react-toastify'
import PdfReaderModal from 'components/Common/PdfReaderModal'

const LibraryBookDetail = ({ toggleDetails, bookIdProps }) => {
  const navigate = useNavigate()
  const { id } = useParams()
  const bookId = bookIdProps ? bookIdProps : id
  // 0 = backToLibaryFromProps, 1 = backToLibararyFromParams
  const backToLibrary = bookIdProps ? 1 : 0

  const [book, setBook] = useState({})
  const [decryptedId, setDecryptedId] = useState('')
  const [error, setError] = useState('')
  //preview video modal states
  const [previewVideo, setPreviewVideo] = useState('')
  const [previewVideoModal, setPreviewVideoModal] = useState(false)
  //video modal states
  const [videoFile, setVideoFile] = useState('')
  const [videoModal, setVideoModal] = useState(false)
  //audio modal states
  const [audioFile, setAudioFile] = useState('')
  const [audioModal, setAudioModal] = useState(false)
  // Pdf model states
  const [pdfFile, setPdfFile] = useState('')
  const [pdfModal, setPdfModal] = useState(false)
  // Pdf model states
  const [ePubFile, setePubFile] = useState('')
  const [ePubModal, setePubModal] = useState(false)
  //for loader
  const [isLoading, setIsLoading] = useState(false)

  const [save, setSave] = useState(false)
  const userInfo = getUserInfo()

  // Book Reviews
  const [bookReviews, setBookReviews] = useState([])
  const [submitLoading, setSubmitLoading] = useState(false)
  const [ratingValue, setRatingValue] = useState(0)
  const [isSubmitLoading, setIsSubmitLoading] = useState(false)
  const [pdfReaderModal, setPdfReaderModal] = useState(false)

  const [form, setForm] = useState({
    br_comment: '',
    br_rate: '',
    br_bookId: 0,
  })

  useEffect(() => {
    // fetchBook()
    // fetchBookReviews()
  }, [save])

  useEffect(() => {
    if (bookIdProps) {
      setDecryptedId(bookIdProps)
      fetchBook(bookIdProps)
      fetchBookReviews(bookIdProps)
    } else {
      let idFromRes = checkIdWithEncryption(id, setDecryptedId, navigate)
      bookIdProps && setDecryptedId(bookIdProps)
      if (idFromRes) {
        fetchBook(idFromRes)
        fetchBookReviews(idFromRes)
      }
    }
  }, [id])
  const fetchBook = async (id) => {
    try {
      let userId = userInfo?.id
      setIsLoading(true)
      const response = await getBook(id, userId)
      const { book } = response.data

      book.bk_previewVideo
        ? setPreviewVideo(book.bk_previewVideo)
        : setPreviewVideo('')
      book.bk_video ? setVideoFile(book.bk_video) : setVideoFile('')
      book.bk_audio ? setAudioFile(book.bk_audio) : setAudioFile('')
      book.bk_epub ? setePubFile(book.bk_epub) : setePubFile('')
      book.bk_pdf ? setPdfFile(book.bk_pdf) : setPdfFile('')

      setBook(book)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      setError(error)
    }
  }

  const fetchBookReviews = async (id) => {
    try {
      setIsLoading(true)
      const response = await getBookReviews(id)
      const { bookReviews } = response.data
      setBookReviews(bookReviews)
      // setForm(bookReviews)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      let message =
        error?.response?.data?.message ||
        error?.message ||
        'There Was A Problem fetch BookReview'
      setError(message)
    }
  }

  const handleBookReviewSubmit = async (data) => {
    try {
      setIsSubmitLoading(true)
      const response = await createBookReview(data)
      if (response.status) {
        let message = 'Thankful for your review'

        setIsSubmitLoading(false)
        toast.success(message, {
          autoClose: 5000,
        })
      }
    } catch (error) {
      setIsSubmitLoading(false)
      let message =
        error?.response?.data?.message ||
        error?.message ||
        'There Was A Problem Inserting BookReview'
      setError(message)
    }
  }

  //remove from library(FOR FUTURE USE)
  /* const handleRemoveFromLibraryClick = async () => {
         const isLoggedIn = getAuthToken() ? true : false
 
         if (!isLoggedIn) {
             alert("Please Log In")
         } else {
             try {
                 const response = await removeFromLibrary(bookId)
                 if (response.status) {
                     alert("Book removed from library")
                     return (
                         navigate("/my-library")
                     )
                 } else {
                     alert('There was problem while removing book from library')
                 }
             } catch (error) {
                 alert(error)
             }
         }
     }*/

  const toggleWatchModal = () => {
    setVideoModal(!videoModal)
  }
  const toggleWatchPreviewModal = () => {
    setPreviewVideoModal(!previewVideoModal)
  }
  const toggleListenModal = () => {
    setAudioModal(!audioModal)
  }
  const togglePdfModal = () => {
    setPdfModal(!pdfModal)
  }
  const toggleePubModal = (i, j) => {
    // navigate(`/epub?first=${i}`)
    navigate('/')

    // setePubModal(!ePubModal)
  }

  const togglePdfReaderModal = () => {
    setPdfReaderModal(!pdfReaderModal)
  }

  return (
    <section className="books-single py-5">
      <ToastContainer position="top-right" />
      <VideoModal
        isOpen={previewVideoModal}
        toggle={toggleWatchPreviewModal}
        video={previewVideo}
      />
      <VideoModal
        isOpen={videoModal}
        toggle={toggleWatchModal}
        video={videoFile}
      />
      <ListenModal
        isOpen={audioModal}
        toggle={toggleListenModal}
        audio={audioFile}
      />
      <PDfModal
        isOpen={pdfModal}
        toggle={togglePdfModal}
        pdf={pdfFile}
        type="pdf"
      />
      <PDfModal
        isOpen={ePubModal}
        toggle={toggleePubModal}
        pdf={ePubFile}
        type="ePub"
      />
      <PdfReaderModal
        modalTitle={'Read PDF'}
        isOpen={pdfReaderModal}
        toggle={togglePdfReaderModal}
        pdf={pdfFile}
        type="pdf"
        isLoading={isLoading}
      />
      <div className="container">
        <div className="page-header-2">
          <ul className="breadcrumb-2">
            <li>
              <Link to="/books">
                My Library
                <BiChevronRight />
              </Link>
            </li>
            <li>{book.bk_title}</li>
          </ul>
          <div
            onClick={() => {
              if (backToLibrary) {
                toggleDetails()
              } else {
                navigate('/my-library')
              }
            }}
            className="back-btn text-base"
          >
            <BiChevronLeft /> Back to Library
          </div>
        </div>
        <div className="books-single row d-flex flex-wrap">
          <div className="books-single-sidebar col-lg-3">
            <DashboardSidebar />
          </div>
          <div
            className="books-single-wrapper col-lg-9"
            style={isLoading ? { position: 'relative', opacity: '0.9' } : {}}
          >
            {isLoading ? <SubmitLoader /> : <></>}
            <div
              className="books-single-inner"
              style={isLoading ? { display: 'none' } : {}}
            >
              <div className="books-single-top">
                <div className="books-single-top-img">
                  {book.bk_preview && (
                    <img
                      src={`${IMAGE_URL}/${book.bk_preview}`}
                      alt={book.bk_name}
                    />
                  )}
                </div>
                <div className="books-single-top-cont">
                  <h4 className="title">{book.bk_title}</h4>
                  <div className="rating-area">
                    <div className="rating">
                      <span className="text-rating">
                        <GoStar />
                      </span>
                      <span className="text-rating">
                        <GoStar />
                      </span>
                      <span className="text-rating">
                        <GoStar />
                      </span>
                      <span className="text-clr">
                        <GoStar />
                      </span>
                      <span className="text-clr">
                        <GoStar />
                      </span>
                      <small className="ms-2 text-rating">9.3</small>
                    </div>
                    <div className="text-green">
                      <GoPrimitiveDot /> In stock
                    </div>
                  </div>
                  <h4 className="mt-2 mb-2 text-green">
                    {book.bk_isFree ? 'Free' : `${book.bk_isFree} GHS per book`}
                  </h4>

                  <ul className="genre-info">
                    <li>
                      <span>Genre</span>
                      <strong>{book.bk_genre}</strong>
                    </li>
                    <li>
                      <span>Publication</span>
                      <strong>{book.bk_publisher}</strong>
                    </li>
                    <li>
                      <span>Author</span>
                      <strong>{book.bk_author}</strong>
                    </li>
                  </ul>
                  <div className="details__btn-grp">
                    {book.bk_epub ? (
                      // <a
                      //   href={`${EPUB_URL}?idValue=${encodeURIComponent(
                      //     IMAGE_URL + '/' + book.bk_epub,
                      //   )}`}
                      //   target="_blank"
                      //   className="cmn--btn"
                      // >
                      <Link
                        // to={`#`}
                        // onClick={() => {
                        //   window.location = `/epub/${book.bk_title}?ePub=${book.bk_epub}`
                        // }}
                        // target="_blank"
                        to={`/epub/${book.bk_title}?ePub=${book.bk_epub}`}
                        className="cmn--btn bg--title"
                        state={{ epub: book.bk_epub }}
                        // onClick={() =>
                        //   toggleePubModal(book.bk_epub, book.bk_title)
                        // }
                      >
                        Read ePub
                      </Link>
                    ) : (
                      <></>
                    )}
                    {book.bk_pdf && (
                      <Link
                        to="#"
                        className="cmn--btn bg--title"
                        onClick={() => {
                          setPdfFile(book.bk_pdf)
                          togglePdfReaderModal()
                        }}
                      >
                        Read Pdf
                      </Link>
                    )}
                    {book.bk_video && (
                      <Link
                        to="#"
                        className="cmn--btn bg--title"
                        onClick={toggleWatchModal}
                      >
                        Watch Video
                      </Link>
                    )}
                    {book.bk_audio ? (
                      <Link
                        to="#"
                        className="cmn--btn bg--title"
                        onClick={toggleListenModal}
                      >
                        Listen Audio
                      </Link>
                    ) : (
                      <></>
                    )}
                    {book.bk_previewVideo ? (
                      <Link
                        to="#"
                        className="cmn--btn"
                        onClick={toggleWatchPreviewModal}
                      >
                        Watch Preview
                      </Link>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <p className="txt mt-3">{book.bk_description}</p>
              </div>
              <div className="books-single-bottom">
                <h4 className="title border-bottom">Send Review</h4>
                <Formik
                  enableReinitialize={true}
                  initialValues={form}
                  validationSchema={Yup.object({
                    br_comment: Yup.string().required('Please enter comment'),
                    br_rate: Yup.string().required('Please select your rating'),
                  })}
                  onSubmit={(values) => {
                    let userType = ''
                    if (
                      userInfo?.userType == 'Student' ||
                      userInfo?.userType == 'Premium'
                    ) {
                      userType = 'Student'
                    } else if (
                      userInfo?.userType == 'Teacher' ||
                      userInfo?.userType == 'Freelance'
                    ) {
                      userType = 'Teacher'
                    }
                    let bookReviewData = values
                    bookReviewData.br_bookId = decryptedId
                    bookReviewData.br_rate = values.br_rate
                    bookReviewData.br_userId = userInfo?.id
                    bookReviewData.br_userType = userType
                    // bookReviewData
                    handleBookReviewSubmit(bookReviewData)
                  }}
                >
                  {({
                    errors,
                    touched,
                    isSubmitting,
                    handleSubmit,
                    handleChange,
                    values,
                    resetForm,
                  }) => (
                    <Form
                      className="mt-4 pt-2"
                      onSubmit={(e) => {
                        e.preventDefault()
                        handleSubmit(e)
                        return false
                      }}
                    >
                      <Field
                        as="textarea"
                        className={`form-control ${
                          errors.br_comment && touched.br_comment
                            ? 'form-err'
                            : ''
                        }`}
                        name="br_comment"
                        maxlength="120"
                        placeholder="Write comment"
                        onChange={handleChange}
                        value={values.br_comment}
                        invalid={touched.br_comment && errors.br_comment}
                      />
                      {errors.br_comment && touched.br_comment ? (
                        <div className="text-danger">{errors.br_comment}</div>
                      ) : null}
                      {console.log('values.br_rate', values.br_rate)}
                      <div className="mt-3 row">
                        <div className="col-lg-9">
                          <Box
                            component="fieldset"
                            mb={3}
                            borderColor="transparent"
                            className="col"
                          >
                            <Typography component="legend">
                              Your rating
                            </Typography>
                            <Rating
                              name="br_rate"
                              invalid={touched.br_rate && errors.br_rate}
                              value={values.br_rate}
                              onChange={handleChange}
                            />
                          </Box>
                          {errors.br_rate && touched.br_rate ? (
                            <div className="text-danger">{errors.br_rate}</div>
                          ) : null}
                        </div>
                        <div className="col-lg-3 col-md-4 col d-flex justify-content-end">
                          <button
                            disabled={isSubmitLoading}
                            className="cmn--btn btn-sm"
                            type="submit"
                          >
                            <div className="d-flex align-items-center justify-content-center">
                              {isSubmitLoading && <ButtonLoader></ButtonLoader>}

                              <span>Submit</span>
                            </div>
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
                <ul className="all-reviews">
                  <div className="review">
                    {bookReviews &&
                      bookReviews.map((bookReview, i) => {
                        {
                          bookReview?.br_student?.st_profilePic && (
                            <li key={i}>
                              <div className="img">
                                <img
                                  src={default_icon}
                                  alt={bookReview?.br_student?.st_fullName}
                                />
                              </div>
                              <div className="cont">
                                <div className="top row">
                                  <h5 className="name col">
                                    {bookReview?.br_student?.st_fullName}
                                    <span>
                                      Posted on {bookReview.br_createdAt}
                                    </span>
                                  </h5>
                                  <div className="rating col-lg-3">
                                    <div className="stars">
                                      {bookReview.br_rate > 0 ? (
                                        <span className="text-rating">
                                          <BsStarFill />
                                        </span>
                                      ) : (
                                        <span className="text-clr">
                                          <BsStarFill />
                                        </span>
                                      )}
                                    </div>
                                    <div className="stars">
                                      {bookReview.br_rate > 1 ? (
                                        <span className="text-rating">
                                          <BsStarFill />
                                        </span>
                                      ) : (
                                        <span className="text-clr">
                                          <BsStarFill />
                                        </span>
                                      )}
                                    </div>
                                    <div className="stars">
                                      {bookReview.br_rate > 2 ? (
                                        <span className="text-rating">
                                          <BsStarFill />
                                        </span>
                                      ) : (
                                        <span className="text-clr">
                                          <BsStarFill />
                                        </span>
                                      )}
                                    </div>
                                    <div className="stars">
                                      {bookReview.br_rate > 3 ? (
                                        <span className="text-rating">
                                          <BsStarFill />
                                        </span>
                                      ) : (
                                        <span className="text-clr">
                                          <BsStarFill />
                                        </span>
                                      )}
                                    </div>
                                    <div className="stars">
                                      {bookReview.br_rate > 4 ? (
                                        <span className="text-rating">
                                          <BsStarFill />
                                        </span>
                                      ) : (
                                        <span className="text-clr">
                                          <BsStarFill />
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="row">
                                    <p className="col">
                                      {bookReview.br_comment}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                          )
                        }
                      })}
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default LibraryBookDetail
